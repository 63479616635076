/* Theme Color */

a,
.theme_color,
.social-icon-one li a:hover,
.main-slider .know_more,
.scroll-to-top:hover,
.list-style-one li:before,
.news-block .inner-box .title,
.list-style-two li:hover .icon,
.list-style-two li:hover a,
.testimonial-block .inner-box .location,
.testimonial-block .inner-box:hover .read,
.testimonial-block .inner-box:hover .quote-icon,
.news-block .inner-box .read-more:hover,
.news-block .inner-box h2 a:hover,
.projects-section .sec-title .view_all,
.market-section .title-column .inner-column .view_all:hover,
.main-header .nav-outer .outer-box .quote-btn:hover,
.main-header .header-top .top-right > ul > li > a:hover,
.main-header .header-top .top-left .links li .icon,
.main-header .header-top .top-left .links li a:hover,
.main-menu .navigation > li > ul > li:hover > a,
.main-footer .footer-bottom .title-column .text .icon,
.main-footer .footer-bottom .subscribe-form .form-group button,
.career-section .content-column .inner-column .see_all:hover,
.main-menu .navigation > li > ul > li > ul > li > a:hover,
.main-menu .navigation > li > ul > li.dropdown:hover > a:after,
.main-header .header-lower .search-box .form-group button,
.market-section-two .view-all,
.btn-style-one:hover,
.form-group .ui-button .ui-icon:before,
.header-style-three .header-upper-two .info-box ul li span,
.main-header .search-box-btn,
.testimonial-block-three .inner-box .upper-box .icon,
.news-block-two .inner-box .lower-content .title,
.testimonial-block-two .inner-box .read,
.news-block-two .inner-box .lower-content .read-more:hover,
.market-block-four .inner-box .lower-content .icon-box,
.market-section-four .more:hover,
.choose-block .inner-box:hover .icon-box,
.choose-block .inner-box:hover h3 a,
.project-block-four .inner-box .lower-content h3 a:hover,
.project-block-four .inner-box .lower-content .read-more:hover,
.testimonial-block-five .inner-box .author-info .quote-icon,
.project-block-two .inner-box .lower-box .title,
.testimonial-section-two .content-column .inner-column .achived-list li a:hover,
.testimonial-block-two .inner-box .designation,
.market-block-three .inner-box .upper-box .icon-box,
.testimonial-block-four .inner-box .author-info .location,
.project-section-two .title-column .inner-column .projects:hover,
.header-style-four .header-top .top-right > ul > li.language > a > .fa,
.news-block-two .inner-box .lower-content h3 a:hover,
.testimonial-block-five .inner-box .author-info .location,
.highlights-section .services-column .inner-column .upper-box .icon,
.project-section .filters .filter-tabs .filter.active,
.page-breadcrumb li a:hover,
.page-breadcrumb li,
.page-breadcrumb li:after,
.testimonial-block-seven .inner-box .designation,
.project-section .filters .filter-tabs .filter:hover,
.highlights-section .services-column .inner-column .services:hover,
.testimonial-section-two .content-column .inner-column .bold-text,
.testimonial-section-two .content-column .inner-column .achived-list li.icon,
.main-menu .navigation > li .mega-menu-bar .column > ul > li > a:hover,
.main-menu .navigation > li:hover > a, .main-menu .navigation > li.current > a,
.history-section .history-carousel .thumbs-carousel .owl-nav .owl-prev:hover,
.history-section .history-carousel .thumbs-carousel .owl-nav .owl-next:hover,
.history-section .carousel-outer .content-carousel .owl-nav .owl-prev,
.history-section .carousel-outer .content-carousel .owl-nav .owl-next,
.history-section .carousel-outer .content-slide .content-inner .date,
.achived-section .title-column .inner-column .title,
.achived-section .title-column .achived-list li a:hover,
.progress-levels .progress-box .inner .count-text,
.team-block .inner-box:hover .lower-content h3 a,
.progress-levels .progress-box .inner .percent,
.achived-section .title-column .achived-list li.icon,
.faq-section .prod-tabs .tab-btns .tab-btn.active-btn span,
.faq-section .prod-tabs .tab-btns .tab-btn:hover span,
.maintenance-box .inner-box .text,
.request-quote a:hover,
.list-style-five li:before,
.post-options .inner-box ul li a:hover,
.blog-cat-two li a:hover::before,
.blog-cat-two li.active a, .blog-cat-two li a:hover,
.services-block-three .inner-box:hover h3 a,
.services-block-three .inner-box:hover .read-more,
.accordion-box .block .acc-btn.active .icon-outer .icon-plus,
.project-page-section .outer-section .load-more,
.comment-block .inner .content h3 a:hover,
.comment-block .inner .content .reply-btn:hover,
.sidebar .popular-posts .post-info:before,
.blog-single .inner-box .lower-content .title,
.blog-single .post-share-options .info-links li a,
.blog-single .post-share-options .tags a,
.blog-single .post-share-options .tags,
.social-icon-three li a:hover,
.sidebar .related-posts .post .rating .fa,
.shop-item .inner-box:hover .lower-content h3 a,
.shop-item .inner-box .lower-content .rating .fa,
.product-details .basic-details .details-header .rating,
.product-details .basic-details .expired,
.sidebar .related-posts .post a, .sidebar .related-posts .post a:hover,
.shop-section .items-sorting .results-column h4,
.comment-form .rating-box .rating a:hover,
.cart-table tbody tr .sub-total,
.list-style-three li .icon,
.checkout-page .default-links li a,
.cart-table tbody tr .remove-btn:hover,
.blog-cat-three li a:hover:before, .blog-cat-three li a:hover,
.sidebar-page-container .comments-area .comment .comment-inner .rating .fa,
.blog-single .inner-box .lower-content .text blockquote .quote-info .quote-desination,
.sidebar .popular-posts .post a, .sidebar .popular-posts .post a:hover,
.testimonial-widget .inner-widget .upper-box .upper-inner .location,
.project-single-section .inner-box .info-column .inner-column ul li .fa,
.shop-single .product-details .prod-tabs .tab-btns .tab-btn:hover,
.shop-single .product-details .prod-tabs .tab-btns .tab-btn.active-btn,
.shop-comment-form .rating-box .rating a:hover,
.contact-form .submit-btn:hover,
.contact-section .info-column .lower-box .lower-inner .designation,
.contact-section .info-column .lower-box .lower-inner .emailed:before{
	color:#39CCCC;
}

/*Background Color*/
.scroll-to-top,
.main-menu .navigation > li:before,
.main-menu .navigation > li:after,
.main-header .nav-outer .outer-box .quote-btn:hover::after,
.main-header .language .dropdown-menu > li > a:hover,
.main-slider .tparrows.gyges:hover,
.sec-title .separator:before,
.sec-title .separator:after,
.main-footer .footer-title h2:after,
.main-footer .links li a:after,
.services-block .inner-box .overlay-box,
.career-section .video-column .inner-column .play-btn,
.project-block .inner-box:hover .image .overlay-box .content,
.testimonial-section .owl-dots .owl-dot:hover,
.testimonial-section .owl-dots .owl-dot.active,
.services-block-two .inner-box:hover,
.btn-style-one,
.btn-style-three,
.achived-section .title-column .achived-list li a,
.btn-style-two:hover,
.social-icon-two li a:hover,
.accordion-box.style-two .block.active-block::before,
.market-block-four .inner-box:hover .lower-content,
.market-block-two .inner-box .content-overlay,
.testimonial-section-two .owl-dots .owl-dot:hover,
.testimonial-section-two .owl-dots .owl-dot.active,
.project-section .filters .filter-tabs .filter:after,
.about-section .carousel-column .owl-dots .owl-dot:hover,
.about-section .carousel-column .owl-dots .owl-dot.active,
.testimonial-section-two .content-column .inner-column .achived-list li a,
.testimonial-section-four .owl-dots .owl-dot:hover,
.testimonial-section-four .owl-dots .owl-dot.active,
.company-section .image-column .inner-column .carousel-box,
.hightlight-block .hightlight-inner:hover,
.accordion-box .block .acc-content:before,
.error-section .inner-section,
.market-section-three .owl-dots .owl-dot:hover,
.market-section-three .owl-dots .owl-dot.active,
.team-block .inner-box .lower-content .designation,
.faq-section .prod-tabs .tab-btns .tab-btn:hover:before,
.faq-section .prod-tabs .tab-btns .tab-btn.active-btn:before,
.blog-cat li.active a, .blog-cat li a:hover,
.sidebar-brochure .brochure:hover,
.request-quote a,
.sidebar .search-box.style-two .form-group button,
.sidebar .popular-tags a:hover,
.range-slider-one .theme-btn,
.cart-table tbody .available-info .icon,
.market-single .inner-box .lower-content h2:after,
.market-block-five .block-inner .block-overlay,
.styled-pagination li a:hover, .styled-pagination li a.active,
.history-section .carousel-outer .content-carousel .owl-nav .owl-next:hover,
.history-section .carousel-outer .content-carousel .owl-nav .owl-prev:hover,
.project-block-five .inner-box .image .overlay-box .content,
.blog-cat-two li:hover::after,
.shipping-form .form-group .total-btn:hover,
.cart-section .coupon-outer .cart-btn:hover,
.ui-state-active, .ui-widget-content .ui-state-active,
.testimonial-widget .owl-dots .owl-dot:hover, .testimonial-widget .owl-dots .owl-dot.active,
.market-section .market-block .inner-box:before{
	background-color:#39CCCC;
}

.header-style-four .header-top .top-left ul > li.language > a{
	background-color:#39CCCC !important;
}

/*Border Color*/

.btn-style-one,
.main-slider .know_more,
.scroll-to-top,
.market-section-two .view-all,
.main-menu .navigation > li > ul > li:hover,
.main-header .nav-outer .outer-box .quote-btn:hover,
.main-header .language .dropdown-menu,
.main-menu .navigation > li > ul,
.market-block .inner-box:hover,
.main-menu .navigation > li > ul > li > ul > li:hover,
.main-menu .navigation > li > ul > li > ul,
.projects-section .sec-title .view_all,
.market-section-two .title-box .text,
.btn-style-one:hover,
.btn-style-three,
.btn-style-two:hover,
.request-quote a,
.range-slider-one .theme-btn,
.sidebar .popular-tags a:hover,
.maintenance-box .inner-box h2,
.cart-table tbody tr .remove-btn:hover,
.accordion-box .block .acc-btn.active .icon-outer .icon-plus,
.achived-section .title-column .achived-list li a,
.market-block-four .inner-box:hover .lower-content,
.main-header .search-box-outer .dropdown-menu,
.testimonial-block-five .inner-box .author-info .quote-icon,
.styled-pagination li a:hover, .styled-pagination li a.active,
.main-header .search-panel input:focus, .main-header .search-panel select:focus,
.testimonial-section-two .content-column .inner-column .achived-list li a,
.comment-form .form-group input[type="text"]:focus,
.comment-form .form-group input[type="password"]:focus,
.comment-form .form-group input[type="tel"]:focus,
.comment-form .form-group input[type="email"]:focus,
.comment-form .form-group select:focus,
.comment-form .form-group textarea:focus,
.shop-comment-form .form-group input[type="text"]:focus,
.shop-comment-form .form-group input[type="password"]:focus,
.shop-comment-form .form-group input[type="tel"]:focus,
.shop-comment-form .form-group input[type="email"]:focus,
.shop-comment-form .form-group select:focus,
.shop-comment-form .form-group textarea:focus,
.ui-state-active, .ui-widget-content .ui-state-active,
.checkout-page .default-links li,
.shipping-form .form-group input:focus,
.shipping-form .form-group textarea:focus,
.checkout-form input:focus, .checkout-form select:focus, .checkout-form textarea:focus,
.styled-form input:focus, .styled-form select:focus, .styled-form textarea:focus,
.contact-form .form-group input[type="text"]:focus,
.contact-form .form-group input[type="password"]:focus,
.contact-form .form-group input[type="tel"]:focus,
.contact-form .form-group input[type="email"]:focus,
.contact-form .form-group select:focus,
.contact-form .form-group textarea:focus{
	border-color:#39CCCC;
}

.faq-section .prod-tabs .tab-btns{
	border-top-color:#39CCCC;
}

/*RGBA Color Dark*/
.informations-section:before,
.call-to-action-section:before{
	background-color:rgba(57,204,204,0.95);
}

/*RGBA Color Dark*/
.project-block-three .inner-box .image .overlay-content-box,
.project-block-two .inner-box .overlay-content:before{
	background-color:rgba(57,204,204,0.90);
}

.faq-section .prod-tabs .tab-btns .tab-btn:hover:before,
.faq-section .prod-tabs .tab-btns .tab-btn.active-btn:before{
	border-color:rgba(57,204,204,0.20);
}

@media only screen and (max-width: 767px){
	
	.main-menu .navbar-header .navbar-toggle{
		border-color:#ffffff;
	}
	
	.main-menu .navbar-header .navbar-toggle,
	.main-menu .navbar-collapse > .navigation,
	.main-menu .navbar-collapse > .navigation > li > ul,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul,
	.main-menu .navbar-collapse > .navigation > li > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul > li > a,
	.main-menu .navbar-collapse > .navigation > li > a:hover,
	.main-menu .navbar-collapse > .navigation > li > a:active,
	.main-menu .navbar-collapse > .navigation > li > a:focus,
	.main-menu .navbar-collapse > .navigation > li:hover > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li:hover > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul > li:hover > a,
	.main-menu .navbar-collapse > .navigation > li.current > a,
	.main-menu .navbar-collapse > .navigation > li.current-menu-item > a,
	.header-style-two.inner-header .main-menu .navigation > li > a{
		background-color:#39CCCC;
	}
	
}