/* The Metron - Industry and Factory HTML Template */

/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global
4. Main Header / Style Two / Style Three
5. Main Slider
6. Services Section
7. Score Section
8. Features Section
9. Marketing Section
10. Case Section
11. Testimonial Section
12. Pricing Section
13. Partners Section
14. Main Footer
15. Page Title
16. Accordian Section
17. Sidebar Widgets
18. Quote Form Section
19. About Section
20. Team Section
21. Clients Section
22. Seo Section
23. Error Section
24. Comming Soon Section
25. Pricing Section
26. Contact Form Section

**********************************************/

@import url('https://fonts.googleapis.com/css?family=Hind:300,400,500,600,700|Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Rubik:300,300i,400,400i,500,500i,700,700i,900,900i');

/*font-family: 'Poppins', sans-serif;
font-family: 'Poppins', sans-serif !important;
font-family: 'Hind', sans-serif;*/

@import url('font-awesome.css');
@import url('flaticon.css');
@import url('animate.css');
@import url('hover.css');
@import url('owl.css');
@import url('jquery-ui.css');
@import url('jquery.fancybox.min.css');
@import url('jquery.bootstrap-touchspin.css');

/*** 

====================================================================
	Reset
====================================================================

 ***/
 
* {
	margin:0px;
	padding:0px;
	border:none;
	outline:none;
}

/*** 

====================================================================
	Global Settings
====================================================================

 ***/

body {
	font-size:15px;
	color:#777777;
	line-height:1.8em;
	font-weight:400;
	background:#ffffff;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	font-family: 'Poppins', sans-serif;
}

a{
	text-decoration:none;
	cursor:pointer;
	color:#aed0f1;
}

a:hover,a:focus,a:visited{
	text-decoration:none;
	outline:none;
}

h1,h2,h3,h4,h5,h6 {
	position:relative;
	font-weight:normal;
	margin:0px;
	background:none;
	line-height:1.6em;
	font-family: 'Poppins', sans-serif !important;
}

input,button,select,textarea{
	font-family: 'Poppins', sans-serif;
}

textarea{
	overflow:hidden;	
}

.theme_color{
	color:#aed0f1;
}

p,.text{
	position:relative;
	line-height:1.8em;	
	font-family: 'Poppins', sans-serif;
}

.page-wrapper section{
	position:relative;
	z-index:5;	
}

.strike-through{
	text-decoration:line-through;	
}

.auto-container{
	position:static;
	max-width:1200px;
	padding:0px 15px;
	margin:0 auto;
}

.page-wrapper{
	position:relative;
	margin:0 auto;
	width:100%;
	min-width:300px;
}

.grey-bg{
	background-color:#f8f8f8;
}

ul,li{
	list-style:none;
	padding:0px;
	margin:0px;	
}

.theme-btn{
	display:inline-block;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.centered{
	text-align:center;	
}

/*List Style One*/

.list-style-one{
	position:relative;
}

.list-style-one li{
	position:relative;
	padding-left:48px;
	margin-bottom:22px;
	color:#848484;
	font-size:16px;
	font-weight:400;
}

.list-style-one li strong{
	position:relative;
	display:block;
	color:#020e28;
	font-size:18px;
	font-weight:500;
}

.list-style-one li:before{
	position:absolute;
	content: "\f12a";
	left:0px;
	top:14px;
	color:#aed0f1;
	font-size:22px;
	font-family: "Flaticon";
}

/*List Style Two*/

.list-style-two{
	position:relative;
}

.list-style-two li{
	position:relative;
	padding-left:50px;
	color:#c4c4c4;
	font-size:16px;
	margin-bottom:25px;
}

.list-style-two li .icon{
	position:absolute;
	left:0px;
	top:7px;
	line-height:1em;
	font-size:40px;
	font-weight:400;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	color:rgba(255,255,255,0.40);
}

.list-style-two li a{
	position:relative;
	font-weight:500;
	display:block;
	color:#ffffff;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	font-family: 'Poppins', sans-serif !important;
}

.list-style-two li:hover a{
	color:#aed0f1;
	text-decoration:underline;
}

.list-style-two li:hover .icon{
	color:#aed0f1;
}

/*List Style Three*/

.list-style-three{
	position:relative;
}

.list-style-three li{
	position:relative;
	margin-bottom:15px;
	color:#848484;
	font-size:15px;
	font-weight:400;
	padding-left:46px;
	padding-bottom:12px;
	border-bottom:1px solid #e6e6e6;
	font-family: 'Poppins', sans-serif;
}

.list-style-three li:last-child{
	margin-bottom:0px;
	padding-bottom:0px;
	border:none;
}

.list-style-three li .icon{
	position:absolute;
	left:0px;
	top:12px;
	color:#aed0f1;
	line-height:1em;
	font-size:24px;
}

.list-style-three li strong{
	position:relative;
	color:#020e28;
	font-size:16px;
	font-weight:600;
	display:block;
	font-family: 'Poppins', sans-serif !important;
}

/*List Style Four*/

.list-style-four{
	position:relative;
}

.list-style-four li{
	position:relative;
	margin-bottom:6px;
	color:#ffffff;
	font-size:18px;
	font-weight:500;
	padding-left:30px;
}

.list-style-four li:before{
	position:absolute;
	content:'\f178';
	left:0px;
	top:3px;
	color:#ffffff;
	line-height:1em;
	font-size:16px;
	font-family: 'FontAwesome';
}

/*List Style Five*/

.list-style-five{
	position:relative;
}

.list-style-five li{
	position:relative;
	margin-bottom:15px;
	color:#848484;
	font-size:16px;
	font-weight:400;
	padding-left:30px;
}

.list-style-five li:before{
	position:absolute;
	content:'\f178';
	left:0px;
	top:3px;
	color:#aed0f1;
	line-height:1em;
	font-size:16px;
	font-family: 'FontAwesome';
}

/*Btn Style One*/

.btn-style-one{
	position:relative;
	line-height:24px;
	color:#ffffff;
	font-size:14px;
	font-weight:600;
	background:#aed0f1;
	display:inline-block;
	padding:13px 22px;
	border-radius:50px;
	letter-spacing:1px;
	text-transform:uppercase;
	border:2px solid #aed0f1;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	font-family: 'Poppins', sans-serif !important;
}

.btn-style-one .icon{
	position:relative;
	top:2px;
	font-size:18px;
	margin-right:4px;
	margin-left:5px;
}

.btn-style-one:hover{
	color:#aed0f1;
	border-color:#aed0f1;
	background:none;
}

/*Btn Style Two*/

.btn-style-two{
	position:relative;
	line-height:24px;
	color:#ffffff;
	font-size:14px;
	font-weight:700;
	background:#020e28;
	display:inline-block;
	padding:12px 49px;
	border-radius:50px;
	text-transform:uppercase;
	border:2px solid #020e28;
	font-family: 'Poppins', sans-serif !important;
}

.btn-style-two:hover{
	color:#ffffff;
	background:#aed0f1;
	border-color:#aed0f1;
}

/*Btn Style Three*/

.btn-style-three{
	position:relative;
	line-height:24px;
	color:#ffffff;
	font-size:14px;
	font-weight:700;
	background:#aed0f1;
	display:inline-block;
	padding:12px 49px;
	border-radius:50px;
	text-transform:uppercase;
	border:2px solid #aed0f1;
	font-family: 'Poppins', sans-serif !important;
}

.btn-style-three:hover{
	color:#ffffff;
	background:#020e28;
	border-color:#020e28;
}

.preloader{ position:fixed; left:0px; top:0px; width:100%; height:100%; z-index:999999; background-color:#ffffff; background-position:center center; background-repeat:no-repeat; background-image:url(../images/icons/preloader.svg);}

img{
	display:inline-block;
	max-width:100%;
	height:auto;	
}

/*** 

====================================================================
	Fancy Box
====================================================================

***/

.fancybox-next span,
.fancybox-prev span{
	background-image:none !important;
	width:44px !important;
	height:44px !important;
	line-height:44px !important;
	text-align:center;
}

.fancybox-next span:before,
.fancybox-prev span:before{
	content:'';
	position:absolute;
	font-family: 'FontAwesome';
	left:0px;
	top:0px;
	font-size:12px;
	width:44px !important;
	height:44px !important;
	line-height:44px !important;
	background-color:rgba(28,28,28,0.40) !important;
	color:#ffffff;
	visibility:visible;
	transition: all 300ms ease;
	-webkit-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
}

.fancybox-next span:before{
	content:'\f178';
}

.fancybox-prev span:before{
	content:'\f177';
}

.fancybox-next:hover span:before,
.fancybox-prev:hover span:before{
	background-color:#ffffff !important;
	color:#000000;	
}

.fancybox-type-image .fancybox-close{
	right:0px;
	top:0px;
	width:45px;
	height:45px;
	background:url(../images/icons/icon-cross.png) center center no-repeat;
	background-color:rgba(17,17,17,0.50) !important;	
}

.fancybox-type-image .fancybox-close:hover{
	background-color:#000000 !important;	
}

.fancybox-type-image .fancybox-skin{
	padding:0px !important;	
}

/*** 

====================================================================
	Social Icon One
====================================================================

***/

.social-icon-one{
	position:relative;
}

.social-icon-one li{
	position:relative;
	margin-left:20px;
	display:inline-block;
}

.social-icon-one li a{
	position:relative;
	font-size:16px;
	color:#ffffff;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.social-icon-one li a:hover{
	color:#aed0f1;
}

/*** 

====================================================================
	Social Icon Two
====================================================================

***/

.social-icon-two{
	position:relative;
}

.social-icon-two li{
	position:relative;
	margin:0px 5px;
	display:inline-block;
}

.social-icon-two li a{
	position:relative;
	width:40px;
	height:40px;
	font-size:14px;
	color:#848484;
	line-height:40px;
	border-radius:50%;
	text-align:center;
	display:inline-block;
	background-color:#ffffff;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.social-icon-two li a:hover{
	color:#ffffff;
	background-color:#aed0f1;
}

/*** 

====================================================================
	Social Icon Three
====================================================================

***/

.social-icon-three{
	position:relative;
}

.social-icon-three li{
	position:relative;
	margin-right:15px;
	display:inline-block;
}

.social-icon-three li a{
	position:relative;
	font-size:14px;
	color:#9b9b9b;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.social-icon-three li a:hover{
	color:#aed0f1;
}

/*** 

====================================================================
	Scroll To Top style
====================================================================

***/

.scroll-to-top{
	position:fixed;
	bottom:15px;
	right:15px;
	width:40px;
	height:70px;
	color:#ffffff;
	font-size:24px;
	text-transform:uppercase;
	line-height:65px;
	text-align:center;
	z-index:99999;
	cursor:pointer;
	border-radius:50px;
	background:#aed0f1;
	display:none;
	border:2px solid #aed0f1;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;		
}

.scroll-to-top:hover{
	color:#aed0f1;
	background:none;
}

/*** 

====================================================================
	Main Header style
====================================================================

***/

.main-header{
	position:relative;
	left:0px;
	top:0px;
	z-index:999;
	width:100%;
}

.main-header .header-top{
	position:relative;
	background:#f4f4f4;
}

.main-header .header-top .top-left{
	position:relative;
	float:left;
	padding:16px 0px 15px;
}

.main-header .header-top .top-left .links{
	position:relative;
	float:left;
	
}

.main-header .header-top .top-left ul {
	margin-bottom: 0;
	font-family: "Poppins", sans-serif;
}

.main-header .header-top .top-left .links li{
	position:relative;
	float:left;
	margin-right:18px;
	line-height:1em;
	color:#ababab;
	padding-right:18px;
}

.main-header .header-top .top-left .links li:after{
	content:'';
	position:absolute;
	right:0px;
	top:50%;
	margin-top:-7px;
	height:15px;
	border-right:1px solid #c3c3c3;
}

.header-style-two .header-top .top-left .links li:after{
	border-color:rgba(255,255,255,0.15);
}

.main-header .header-top .top-left ul li:last-child{
	margin-right:0px;
	padding-right:0px;
}

.main-header .header-top .top-left .links li:last-child:after{
	display:none;	
}

.main-header .header-top .top-left .links li .icon{
	position:relative;
	top:0px;
	color:#aed0f1;
	font-size:18px;
	line-height:24px;
	margin-right:10px;
}

.top-left li p { margin: 0; }

.main-header .header-top .top-left .links li a{
	position:relative;
	line-height:22px;
	color:#020e28;
	font-size:16px;
	font-weight:400;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	transition:all 500ms ease;
}

.header-style-two .header-top .top-left .links li a{
	color:#ffffff;
}

.main-header .header-top .top-left .links li a:hover{
	color:#aed0f1;
}

.main-header .main-box li:hover a, .main-header .main-box a:hover {
    text-decoration: none;
}

.main-header .header-top .top-right{
	position:relative;
	float:right;
	padding:16px 0px;
}

.main-header .header-top .top-right ul li{
	position:relative;
	color:#808080;
	font-size:14px;
	float:left;
	line-height:1.6em;
	margin-right:20px;
}

.main-header .header-top .top-left .links li img {
	margin-right: 15px;
}

.main-header .header-top .top-right ul li span{
	position:relative;
	margin:0px 5px;
}

.main-header .header-top .top-right ul li a{
	position:relative;
	color:#020e28;
	font-size:16px;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	transition:all 500ms ease;
}

.header-style-two .header-top .top-right ul li a,
.header-style-three .header-upper-two .header-upper-left .language a{
	color:#ffffff;
}

.header-style-three .header-upper-two .header-upper-left .info-box{
	position:relative;
	padding-left:20px;
	margin-left:20px;
	display:inline-block;
	border-left:2px solid rgba(255,255,255,0.20);
}

.header-style-three .header-upper-two .info-box ul li{
	position:relative;
	color:#ffffff;
	font-size:16px;
}

.header-style-three .header-upper-two .info-box ul li span{
	display:block;
	color:#aed0f1;
}

.main-header .header-top .top-right ul li a .icon{
	position:relative;
	margin-right:12px;
	color:#aed0f1;
}

.main-header .header-top .top-right > ul > li > a:hover{
	color:#aed0f1;
	text-decoration:underline;
}

.main-header .header-top .top-right > ul{
	position:relative;
	float:left;
}

.main-header .header-top .top-right > ul > li:last-child{
	margin-right:0px;
	padding-left:12px;
}

.main-header .header-top .top-right > ul > li:last-child::before{
	position:absolute;
	content:'';
	left:0px;
	top:3px;
	width:1px;
	height:15px;
	background-color:#d0d0d0;
}

.header-style-two{
	background-color:#ffffff;
}

.header-style-two .header-top .top-right > ul > li:last-child::before{
	background-color:rgba(255,255,255,0.20);
}

.main-menu .nav > li:last-child > ul{
	left:auto;
	right:0px;
}

.main-header .header-lower{
	position:relative;
	padding-top:30px;
	overflow:hidden;
}

.main-header .header-lower .lower-left{
	position:relative;
	margin-bottom:10px;
}

.main-header .header-lower .info-box{
    position: relative;
    float: left;
    min-height: 45px;
    margin-right: 28px;
    font-size: 14px;
    color: #5c657a;
    text-align: left;
	font-weight:500;
	line-height:1.8em;
	margin-bottom:20px;
    padding: 0px 28px 0px 50px;
	border-right:2px solid #e6e6e6;
}

.main-header .header-lower .info-box:last-child{
	margin-right:0px;
	padding-right:0px;
	border:none;
}

.main-header .header-lower .info-box .icon-box{
	position:absolute;
	left:0px;
	top:4px;
	color:#c1c1c1;
	font-size:42px;
	line-height:1em;
}

.main-header .header-lower .info-box strong{
	text-transform:uppercase;
	font-weight:600;
	color:#5c657a;
	display:block;
	font-family: 'Poppins', sans-serif !important;
}

.main-header .header-lower .lower-right{
	position:relative;
	margin-top:20px;
	padding-left:10px;
}

.main-header .header-lower .lower-right:before{
	position:absolute;
	content:'';
	left:0%;
	bottom:0px;
	width:2000px;
	height:100%;
	background-color:#020e28;
	-ms-transform: skewX(-25deg);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
}

.main-header .header-lower .search-box{
	position:relative;
	float:left;
}

.main-header .header-lower .search-box .form-group{
	position:relative;
	margin:0px;
}

.main-header .header-lower .search-box .form-group input[type="text"],
.main-header .header-lower .search-box .form-group input[type="search"]{
	position:relative;
	line-height:25px;
	padding:10px 50px 10px 20px;
	background:rgba(255,255,255,0);
	display:block;
	font-size:14px;
	height:60px;
	width:330px;
	float:right;
	color:#ffffff;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .header-lower .search-box .form-group input::-webkit-input-placeholder{
	color:rgba(255,255,255,1);
}

.main-header .header-lower .search-box .form-group button{
	position:absolute;
	right:0px;
	top:0px;
	height:60px;
	width:45px;
	display:block;
	font-size:14px;
	color:#aed0f1;
	line-height:100%;
	font-weight:normal;
	background:none;
	text-align:right;
}

.header-style-three .header-upper-two .header-upper-left{
	position:relative;
	padding-left:300px;
	padding-top:18px;
	padding-bottom:17px;
}

.header-style-three .header-upper-two .header-upper-left .language{
	position:relative;
	float:left;
	padding:14px 0px;
}

.header-style-three .header-upper-two .header-upper-right .social-icon-one{
	margin-top:32px;
}

.header-style-three .header-lower-two{
	position:relative;
	background-color:#ffffff;
}

.header-style-three .header-lower-two .outer-container{
	position:relative;
	padding-left:284px;
}

.header-style-three .header-lower-two .logo-box{
	position:absolute;
	left:0px;
	top:-90px;
}

.header-style-three .header-lower-two .nav-outer{
	width:100%;
	padding-left:18px;
}

.header-style-three .header-lower-two .main-menu .nav > li{
	padding:0px;
	margin-left:0px;
	margin-right:35px;
}

.header-style-three .header-lower-two .main-menu .nav > li > a{
	color:#020e28;
}

.header-style-four{
	position:absolute;
	left:0px;
	top:0px;
	background:none;
}

.header-style-four .header-top{
	background-color:#020e28;
}

.header-style-four .header-top .top-left{
	padding:12px 0px 12px;
}

.header-style-four .header-top .top-left ul > li.language{
	padding-right:0px;
}

.header-style-four .header-top .top-left ul > li.language > a{
	padding:4px 17px;
	background-color:#aed0f1 !important;
}

.header-style-four .header-top .top-left ul > li.language > a:hover{
	color:#ffffff;
}

.header-style-four .header-top .top-left .links li a,
.header-style-four .header-top .top-right ul li a{
	color:#ffffff;
}

.header-style-four .header-top .top-left .links li:after{
	display:none;
}

.header-style-four .header-top .top-right > ul > .language{
	padding-left:0px;
	padding-right:12px;
	margin-right:2px;
	border-right:1px solid rgba(255,255,255,0.20);
}

.header-style-four .header-top .top-right > ul > .language > a > .fa{
	color:#aed0f1;
}

.header-style-four .header-top .top-right > ul > li:last-child::before{
	display:none;
}

.header-style-four .header-top .top-right > .social-icon-one{
	float:left;
}

.header-style-four .header-top .top-right > .social-icon-one > li{
	margin-right:0px;
	margin-left:12px;
}

.header-style-four .header-top .top-right > .social-icon-one > li:last-child{
	padding-left:0px;
}

/*Language Dropdown*/

.main-header .language .flag-img{
	position:relative;
	margin-right:10px;
	top:-2px;
}

.main-header .language .dropdown-menu{
	top:100%;
	left:0px;
	width:180px;	
	border-radius:0px;
	margin-top:18px;
	background-color:#222222;
	border:none;
	padding:0px;
    border-top:3px solid #aed0f1;
}

.main-header .language > a{
	padding:0px 0px;
	background:none !important;
    border:0px;	
	border-radius:0px;
}

.main-header language a:hover{
	background:none;
}

.main-header .language .dropdown-menu > li{
	padding-right:0px !important;
	margin:0px !important;
	float:none;
	width:100%;
	display:block !important;
    border-bottom: 1px solid rgba(255,255,255,0.20);
}

.main-header .language .dropdown-menu > li:last-child{
	border-bottom:none;	
}

.main-header .language .dropdown-menu > li > a{
	padding:10px 20px !important;
	color:#ffffff !important;
	text-align:left;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-header .language .dropdown-menu > li > a:hover{
	color:#ffffff !important;
	background-color:#aed0f1;
}

/*Main Box*/

.main-header .main-box{
	position:relative;
	padding:0px 0px;
	left:0px;
	top:0px;
	width:100%;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-header .main-box .outer-container{
	position:relative;
}

.main-header .main-box .outer-container:before{
	position:absolute;
	content:'';
	left:0px;
	bottom:0px;
	width:100%;
	height:2px;
	background-color:rgba(255,255,255,0.15);
}

.header-style-two .main-box .outer-container:before{
	background-color:rgba(230,230,230,1)
}

.header-style-four .main-box .outer-container:before{
	display:none;
}

.main-header .main-box .logo-box{
	position:relative;
	float:left;
	left:0px;
	z-index:10;
	padding:15px 0px;
}

.main-header.fixed-header .main-box .logo-box{
	padding:5px 0px;
}

.main-header .main-box .logo-box .logo img{
	display:inline-block;
	max-width:100%;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;	
}

.main-header.fixed-header .nav-outer .search-box-outer{
	margin-top:-10px;
}

.main-header.fixed-header .main-box .logo-box .logo img{
	height:60px;
}

.logo-box .logo a {
    text-transform: uppercase;
    margin: 22px 0;
    display: inline-block;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
}

.main-header .nav-toggler{
	position:relative;
	right:0px;
	width:44px;
	height:46px;
	top:50%;
	margin-top:0px;
	display: block;
	border-radius:4px;
	text-align:center;
	border:1px solid #dddddd;
}

.main-header .nav-toggler button{
	position:relative;
	left:12px;
	display:block;
	color:#444444;
	text-align:center;
	font-size:20px;
	line-height:44px;
	background:none;
	font-weight:600;
}

.main-header .nav-outer{
	position:static;
	float:right;
}

.main-header .nav-outer .outer-box{
	position:relative;
	float:right;
	margin: 30px 0 30px 50px;
}

.header-style-three .nav-outer .outer-box{
	margin-top:20px;
	margin-left:0px;
}

.header-style-three .nav-outer .outer-box .quote-btn{
	padding:3px 22px 3px !important;
	color:#020e28 !important;
	border-color:#e6e6e6 !important;
}

.header-style-three .nav-outer .outer-box .quote-btn:after{
	background-color:#e6e6e6 !important;
	bottom:0px !important;
}

.main-header .nav-outer .outer-box .quote-btn{
	position:relative;
	font-size:14px;
	font-weight:700;
	color:#ffffff;
	float:left;
	letter-spacing:1px;
	text-transform:uppercase;
	padding:12px 22px;
	font-family: 'Poppins', sans-serif !important;
	border-radius: 50px;
	background: #aed0f1;
}
.main-header .nav-outer .outer-box .quote-btn img {
	margin-right: 10px;
}

.header-style-four .nav-outer .outer-box .quote-btn{
	padding-right:0px;
	border-right:0px;
}

.main-header .nav-outer .outer-box .quote-btn:after{
	position: absolute;
    content: "";
    left: -25px;
    top: -20px;
    height: calc(100% + 40px);
    width: 2px;
    transition: all .3s ease;
    background-color: #ccc;
}

.header-style-four .nav-outer .outer-box .quote-btn:after{
	right:0px;
}

.main-header .sticky-header .nav-outer .outer-box .quote-btn:after {
	background: #ccc;
}

.main-header .nav-outer .outer-box .quote-btn:hover{
	color:#aed0f1;
	border-color:#aed0f1;
}

.main-header .nav-outer .outer-box .quote-btn:hover::after {
	background: rgba(255,255,255,0.3) !important;
}

.main-header .sticky-header .nav-outer .outer-box .quote-btn:hover::after {
	background: #ccc !important;
}

.main-header .nav-outer .outer-box .quote-btn .icon{
	position:relative;
	width:20px;
	height:13px;
	margin-left:2px;
	display:inline-block;
	background:url(../images/icons/msg-icon.png) no-repeat;
}

.main-header .nav-outer .outer-box .quote-btn .icon.icon-two{
	background:url(../images/icons/msg-icon-1.png) no-repeat;
}

.main-header .nav-outer .outer-box .social-icon-one{
	float:left;
	margin-left:6px;
	margin-top:14px;
}

.header-style-two .nav-outer .outer-box .social-icon-one li a{
	color:#848484;
}

.main-header .nav-outer .outer-box .talk{
	position:relative;
	color:#aed0f1;
	font-weight:700;
	font-size:14px;
	float:left;
	margin-top:-10px;
	margin-left:35px;
	display:inline-block;
	text-transform:uppercase;
	font-family: 'Poppins', sans-serif !important;
}

.main-header .nav-outer .outer-box .talk .icon{
	position:relative;
	display:inline-block;
	margin-right:10px;
}

.main-header .nav-outer .search-box-outer{
	position: relative;
	right:0px;
	float:left;
	color:#aed0f1;
	margin-left:20px;
	margin-top:3px;
}

.main-header .search-box-btn{
	position:relative;
	display:block;
	color:#aed0f1;
	padding:0px;
	margin:0px;
	font-size:14px;
	cursor:pointer;
	background:none;
	text-align:right;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .search-box-outer .dropdown-menu{
	top:45px;
	right:0px;
	padding:0px;
	width:280px;	
	border-radius:0px;
	border-top:3px solid #aed0f1;
}

.main-header .search-box-outer .dropdown-menu > li{
	padding:0px;
	border:none;
	background:none;
}

.main-header .search-panel .form-container{
	padding:25px 20px;	
}

.main-header .search-panel .form-group{
	position:relative;
	margin:0px;	
}

.main-header .search-panel input[type="text"],
.main-header .search-panel input[type="search"],
.main-header .search-panel input[type="password"],
.main-header .search-panel select{
	display:block;
	width:100%;
	height:40px;
	color:#000000;
	line-height:24px;
	background:#ffffff;	
	border:1px solid #e0e0e0;
	padding:7px 40px 7px 15px;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-header .search-panel input:focus,
.main-header .search-panel select:focus{
	border-color:#aed0f1;	
}

.main-header .search-panel .search-btn{
	position:absolute;
	right:0px;
	top:0px;
	width:40px;
	height:40px;
	text-align:center;
	color:#555555;
	font-size:12px;
	background:none;
	cursor:pointer;
}

.header-style-two .header-top{
	background-color:#020e28;
}

.main-menu{
	position:static;
	float:left;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-menu .navbar-collapse{
	padding:0px;	
}

.main-menu .nav{
	position:static;
	margin:0px;
}

.main-menu .nav > li{
	position:relative;
	float:left;
	padding:17px 0px;
	margin-left:20px;
}

.main-menu .nav > li.has-mega-menu{
	position:static;
}

.main-menu .nav > li.has-mega-menu:before,
.main-menu .nav > li.has-mega-menu:after{
	display:none;	
}

.header-style-four .nav > li{
	padding-bottom:0px;
}

.main-menu .nav > li:after{
	position:absolute;
	content:'';
	left:0px;
	bottom:0px;
	width:100%;
	height:2px;
	opacity:0;
	background-color:#aed0f1;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-menu .nav > li.current::before,
.main-menu .nav > li.current::after{
	opacity:1;
}

.main-menu .nav > li:hover::before,
.main-menu .nav > li:hover::after{
	opacity:1;
}

.main-menu .nav > li > a{
	position:relative;
	display:block;
	padding:20px 0px;
	color:#333333;
	text-align:center;
	line-height:30px;
	letter-spacing:0px;
	font-weight:500;
	font-size:16px;
	text-transform:capitalize;
	opacity:1;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;	
	font-family: 'Poppins', sans-serif !important;
}

.header-style-two .main-menu .nav > li > a{
	color:#020e28;
}

.header-style-two .nav-outer .outer-box .quote-btn{
	color:#020e28;
	border-color:#e6e6e6;
}

.header-style-two .nav-outer .outer-box .quote-btn:after{
	background-color:#e6e6e6;
}

.sticky-header .main-menu .nav > li > a{
	padding:0;	
}

.main-menu .nav > li:hover > a,
.main-menu .nav > li.current > a{
	color:#aed0f1;
	opacity:1;
}

.main-menu .nav > li:hover > a:before,
.main-menu .nav > li.current > a:before{
	width:100%;
}

.main-menu .nav > li > ul{
	position:absolute;
	left:0px;
	top:100%;
	width:270px;
	z-index:100;
	display:none;
	padding:5px 20px;
	background:#ffffff;
	-webkit-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-ms-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-o-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-moz-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	font-family:'Poppins',sans-serif;
	border-bottom:2px solid #aed0f1;
}

.main-menu .nav > li > ul:before{
	position:absolute;
	content:'';
	left:0px;
	top:-12px;
	width:100%;
	height:12px;
}

.main-menu .nav > li > ul.from-right{
	left:auto;
	right:0px;	
}

.main-menu .nav > li > ul > li{
	position:relative;
	width:100%;
	border-bottom:1px solid #f2f2f2;
	text-transform:capitalize;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-menu .nav > li > .mega-menu{
	position:absolute;
	left:0px;
	width:100%;
	background-color: #ffffff;
    padding: 45px 40px 30px 45px;
    top: 100%;
	z-index:10;
	margin-top:10px;
	display:none;
	background-position:right bottom;
	background-repeat:no-repeat;
	-webkit-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-ms-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-o-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-moz-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
}

.main-menu .nav > li > .mega-menu:before{
	position:absolute;
	content:'';
	left:0px;
	top:-10px;
	width:100%;
	height:10px;
}

.main-menu .nav > li:hover > .mega-menu{
	opacity:1;
	visibility:visible;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-menu .nav > li > .mega-menu .mega-menu-bar{
	position: relative;
}

.main-menu .nav > li .mega-menu-bar h3{
	position:relative;
	color:#020e28;
	font-size:20px;
	font-weight:500;
	padding-bottom:10px;
	border-bottom:2px solid #f2f2f2;
}

.main-menu .nav > li .mega-menu-bar > ul{
	position:relative;
}

.main-menu .nav > li .mega-menu-bar .column > ul > li{
	position:relative;
	width:100%;
	border-bottom:1px solid #f2f2f2;
	text-transform:capitalize;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-menu .nav > li .mega-menu-bar .column{
	position:relative;
	margin-bottom:10px;
}

.main-menu .nav > li .mega-menu-bar .column > ul > li:last-child{
	border:none;
}

.main-menu .nav > li .mega-menu-bar .column > ul > li > a{
	position:relative;
	display:block;
	padding:13px 0px;
	line-height:24px;
	font-weight:400;
	font-size:16px;
	color:#848484;
	text-align:left;
	text-transform:capitalize;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	font-family: 'Poppins', sans-serif;
}

.main-menu .nav > li > ul > li:last-child{
	border-bottom:none;	
}

.main-menu .nav > li > ul > li > a{
	position:relative;
	display:block;
	padding:13px 0px;
	line-height:24px;
	font-weight:400;
	font-size:16px;
	color:#848484;
	text-align:left;
	text-transform:capitalize;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	font-family: 'Poppins', sans-serif;
}

.main-menu .nav > li > ul > li:hover{
	border-color:#aed0f1;
}

.main-menu .nav > li > ul > li:hover > a,
.main-menu .nav > li .mega-menu-bar .column > ul > li > a:hover{
	color:#aed0f1;
}

.main-menu .nav > li > ul > li.dropdown > a:after{
	font-family: 'FontAwesome';
	content: "\f105";
	position:absolute;
	right:0px;
	top:15px;
	width:10px;
	height:20px;
	display:block;
	line-height:21px;
	font-size:16px;
	font-weight:normal;
	text-align:center;
	z-index:5;	
}

.main-menu .nav > li > ul > li.dropdown:hover > a:after{
	color:#aed0f1;
}

.main-menu .nav > li > ul > li > ul{
	position:absolute;
	left:109%;
	top:0px;
	width:270px;
	z-index:100;
	display:none;
	padding:5px 20px;
	background:#ffffff;
	-webkit-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-ms-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-o-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-moz-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	font-family:'Poppins',sans-serif;
	border-bottom:2px solid #aed0f1;
}

.main-menu .nav > li > ul > li > ul > li{
	position:relative;
	width:100%;
	border-bottom:1px solid #f2f2f2;
	text-transform:capitalize;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-menu .nav > li > ul > li > ul > li:hover{
	border-color:#aed0f1;
}

.main-menu .nav > li > ul > li > ul > li:last-child{
	border-bottom:none;	
}

.main-menu .nav > li > ul > li  > ul > li > a{
	position: relative;
    display: block;
    padding: 13px 0px;
    line-height: 24px;
    font-weight: 400;
    font-size: 16px;
    color: #848484;
    text-align: left;
    text-transform: capitalize;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    font-family: 'Poppins', sans-serif;
}

.main-menu .nav > li > ul > li  > ul > li > a:hover{
	color:#aed0f1;
}

.main-menu .nav > li.dropdown:hover > ul{
	visibility:visible;
	opacity:1;
	top:100%;
	margin-top:10px;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-menu .nav li > ul > li.dropdown:hover > ul{
	visibility:visible;
	opacity:1;
	top:0px;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;	
}

.main-menu .nav li.dropdown .dropdown-btn{
	position:absolute;
	right:10px;
	top:6px;
	width:34px;
	height:30px;
	border:1px solid #ffffff;
	text-align:center;
	font-size:16px;
	line-height:26px;
	color:#ffffff;
	cursor:pointer;
	z-index:5;
	display:none;
}

.main-header .header-upper{
	position:relative;
	background:#ffffff;
	z-index:5;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.main-header .header-upper .logo img{
	position:relative;
	display:inline-block;
	max-width:100%;
}

.main-header .header-upper .logo-outer{
	position:relative;
	z-index:25;
	padding:18px 0px;
}

/*Sticky Header*/

.sticky-header{
	position:fixed;
	opacity:0;
	visibility:hidden;
	left:0px;
	top:0px;
	width:100%;
	padding:0px 0px;
	background:#ffffff;
	z-index:0;
	transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
}

.sticky-header .logo{
	padding:14px 0px 15px;
}

.sticky-header .logo a {
    color: #333333;
}

.fixed-header .sticky-header{
	z-index:999;
	opacity:1;
	visibility:visible;
	-ms-animation-name: fadeInDown;
	-moz-animation-name: fadeInDown;
	-op-animation-name: fadeInDown;
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	-ms-animation-duration: 500ms;
	-moz-animation-duration: 500ms;
	-op-animation-duration: 500ms;
	-webkit-animation-duration: 500ms;
	animation-duration: 500ms;
	-ms-animation-timing-function: linear;
	-moz-animation-timing-function: linear;
	-op-animation-timing-function: linear;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-ms-animation-iteration-count: 1;
	-moz-animation-iteration-count: 1;
	-op-animation-iteration-count: 1;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;	
	-webkit-box-shadow: 0px 6px 15px 0px rgba(0,0,0,0.10);
	-moz-box-shadow: 0px 6px 15px 0px rgba(0,0,0,0.10);
	box-shadow: 0px 6px 15px 0px rgba(0,0,0,0.10);
}

.main-header .sticky-header .main-menu .nav > li{
	margin-right:0px;
	margin-left:20px;
	padding:20px 0px 10px;
}

.main-header .sticky-header .main-menu .nav > li > a{
	color:#333333;
}

.main-header .sticky-header .main-menu .nav > li:before,
.main-header .sticky-header .main-menu .nav > li:after{
	display:none;
}

.main-header .sticky-header .right-col{
	position:relative;
}

/*** 

====================================================================
	Main Slider style
====================================================================

***/

.main-slider{
	position:relative;
	z-index:10;
}

.main-slider .tp-caption{
	z-index:5 !important;
}

.main-slider .tp-dottedoverlay{
	background:rgba(0,0,0,0.40) !important;	
}

.main-slider .tparrows.gyges{
	width:60px;
	height:60px;
	line-height:60px;
	border-radius:50%;
	background-color:rgba(255,255,255,0.20);
}

.main-slider .tparrows.gyges:before{
	line-height:60px;
}

.main-slider .tparrows.gyges:hover{
	color:#aed0f1;
	background-color:#aed0f1;
}

.main-slider h1{
	position:relative;
	color:#ffffff;
	font-size:40px;
	font-weight:600;
	line-height:1.2em;
	display:inline-block;
	text-transform: uppercase;
}

.main-slider h1.dark{
	color:#020e28;
}

.main-slider h1.alternate{
	padding-bottom:25px;
	border-bottom:1px solid rgba(255,255,255,0.20);
}

.main-slider h1.style-two{
	padding-bottom:25px;
	border-bottom:1px solid rgba(0,0,0,0.10);
}

.main-slider .text{
	position:relative;
	color:#d8d8d8;
	font-size:18px;
	line-height:1.7em;
}

.main-slider .text.style-two{
	color:#848484;
}

.main-slider .text.alternate{
	color:#ffffff;
	font-size:20px;
}

.main-slider .text.style-three{
	color:#020e28;
	font-size:20px;
}

.main-slider .know_more{
	position:relative;
	color:#aed0f1;
	font-weight:600;
	font-size:14px;
	padding-bottom:2px;
	text-transform:uppercase;
	transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	border-bottom:1px solid #aed0f1;
	font-family: 'Poppins', sans-serif !important;
}

.main-slider .know_more .icon{
	margin-right:4px;
}

.main-slider .know_more:hover{
	color:#ffffff;
}

.main-slider .btns-box .theme-btn{
	margin-right:20px;
}

.main-slider .tp-bannertimer,
.main-slider .tp-bullets{
	display:none !important;	
}

/*** 

====================================================================
	Section Title
====================================================================

***/

.sec-title{
	position:relative;
	margin-bottom:50px;
}

.sec-title h2{
	position:relative;
	font-size:30px;
	font-weight:600;
	color:#020e28;
	line-height:1.2em;
	padding:0px 0px 24px;
	display:inline-block;
	text-transform:uppercase;
}

.sec-title.light h2{
	color:#ffffff;
	font-weight: 500;
    font-size: 33px;
}

.sec-title .separator{
	position:relative;
	width:75px;
}

.sec-title .separator:before{
	position:absolute;
	content:'';
	left:0px;
	bottom:0px;
	width:75px;
	height:2px;
	background-color:#aed0f1;
}

/*.sec-title .separator:after{
	position:absolute;
	content:'';
	left:0px;
	bottom:2px;
	width:25px;
	height:4px;
	background-color:#aed0f1;
}*/

.sec-title .separator.centered::after{
	margin-left:25px;
}

.sec-title.centered .separator{
	margin:0 auto;
}

.sec-title.centered .separator::after{
	left:50%;
	margin-left:-12px;
}

/*** 

====================================================================
	Services Section
====================================================================

***/

.services-section{
	position:relative;
	padding-bottom:50px;
	background-position:center center;
	background-repeat:no-repeat;
	background-size:cover;
}

.services-section .inner-container{
	position:relative;
	margin-top:-65px;
	display:inline-block;
}

.services-block{
	position:relative;
	margin-bottom:40px;
}

.services-block .inner-box{
	position:relative;
    max-width: 420px;
    margin: 0 auto;
    display: block;
}

.services-block .inner-box .image{
	position:relative;
}

.services-block .inner-box .image:before{
	position:absolute;
	content:'';
	left:0px;
	bottom:0px;
	width:100%;
	height:30%;
	z-index:1;
	background:-webkit-linear-gradient(top,rgba(0,0,0,0),rgba(2,14,40,0.9));
	background:-moz-linear-gradient(top,rgba(0,0,0,0),rgba(2,14,40,0.9));
	background:-o-linear-gradient(top,rgba(0,0,0,0),rgba(2,14,40,0.9));
	background:-ms-linear-gradient(top,rgba(0,0,0,0),rgba(2,14,40,0.9));
}

.services-block .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
}

.services-block .inner-box .lower-content{
	position:relative;
	text-align:center;
	padding:32px 0px 25px;
}

.services-block .inner-box .lower-content .icon-box{
	position:relative;
	color:#c4c4c4;
	font-size:60px;
	line-height:1em;
	margin-bottom:20px;
}

.services-block .inner-box .lower-content h3{
	position:relative;
	color:#020e28;
	font-size:18px;
	font-weight:500;
}

.services-block .inner-box .lower-content:after{
	position:absolute;
	content:'';
	left:50%;
	bottom:0px;
	width:90px;
	height:3px;
	margin-left:-45px;
	background-color:#e0e0e0;
}

.services-block .inner-box .overlay-box{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	z-index:2;
	opacity:0;
	padding:55px 20px 50px;
	background-color:#aed0f1;
	transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
}

.services-block .inner-box .overlay-box:after{
	position:absolute;
	content:'';
	left:50%;
	bottom:0px;
	width:90px;
	height:3px;
	margin-left:-45px;
	background-color:rgba(0,0,0,0.20);
}

.services-block .inner-box:hover .overlay-box{
	opacity:1;
}

.services-block .inner-box .overlay-box .overlay-inner{
	position:relative;
	text-align:center;
	height:100%;
}

.services-block .inner-box .overlay-box .overlay-inner .overlay-lower-inner{
	position:absolute;
	bottom:0px;
	width:100%;
	text-align:center;
}

.services-block .inner-box .overlay-box .overlay-inner .text{
	position:relative;
	color:#ffffff;
	font-size:16px;
	line-height:1.9em;
	font-weight:400;
	margin-bottom:20px;
	font-family: 'Poppins', sans-serif;
}

.services-block .inner-box .overlay-box .overlay-inner .know-more{
	position:relative;
	color:#ffffff;
	font-size:14px;
	font-weight:600;
	letter-spacing:1px;
	text-transform:uppercase;
	transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	font-family: 'Poppins', sans-serif !important;
}

.services-block .inner-box .overlay-box .overlay-inner .know-more:hover{
	color:#041332;
}

.services-block .inner-box .overlay-box .overlay-inner .overlay-icon{
	position:relative;
	color:#ffffff;
	font-size:60px;
	line-height:1em;
	margin-top:0px;
	margin-bottom:20px;
}

.services-block .inner-box .overlay-box .overlay-inner h4{
	position:relative;
	color:#020e28;
	font-size:18px;
	font-weight:500;
}

.services-block .inner-box .overlay-box .overlay-inner h4 a{
	position:relative;
	color:#ffffff;
	transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
}

.services-block .inner-box .overlay-box .overlay-inner h4 a:hover{
	color:#041332;
}

/*** 

====================================================================
	Market Section
====================================================================

***/

.market-section{
	position:relative;
	padding:80px 0px;
	background-position:center center;
	background-repeat:no-repeat;
	background-size:cover;
}

.market-section:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	background-color: #009ce1;
}

.market-section .title-column{
	position:relative;
	display: flex;
	align-items: center;
}

.market-section .title-column:after,
.market-section .market-block:after {
    content: "";
    position: absolute;
    bottom: 30px;
    left: 30px;
    width: calc(100% - 60px);
    height: 1px;
    background: rgba(255,255,255,0.3);
}

.market-section .market-block:nth-child(4):after,
.market-section .market-block:nth-child(5):after,
.market-section .market-block:nth-child(6):after {
    display: none;
}

.market-section .title-column .sec-title{
	margin-bottom:0;
}

.market-section .title-column .inner-column{
	position:relative;
	padding-bottom:0;
	/*border-bottom:1px solid rgba(255,255,255,0.20);*/
}

.market-section .title-column .inner-column .text{
	position:relative;
	color:#c4c4c4;
	font-size:16px;
	font-weight:400;
	line-height:1.8em;
	margin-bottom:0;
}

.market-section .title-column .inner-column .view_all{
	position:relative;
	color:#ffffff;
	font-size:14px;
	font-weight:600;
	line-height:1.2em;
	letter-spacing:1px;
	text-transform:uppercase;
	transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	font-family: 'Poppins', sans-serif !important;
}

.market-section .title-column .inner-column .view_all .icon{
	position:relative;
	margin-right:4px;
}

.market-section .title-column .inner-column .view_all:hover{
	color:#aed0f1;
}

.market-section .row{
	margin:0px -30px;	
}

.market-section .row .m-block{
	padding:0px 30px;
}

.market-section .row .m-block:nth-child(2) .inner-box:after,
.market-section .row .m-block:nth-child(3) .inner-box:after,
.market-section .row .m-block:nth-child(5) .inner-box:after,
.market-section .row .m-block:nth-child(6) .inner-box:after{
	content:'';
	position:absolute;
	left:-30px;
	bottom:30px;
	top:0px;
	border-left:1px solid rgba(255,255,255,0.20);
}

.market-block{
	position:relative;
	padding-bottom:30px;
}

.market-block .inner-box{
	position: relative;
    padding: 30px 0 0 0;
    margin-bottom: 30px;
	transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	/*border-bottom:1px solid rgba(255,255,255,0.20);*/
}

.market-section .row .m-block:nth-child(4) .inner-box,
.market-section .row .m-block:nth-child(5) .inner-box,
.market-section .row .m-block:nth-child(6) .inner-box{
	border-bottom:none;
}

.market-section .market-block .inner-box:before{
	content:'';
	position:absolute;
	left:-30px;
	right:-30px;
	top:0px;
	bottom:-1px;
	opacity:0;
	visibility:hidden;
	background-color: transparent;	
}

.market-section .market-block .inner-box:hover:before{
	opacity:1;
	visibility:visible;	
}

.market-block .inner-box:hover{
	border-color:#aed0f1;
}

.market-block .inner-box h3{
	position:relative;
	font-size:20px;
	font-weight:500;
	line-height:1.5em;
	margin-bottom:15px;
	min-height: 60px;
	color: white;
}

.market-block .inner-box h3 a{
	position:relative;
	color:#ffffff;
	transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
}

.market-block .inner-box h3 a:hover{
	color:#020e28;
}

.market-block .inner-box .text{
	position:relative;
	min-height: 115px;
	color:#ffffff;
	font-size:16px;
	line-height:1.8em;
	font-weight:400;
	margin-bottom:35px;
	transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
}

.market-block .inner-box:hover .text{
	opacity:1;
	visibility:visible;
}

.market-block .inner-box .icon-box{
	position:relative;
	font-size:50px;
	line-height:1em;
	padding-bottom: 30px;
	transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	color:rgba(255,255,255,0.40);
}

.market-block .inner-box .view_all{
	position:absolute;
	left:0px;
	bottom:30px;
	font-size:14px;
	font-weight:600;
	color:#ffffff;
	letter-spacing:1px;
	opacity:0;
	visibility:hidden;
	transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	text-transform:uppercase;
	font-family: 'Poppins', sans-serif !important;
}

.market-block .inner-box .view_all:hover{
	color:#020e28;
}

.market-block .inner-box:hover .view_all{
	opacity:1;
	visibility:visible;
}

/*** 

====================================================================
	Projects Section
====================================================================

***/

.projects-section{
	position:relative;
	padding:80px 0px;
}

.projects-section .sec-title .text{
	position:relative;
	color:#848484;
	font-size:18px;
	line-height:1.7em;
	font-weight:400;
}

.projects-section .sec-title .view_all{
	position: relative;
    color: #aed0f1;
    font-weight: 600;
    font-size: 14px;
	float:right;
    padding-bottom: 2px;
    text-transform: uppercase;
    transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    border-bottom: 1px solid #aed0f1;
    font-family: 'Poppins', sans-serif !important;
}

.projects-section .sec-title .view_all .icon{
	position:relative;
	margin-left:5px;
}

.projects-section .sec-title .view_all:hover{
	color:#020e28;
}

.projects-section .project-block{
	position:relative;
	padding:0px;
}

.project-block .inner-box{
	position:relative;
	overflow:hidden;
}

.project-block .inner-box .image{
	position:relative;
}

.project-block .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
}

.project-block .inner-box .image .overlay-box{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
}

.project-block .inner-box .image .overlay-box:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	opacity:0;
	transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
	background-color:rgba(74,74,74,0.70);
}

.project-block .inner-box:hover .image .overlay-box:before{
	opacity:1;
}

.project-block .inner-box .image .overlay-box .content{
	position:absolute;
	content:'';
	left:0px;
	bottom:-38px;
	max-width:390px;
	width:100%;
	padding:15px 30px;
	transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
	background-color:#eaedef;
}

.project-block .inner-box .image .overlay-box .content h3{
	position:relative;
	font-weight:500;
	font-size:18px;
}

.project-block .inner-box .image .overlay-box .content h3 a{
	position:relative;
	color:#020e28;
	transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
}

.project-block .inner-box:hover .image .overlay-box .content h3 a{
	color:#ffffff;
}

.project-block .inner-box .image .overlay-box .content .text{
	position:relative;
	color:#ffffff;
	font-size:16px;
	margin-top:8px;
	transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
}

.project-block .inner-box .image .overlay-box .content .text .icon{
	margin-left:5px;
}

.project-block .inner-box:hover .image .overlay-box .content{
	background-color:#e95715;
	bottom:0px;
}

.project-block .inner-box:hover .image .overlay-box .content .text{
	margin-top:0px;
}

/*** 

====================================================================
	News Section
====================================================================

***/

.news-section{
	position:relative;
	padding:70px 0px 50px;
	background-color:#f4f4f4;
}

.news-section .three-item-carousel .owl-stage-outer{
	padding:10px 20px;
	margin:0px -20px;
}

.news-section .three-item-carousel{
}

.news-block{
	position:relative;
	margin-bottom:40px;
}

.news-section .three-item-carousel .news-block{
	padding:0px 0px;	
}

.news-block .inner-box{
	position:relative;
	padding:44px 30px;
	transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
	background-color:#ffffff;
}

.news-block .inner-box h2{
	position:relative;
	color:#020e28;
	font-size:20px;
	font-weight:500;
	line-height:1.5em;
	margin-bottom:14px;
}

.news-block .inner-box h2 a{
	position:relative;
	color:#020e28;
	transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
}

.news-block .inner-box h2 a:hover{
	color:#aed0f1;
}

.news-block .inner-box .title{
	position:relative;
	color:#aed0f1;
	font-size:14px;
	font-weight:700;
}

.news-block .inner-box .post-date{
	position:relative;
	color:#848484;
	font-size:14px;
	font-weight:500;
	margin-bottom:17px;
}

.news-block .inner-box .text{
	position:relative;
	line-height:1.8em;
	color:#848484;
	font-size:16px;
	margin-bottom:28px;
}

.news-block .inner-box .read-more{
	position:relative;
	color:#020e28;
	font-size:14px;
	font-weight:600;
	text-transform:uppercase;
	font-family: 'Poppins', sans-serif !important;
	transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
}

.news-block .inner-box .read-more .icon{
	position:relative;
	margin-left:5px;
}

.news-block .inner-box .read-more:hover{
	color:#aed0f1;
}

.news-block .inner-box:hover{
	-webkit-box-shadow: 0px 0px 15px 0px rgba(219,219,219,1);
	-moz-box-shadow: 0px 0px 15px 0px rgba(219,219,219,1);
	box-shadow: 0px 0px 15px 0px rgba(219,219,219,1);
}

.news-section .owl-dots{
	display:none;
}

.news-section .owl-nav{
	position:absolute;
	right:0px;
	top:-100px;
}

.news-section .owl-nav .owl-prev .fa,
.news-section .owl-nav .owl-next .fa{
	display:none;
}

.news-section .owl-nav .owl-prev{
	position:relative;
	width:35px;
	height:35px;
	margin-right:14px;
	display:inline-block;
	background:url(../images/icons/arrow-left.png) no-repeat;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.news-section .owl-nav .owl-next{
	position:relative;
	width:35px;
	height:35px;
	display:inline-block;
	background:url(../images/icons/arrow-right.png) no-repeat;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.news-section .owl-nav .owl-prev:hover,
.news-section .owl-nav .owl-next:hover{
	-webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}

/*** 

====================================================================
	Career Section
====================================================================

***/

.career-section{
	position:relative;
	padding:90px 0px 50px;
}

.career-section .video-column{
	position:relative;
	margin-bottom:40px;
}

.career-section .video-column .inner-column{
	position:relative;
	padding-right:35px;
}

.career-section .video-column .inner-column .image{
	position:relative;
}

.career-section .video-column .inner-column .image img{
	position:relative;
	width:100%;
	display:block;
}

.career-section .video-column .inner-column .play-btn{
	position:absolute;
	right:0px;
	bottom:0px;
	padding-left:90px;
	color:#ffffff;
	padding-top:25px;
	margin-left:50px;
	font-size:18px;
	font-weight:600;
	padding-right:20px;
	padding-bottom:22px;
	display:inline-block;
	background-color:#aed0f1;
	text-transform:uppercase;
	font-family: 'Poppins', sans-serif !important;
}

.career-section .video-column .inner-column .play-btn .icon{
	position:absolute;
	left:20px;
	top:12px;
	color:#ffffff;
	font-size:50px;
	line-height:1em;
	font-weight:400;
}

.career-section .content-column{
	position:relative;
}

.career-section .content-column .inner-column{
	position:relative;
}

.career-section .content-column .inner-column .sec-title{
	margin-bottom:30px;
}

.career-section .content-column .inner-column .text{
	position:relative;
	color:#848484;
	font-size:16px;
	line-height:1.8em;
	margin-bottom:22px;
}

.career-section .content-column .inner-column .see_all{
	position:relative;
	color:#020e28;
	font-size:14px;
	font-weight:600;
	text-transform:uppercase;
	font-family: 'Poppins', sans-serif !important;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.career-section .content-column .inner-column .see_all:hover{
	color:#aed0f1;
}

/*** 

====================================================================
	Informations Section
====================================================================

 ***/
 
.informations-section{
	position:relative;
	padding:95px 0px 55px;
	background-attachment:fixed;
	background-repeat:no-repeat;
	background-size:cover;
}

.informations-section:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	display:block;
	height:100%;
	background-color:rgba(245,91,20,0.95);
}

.info-block{
	position:relative;
	margin-bottom:40px;
}

.info-block:after{
	position:absolute;
	content:'';
	left:100%;
	top:25px;
	width:92px;
	height:34px;
	margin-left:-40px;
	display:inline-block;
	background:url(../images/icons/arrow-1.png) right top no-repeat;
}

.info-block:last-child::after{
	display:none;
}

.info-block:nth-child(2)::after{
	background:url(../images/icons/arrow-2.png) right top no-repeat;
}

.info-block .inner-box{
	position:relative;
	text-align:center;
}

.info-block .inner-box{
	position:relative;
}

.info-block .inner-box .icon-box{
	position:relative;
	width:100px;
	height:100px;
	line-height:100px;
	text-align:center;
	font-size:52px;
	color:#ffffff;
	margin:0 auto;
	margin-bottom:15px;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	border:2px solid rgba(255,255,255,0.20);
}

.info-block .inner-box:hover .icon-box{
	border:2px solid rgba(255,255,255,0.60);
}

.info-block .inner-box .icon-box:after{
	position:absolute;
	content:'';
	left:12px;
	bottom:-40px;
	height:50px;
	width:1px;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	background-color:rgba(255,255,255,0.20);
}

.info-block .inner-box:hover .icon-box:after{
	background-color:rgba(255,255,255,0.60);
}

.info-block .inner-box h3{
	position:relative;
	font-size:18px;
	font-weight:500;
	text-align:left;
	margin-left:120px;
}

.info-block .inner-box h3 a{
	position:relative;
	color:#ffffff;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.info-block .inner-box h3 a:hover{
	color:#020e28;
}

/*** 

====================================================================
	Achivements Section
====================================================================

 ***/

.achivements-section{
	position:relative;
	padding:80px 0px 50px;
	background-repeat:no-repeat;
	background-size:cover;
}

.achivements-block{
	position:relative;
	margin-bottom:40px;
}

.achivements-block .inner-box{
	position:relative;
	border:1px solid #e6e6e6;
}

.achivements-block .inner-box .image{
	position:relative;
	text-align:center;
}

.achivements-block .inner-box .upper-box{
	position:relative;
	border-bottom:1px solid #e6e6e6;
}

.achivements-block .inner-box .lower-content{
	position:relative;
	padding:24px 25px;
	text-align:center;
}

.achivements-block .inner-box .lower-content h3{
	position:relative;
	color:#020e28;
	font-size:18px;
	font-weight:500;
	line-height:1.6em;
	margin-bottom:15px;
}

.achivements-block .inner-box .lower-content .text{
	position:relative;
	color:#848484;
	font-size:16px;
	line-height:1.8em;
}

/*** 

====================================================================
	Testimonial Section
====================================================================

***/

.testimonial-section{
	position:relative;
	padding:80px 0px 90px;
}

.testimonial-section .owl-stage-outer{
	border-bottom:1px solid #f2f2f2;
}

.testimonial-section .owl-stage-outer:before{
	position:absolute;
	content:'';
	left:50%;
	top:0px;
	width:1px;
	height:100%;
	background-color:#f2f2f2;
}

.testimonial-block{
	position:relative;
}

.testimonial-block .inner-box{
	position:relative;
	padding-left:210px;
}

.testimonial-block .inner-box .author-img{
	position:absolute;
	left:0px;
	top:0px;
}

.testimonial-block .inner-box .quote-icon{
	position:relative;
	font-size:36px;
	line-height:1em;
	color:#d0d0d0;
	margin-bottom:15px;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.testimonial-block .inner-box h3{
	position:relative;
	color:#020e28;
	font-size:18px;
	font-weight:600;
	margin-bottom:1px;
	font-family: 'Poppins', sans-serif !important;
}

.testimonial-block .inner-box:hover .quote-icon{
	color:#aed0f1;
}

.testimonial-block .inner-box .location{
	position:relative;
	color:#aed0f1;
	font-size:16px;
	font-weight:400;
	margin-bottom:15px;
}

.testimonial-block .inner-box .text{
	position:relative;
	color:#848484;
	font-size:18px;
	line-height:1.6em;
	margin-bottom:20px;
}

.testimonial-block .inner-box .read{
	position:relative;
	color:#020e28;
	font-size:14px;
	font-weight:600;
	margin-bottom:30px;
	display:inline-block;
	text-transform:uppercase;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	font-family: 'Poppins', sans-serif !important;
}

.testimonial-block .inner-box:hover .read{
	color:#aed0f1;
}

.testimonial-block .inner-box .read .icon{
	margin-left:5px;
	font-weight:700;
}

.testimonial-block.style-two .inner-box{
	padding-left:0px;
	padding-right:210px;
}

.testimonial-block.style-two .inner-box .author-img{
	right:0px;
	left:auto;
}

.testimonial-section .owl-nav{
	display:none;
}

.testimonial-section .owl-dots{
	position:relative;
	text-align:center;
	margin-top:30px;
}

.testimonial-section .owl-dots .owl-dot{
	position:relative;
	width:9px;
	height:9px;
	margin:0px 5px;
	display:inline-block;
	border-radius:50%;
	background-color:#dedede;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.testimonial-section .owl-dots .owl-dot:hover,
.testimonial-section .owl-dots .owl-dot.active{
	position:relative;
	width:22px;
	height:8px;
	border-radius:10px;
	background-color:#ff4800;
}

/*** 

====================================================================
	Clients Section
====================================================================

***/

.clients-section{
	position:relative;
	padding:50px 0px 50px;
	background-color:#f4f4f4;
}

.clients-section .sponsors-outer{
	position:relative;
}

.clients-section .sponsors-outer:after{
	position:absolute;
	content:'';
	right:0px;
	top:0px;
	width:2px;
	height:100%;
	z-index:1;
	background-color:#f4f4f4;
}

.clients-section .sponsors-outer .owl-dots,
.clients-section .sponsors-outer .owl-nav{
	position:relative;
	display:none;
}

.clients-section .sponsors-outer .image-box{
	position:relative;
	text-align:center;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.clients-section .sponsors-outer .image-box{
	border-right:1px solid #e6e6e6;
}

.clients-section .sponsors-outer .image-box img{
	max-width:100%;
	width:auto;
	display:inline-block;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.clients-section .sponsors-outer .image-box img:hover{
	opacity:0.7;
}

/*** 

====================================================================
	Call To Action Section
====================================================================

***/

.call-to-action-section{
	position:relative;
	padding:45px 0px;
	background-attachment:fixed;
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center bottom;
}

.call-to-action-section:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	background-color:rgba(245,91,20,0.95);
}

.call-to-action-section h2{
	position:relative;
	color:#ffffff;
	font-size:24px;
	font-weight:500;
	line-height:1.4em;
}

.call-to-action-section .link-column{
	position:relative;
	text-align:right;
}

.call-to-action-section .apointment{
	position:relative;
	color:#ffffff;
	font-size:14px;
	font-weight:600;
	text-transform:uppercase;
	border-bottom:2px solid #ffffff;
	font-family: 'Poppins', sans-serif !important;
}

/*** 

====================================================================
	Main Footer
====================================================================

***/

.main-footer{
	position:relative;
	background-color:#020e28;
	z-index:10;
}

.main-footer .widgets-section{
	position:relative;
	padding:80px 0px 40px;
}

.main-footer .footer-widget{
	position:relative;
	margin-bottom:30px;
}

.main-footer .footer-title{
	position:relative;
	margin-bottom:30px;
}

.main-footer .footer-title h2{
	position:relative;
	font-size:20px;
	font-weight:500;
	color:#ffffff;
	padding-bottom:18px;
	text-transform:capitalize;
}

.main-footer .footer-title h2:after{
	position:absolute;
	content:'';
	left:0px;
	bottom:0px;
	width:35px;
	height:4px;
	background-color:#aed0f1;
}

.main-footer .logo-widget{
	position:relative;
}

.main-footer .logo-widget .widget-content{
	position:relative;
}

.main-footer .logo-widget .logo{
	position:relative;
	margin-bottom:25px;
}

.main-footer .logo-widget .widget-content .text{
	position:relative;
	color:#ffffff;
	font-size:16px;
	font-weight:400;
	margin-bottom:12px;
}

.main-footer .logo-widget .widget-content .list{
	position:relative;
	margin-bottom:20px;
}

.main-footer .logo-widget .widget-content > .list > li{
	position:relative;
	color:#c4c4c4;
	font-size:16px;
	padding-right:12px;
	margin-right:12px;
	line-height:1.2em;
	display:inline-block;
	border-right:1px solid #414a5e;
}

.main-footer .logo-widget .widget-content > .list > li:last-child{
	border-right:0px;
	margin-right:0px;
	padding-right:0px;
}

.main-footer .logo-widget .widget-content .timing{
	position:relative;
	color:#ffffff;
	font-size:16px;
	line-height:1.8em;
	padding-bottom:28px;
	margin-bottom:25px;
	border-bottom:1px solid rgba(255,255,255,0.10);
}

.main-footer .logo-widget .widget-content .timing span{
	font-weight:500;
	display:block;
}

.main-footer .logo-widget .widget-content .social-icon-one li{
	margin-left:0px;
	margin-right:22px;
}

.main-footer .links{
	position:relative;
}

.main-footer .links li{
	position:relative;
	margin-bottom:16px;
}

.main-footer .links li a{
	position:relative;
	color:#c4c4c4;
	font-size:16px;
	padding-bottom:5px;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-footer .links li a:after{
	position:absolute;
	content:'';
	left:0px;
	bottom:0px;
	width:20px;
	height:2px;
	opacity:0;
	background-color:#aed0f1;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.main-footer .links li a:hover::after{
	opacity:1;
}

.main-footer .links li a:hover{
	color:#ffffff;
}

.main-footer .footer-bottom{
	position:relative;
	padding-top:45px;
	background-color:#041332;
}

.main-footer .footer-bottom.style-two{
	padding-top:0px;
}

.main-footer .footer-bottom.style-two .copyright{
	border-top:0px;
	margin-top:10px;
}

.main-footer .footer-bottom .title-column{
	position:relative;
}

.main-footer .footer-bottom .title-column .text{
	position:relative;
	padding-left:80px;
	color:#ffffff;
	font-size:16px;
	font-weight:500;
	font-family: 'Poppins', sans-serif !important;
}

.main-footer .footer-bottom .title-column .text .icon{
	position:absolute;
	left:0px;
	top:10px;
	color:#aed0f1;
	font-size:40px;
	line-height:1em;
}




.main-footer .footer-bottom .subscribe-form{
	margin-bottom:20px;
}

.main-footer .footer-bottom .subscribe-form .form-group{
	position:relative;
	display:block;
	margin-bottom:10px;
}

.main-footer .footer-bottom .subscribe-form .form-group input[type="text"],
.main-footer .footer-bottom .subscribe-form .form-group input[type="email"]{
	position:relative;
	display:block;
	width:100%;
	line-height:26px;
	padding:10px 0px;
	height:48px;
	font-size:15px;
	color:#ffffff;
	background:none;
	font-weight:400;
	border-bottom:4px solid rgba(255,255,255,0.20);
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;	
}

.main-footer .footer-bottom .subscribe-form .form-group button{
	position:absolute;
	right:0px;
	top:10px;
	font-size:14px;
	color:#aed0f1;
	font-weight:600;
	background:none;
	letter-spacing:1px;
	text-transform:uppercase;
	font-family: 'Poppins', sans-serif !important;
}

.main-footer .footer-bottom .copyright{
	position:relative;
	text-align:center;
	color:#ffffff;
	padding:20px 0px;
	margin-top:30px;
	border-top:1px solid rgba(255,255,255,0.10);
}

/*** 

====================================================================
	Services Section Two
====================================================================

***/

.services-section-two{
	position:relative;
	background-position:center center;
	background-repeat:no-repeat;
	background-size:cover;
}

.services-block-two{
	position:relative;
	padding:0px !important;
}

.services-block-two .inner-box{
	position:relative;
	text-align:center;
	padding:80px 15px 60px;
	transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
}

.services-block-two:first-child .inner-box .lower-content .text,
.services-block-two:nth-child(3) .inner-box .lower-content .text {
    padding: 0 40px !important;
}
.services-block-two:last-child .inner-box .lower-content .text {
	padding: 0 10px !important;
}

.services-block-two .inner-box:hover{
	background-color:#aed0f1;
}

.services-block-two .inner-box .upper-box{
	position:relative;
}

.services-block-two .inner-box .upper-box .icon-box{
	position: relative;
    color: #c4c4c4;
    font-size: 60px;
    line-height: 1em;
    margin-bottom: 0;
	transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
}

/*.services-block-two .inner-box .upper-box .icon-box img {
	height: 100%;
	width: auto;
}*/

.services-block-two .inner-box:hover .upper-box .icon-box,
.services-block-two .inner-box:hover .upper-box h3,
.services-block-two .inner-box:hover .lower-content .text{
	color:#ffffff;
}
.services-block-two .inner-box:hover img {
	filter: brightness(5);
}

.services-block-two .inner-box .upper-box h3{
	position: relative;
    font-size: 18px;
    font-weight: 500;
	padding:0 20px 18px 20px;
	margin-bottom:40px;
	display: flex;
    align-items: center;
    justify-content: center;
	transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
}

.services-block-two .inner-box .upper-box h3 a{
	position:relative;
	color: #020e28;
	transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
}

.services-block-two .inner-box .upper-box h3:after{
	position: absolute;
    content: "";
    left: 50%;
    transform: translateX(-25%);
    bottom: 0;
    width: 190px;
    height: 2px;
    margin-left: -45px;
	transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    background-color: #aed0f1;
}

.services-block-two .inner-box:hover .upper-box h3:after{
	background-color:rgba(255,255,255,0.40);
}

.services-block-two .inner-box .lower-content{
	position:relative;
}

.services-block-two .inner-box .lower-content .text{
	position:relative;
	color:#848484;
	font-size:16px;
	font-weight:400;
	line-height:1.8em;
	margin-bottom:20px;
	transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
}

.services-block-two .inner-box .lower-content .know-more{
	position: relative;
    color: #ffffff;
    font-size: 14px;
	opacity:0;
	visibility:hidden;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    font-family: 'Poppins', sans-serif !important;
}

/*** 

====================================================================
	Market Section Two
====================================================================

***/

.market-section-two{
	position:relative;
	padding:90px 0px;
}

.market-section-two .view-all{
	position:relative;
	color:#aed0f1;
	font-size:14px;
	font-weight:600;
	display:inline-block;
	margin-top:10px;
	text-transform:uppercase;
	border-bottom:1px solid #aed0f1;
	font-family: 'Poppins', sans-serif !important;
}

.market-section-two .view-all .icon{
	margin-left:5px;
}

.market-section-two .title-box{
	position:relative;
	margin-bottom:45px;
}

.market-section-two .title-box h2{
	position:relative;
	color:#020e28;
	font-weight:600;
	font-size:30px;
	margin-right:28px;
	line-height:1.8em;
	float:left;
	text-transform:uppercase;	
}

.market-section-two .title-box .text{
	position:relative;
	color:#848484;
	font-size:16px;
	font-weight:400;
	line-height:1.8em;
	display:inline-block;
	max-width:660px;
	padding-left:28px;
	border-left:4px solid #aed0f1;
}

.market-block-two{
	position:relative;
	margin-bottom:30px;
}

.market-block-two .inner-box{
	position:relative;
	overflow:hidden;
}

.market-block-two .inner-box .image{
	position:relative;
}

.market-block img {
    height: 50px;
    width: auto;
}

.market-block-two .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
}

.market-block-two .inner-box .image .overlay-box{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	padding:35px 35px;
}

.market-block-two .inner-box .image .overlay-box h2{
	position:relative;
	font-size:20px;
	font-weight:500;
	color:#ffffff;
}

.market-block-two .inner-box .content-overlay{
	position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    color: #ffffff;
    text-align: center;
    background: #aed0f1;
    -webkit-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    transition: all 700ms ease;
    -webkit-transform: translate(-100%,0%);
    -ms-transform: translate(-100%,0%);
    -o-transform: translate(-100%,0%);
    -moz-transform: translate(-100%,0%);
    transform: translate(-100%,0%);
}

.market-block-two .inner-box .content-overlay .content-inner{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: table;
    vertical-align: middle;
    padding: 10px 0px;
}

.market-block-two .inner-box .content-overlay .content-inner .content{
	position: relative;
    display: table-cell;
    vertical-align: middle;
}

.market-block-two .inner-box .content-overlay .content-inner .content .text{
	position:relative;
	color:#ffffff;
	font-size:16px;
	font-weight:400;
	line-height:1.4em;
	margin-bottom:15px;
}

.market-block-two .inner-box .content-overlay .content-inner .content .go{
	position:relative;
	font-weight:600;
	color:#ffffff;
	text-transform:uppercase;
	font-family: 'Poppins', sans-serif !important;
}

.market-block-two .inner-box .content-overlay .content-inner .content .go .icon{
	position:relative;
	margin-left:5px;
}

.market-block-two .inner-box:hover .content-overlay{
	opacity: 1;
    -webkit-transform: translate(0%);
    -ms-transform: translate(0%);
    -o-transform: translate(0%);
    -moz-transform: translate(0%);
    transform: translate(0%);
}

/*** 

====================================================================
	Testimonial Section Two
====================================================================

***/

.testimonial-section-two{
	position:relative;
}

.testimonial-section-two .testimonial-column{
	position:relative;
}

.testimonial-section-two .testimonial-column .inner-column{
	position:relative;
	margin-right:30px;
	padding:50px 50px 85px;
	background: rgba(55,60,64,0.8);
}

.testimonial-section-two .testimonial-column .inner-column .quote-icon{
	position:absolute;
	right:35px;
	top:40px;
	font-size:80px;
	line-height:1em;
	color:rgba(255,255,255,0.20);
}

.testimonial-block-two{
	position:relative;
}

.testimonial-block-two .inner-box{
	position:relative;
}

.testimonial-block-two .inner-box .author{
	position:relative;
	width:75px;
	height:75px;
	overflow:hidden;
	margin-bottom:22px;
	border-radius:50%;
}

.testimonial-block-two .inner-box .designation {
	position:relative;
	color:#ffffff;
	font-size:18px;
	font-weight:600;
}

.testimonial-block-two .inner-box .designation + h3 {
	position:relative;
	color:#aed0f1;
	font-size:16px;
	font-weight:400;
	margin-bottom:15px;
	font-family: "Poppins", sans-serif;
}

.testimonial-block-two .inner-box .text {
    position: relative;
    color: #f4f4f4;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.7em;
    margin-bottom: 20px;
}

.testimonial-block-two .inner-box .read{
	position:relative;
	color:#aed0f1;
	font-size:14px;
	font-weight:600;
	text-transform:uppercase;
	font-family: 'Poppins', sans-serif !important;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.testimonial-block-two .inner-box .read .icon{
	margin-left:5px;
}

.testimonial-block-two .inner-box .read:hover{
	color:#ffffff;
}

.testimonial-section-two .owl-nav{
	display:none;
}

.testimonial-section-two .owl-dots{
	position:relative;
	text-align:right;
	margin-top:-10px;
}

.testimonial-section-two .owl-dots .owl-dot{
	position:relative;
	width:9px;
	height:9px;
	margin:0px 5px;
	display:inline-block;
	border-radius:50%;
	background-color:#eeeeee;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.testimonial-section-two .owl-dots .owl-dot:hover,
.testimonial-section-two .owl-dots .owl-dot.active{
	position:relative;
	width:22px;
	height:8px;
	border-radius:10px;
	background-color:#009ce1;
}

.testimonial-section-two .content-column{
	position:relative;
}

.testimonial-section-two .content-column .inner-column{
	position:relative;
	padding:80px 0px;
}

.testimonial-section-two .content-column .inner-column .sec-title{
	margin-bottom:30px;
}

.testimonial-section-two .content-column .inner-column .bold-text{
	position:relative;
	color:#aed0f1;
	font-weight:400;
	margin-bottom:30px;
}

.testimonial-section-two .content-column .inner-column .achived-list{
	position:relative;
}

.testimonial-section-two .content-column .inner-column .achived-list li{
	position:relative;
	display:inline-block;
	margin-right:20px;
}

.testimonial-section-two .content-column .inner-column .achived-list li.icon{
	font-size:60px;
	color:#aed0f1;
	line-height:1em;
}

.testimonial-section-two .content-column .inner-column .achived-list li.number{
	color:#020e28;
	font-size:60px;
	font-weight:600;
	line-height:1em;
	font-family: 'Poppins', sans-serif !important;
}

.testimonial-section-two .content-column .inner-column .achived-list li a{
	position:relative;
	top:-15px;
	color:#ffffff;
	font-size:18px;
	font-weight:400;
	padding:7px 12px;
	display:inline-block;
	background-color:#aed0f1;
	border:2px solid #aed0f1;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.testimonial-section-two .content-column .inner-column .achived-list li a:hover{
	background:none;
	color:#aed0f1;
}

.testimonial-section-two .content-column .inner-column .text{
	position:relative;
	color:#848484;
	font-size:16px;
	line-height:1.7em;
	font-weight:400;
	margin-top:28px;
}

/*** 

====================================================================
	Project Section
====================================================================

***/

.project-section{
	position:relative;
	padding:80px 0px 50px;
}

.project-section .filters{
	position:relative;
	text-align:center;
}

.project-section .filters .filter-tabs{
	position:relative;
	margin-bottom:40px;
	text-align:center;
	display:inline-block;
	border-top:3px solid #f2f2f2;
}

.project-section .filters .filter-tabs .filter{
	position:relative;
	color:#020e28;
	font-size:16px;
	cursor:pointer;
	font-weight:400;
	margin-right:35px;
	padding:20px 0px 18px;
	text-transform:capitalize;
	display:inline-block;
	line-height:1em;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	font-family: 'Poppins', sans-serif;
}

.project-section .filters .filter-tabs .filter:last-child{
	margin-right:0px;
}

.project-section .filters .filter-tabs .filter:after{
	position:absolute;
	content:'';
	left:0px;
	top:-3px;
	width:100%;
	height:3px;
	opacity:0;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	background-color:#aed0f1;
}

.project-section .filters .filter-tabs .filter:hover::after,
.project-section .filters .filter-tabs .filter.active::after{
	opacity:1;
}

.project-section .filters .filter-tabs .filter.active,
.project-section .filters .filter-tabs .filter:hover{
	color:#aed0f1;
}

.project-section .project-block-two{
	padding:0px;
}

.project-block-two.mix{
	display:none;
}

.project-block-two{
	position:relative;
	margin-bottom:30px;
}

.project-block-two .inner-box{
	position:relative;
	overflow:hidden;
}

.project-block-two .inner-box .image{
	position:relative;
}

.project-block-two .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
}

.project-block-two .inner-box .lower-box{
	position:relative;
	padding:18px 28px 0px;
	border-left:1px solid #e6e6e6;
}

.project-block-two .inner-box .lower-box h3{
	position:relative;
	color:#020e28;
	font-size:18px;
	font-weight:500;
	margin-bottom:5px;
	text-transform:capitalize;
}

.project-block-two .inner-box .lower-box .title{
	position:relative;
	color:#aed0f1;
	font-size:16px;
	font-weight:400;
	margin-bottom:20px;
}

.project-block-two .inner-box .lower-box .go-title{
	position:relative;
	color:#020e28;
	font-weight:600;
	font-size:14px;
	text-transform:uppercase;
	font-family: 'Poppins', sans-serif !important;
}

.project-block-two .inner-box .lower-box .go-title .icon{
	margin-left:5px;
}

.project-block-two .inner-box .overlay-content{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	opacity:0;
	padding:0px 28px 18px;
	background-size:cover;
	background-repeat:no-repeat;
	-webkit-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    transition: all 700ms ease;
    -webkit-transform: translate(-100%,0%);
    -ms-transform: translate(-100%,0%);
    -o-transform: translate(-100%,0%);
    -moz-transform: translate(-100%,0%);
    transform: translate(-100%,0%);
	background-color:rgba(245,91,20,0.90);
}

.project-block-two .inner-box .overlay-content:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	background-color:rgba(245,91,20,0.90);
}

.project-block-two .inner-box .overlay-content .overlay-inner{
	position:absolute;
	left:28px;
	bottom:16px;
}

.project-block-two .inner-box .overlay-content .overlay-inner h4{
	position:relative;
	color:#ffffff;
	font-size:18px;
	font-weight:500;
	margin-bottom:3px;
	text-transform:capitalize;
}

.project-block-two .inner-box .overlay-content .overlay-inner h4 a{
	position:relative;
	color:#ffffff;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.project-block-two .inner-box .overlay-content .overlay-inner h4 a:hover{
	color:#333333;
}

.project-block-two .inner-box .overlay-content .overlay-inner .title{
	position:relative;
	color:#ffffff;
	font-size:16px;
	font-weight:400;
	margin-bottom:18px;
}

.project-block-two .inner-box .overlay-content .overlay-inner .go{
	position:relative;
	color:#ffffff;
	font-weight:600;
	font-size:14px;
	text-transform:uppercase;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	font-family: 'Poppins', sans-serif !important;
}

.project-block-two .inner-box .overlay-content .overlay-inner .go .icon{
	margin-left:5px;
}

.project-block-two .inner-box .overlay-content .overlay-inner .go:hover{
	color:#333333;
}

.project-block-two .inner-box:hover .overlay-content{
	opacity: 1;
    -webkit-transform: translate(0%);
    -ms-transform: translate(0%);
    -o-transform: translate(0%);
    -moz-transform: translate(0%);
    transform: translate(0%);
}

/*** 

====================================================================
	Call To Action Section Two
====================================================================

***/

.call-to-action-section-two{
	position:relative;
	text-align:center;
	padding:90px 0px 90px;
	background-attachment:fixed;
	background-repeat:no-repeat;
	background-size:cover;
}

.call-to-action-section-two:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	background-color:rgba(2,14,40,0.92);
}

.call-to-action-section-two h2{
	position:relative;
	color:#ffffff;
	font-size:30px;
	font-weight:700;
	line-height:1.5em;
	max-width:1100px;
	margin:0 auto;
	margin-bottom:15px;
}

.call-to-action-section-two .text{
	position:relative;
	color:#c4c4c4;
	font-size:16px;
	line-height:1.9em;
	max-width:880px;
	margin:0 auto;
	margin-bottom:25px;
}

/*** 

====================================================================
	News Section Two
====================================================================

***/

.news-section-two{
	position:relative;
	padding:85px 0px 60px;
}

.news-block-two{
	position:relative;
	margin-bottom:40px;
}

.news-block-two .inner-box{
	position:relative;
	background-color:#ffffff;
	 transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.news-block-two .inner-box:hover{
	-webkit-box-shadow: 0px 0px 30px 0px rgba(234,234,234,1);
	-moz-box-shadow: 0px 0px 30px 0px rgba(234,234,234,1);
	box-shadow: 0px 0px 30px 0px rgba(234,234,234,1);
}

.news-block-two .inner-box .image{
	position:relative;
    background-color: #0b0b0b;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.news-block-two .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
	transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.news-block-two .inner-box:hover .image img{
	opacity:0.5;
}

.news-block-two .inner-box .lower-content{
	position:relative;
	padding:24px 28px;
	border:1px solid #f2f2f2;
}

.news-block-two .inner-box .lower-content h3{
	position:relative;
	color:#020e28;
	font-size:20px;
	font-weight:500;
	line-height:1.5em;
	padding-bottom:15px;
}

.news-block-two .inner-box .lower-content h3 a{
	position:relative;
	color:#020e28;
	transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.news-block-two .inner-box .lower-content h3 a:hover{
	color:#aed0f1;
}

.blog-classic .news-block-two .inner-box .lower-content h3{
	font-size:24px;
}

.blog-classic .styled-pagination li{
	margin-right:0px;
	margin-left:10px;
}

.news-block-two .inner-box .lower-content .title{
	position:relative;
	color:#aed0f1;
	font-size:14px;
	font-weight:700;
	margin-bottom:3px;
	text-transform:capitalize;
	font-family: 'Poppins', sans-serif;
}

.news-block-two .inner-box .lower-content .post-date{
	position:relative;
	color:#848484;
	font-size:14px;
	font-weight:500;
	margin-bottom:12px;
}

.news-block-two .inner-box .lower-content .text{
	position:relative;
	color:#848484;
	font-size:16px;
	line-height:1.8em;
	margin-bottom:18px;
}

.news-block-two .inner-box .lower-content .read-more{
	position:relative;
	color:#020e28;
	font-size:14px;
	font-weight:600;
	text-transform:uppercase;
	font-family: 'Poppins', sans-serif !important;
	transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}

.news-block-two .inner-box .lower-content .read-more .icon{
	position:relative;
	margin-left:5px;
}

.news-block-two .inner-box .lower-content .read-more:hover{
	color:#aed0f1;
}

/*Custom Select*/

.form-group .ui-selectmenu-button.ui-button{
	width:100%;
	font-size:16px;
	font-style:normal;
	line-height:25px;
	color:#797979;
	float:left;
	border-radius:0px;
	border:0px;
	padding:14px 20px;
	background:#ffffff;
}

.form-group .ui-button .ui-icon{
	background: none;
    position: relative;
    top: -1px;
    text-indent: 0px;
    color: #333333;
    font-size: 20px;
}

.form-group .ui-button .ui-icon:before{
	font-family: 'FontAwesome';
	content: "\f0d7";
	position:absolute;
	right:0px;
	top:2px !important;
	top:13px;
	width:10px;
	height:30px;
	display:block;
	color:#aed0f1;
	line-height:23px;
	font-size:18px;
	font-weight:normal;
	text-align:center;
	z-index:5;
}

.ui-widget.ui-widget-content{
	border:1px solid #e0e0e0;
}

.ui-menu .ui-menu-item{
	font-size:14px;
	border-bottom:1px solid #e0e0e0;
}

.ui-menu .ui-menu-item:last-child{
	border:none;	
}

.ui-state-active, .ui-widget-content .ui-state-active{
	background-color:#aed0f1;
	border-color:#aed0f1;
}

.ui-menu .ui-menu-item-wrapper{
	position:relative;
	display:block;
	padding:8px 20px ;
	font-size:14px;
	line-height:24px;
}

.ui-menu-item:hover{
}

/*** 

====================================================================
	Quote Section
====================================================================

***/

.quote-section{
	position:relative;
	padding:90px 0px;
	background-position:center center;
	background-repeat:no-repeat;
	background-size:cover;
}

.quote-box{
	position:relative;
}

.quote-box .inner-box{
	position:relative;
	padding:45px 45px 30px;
	max-width:670px;
	background-color:#373c40;
}

.quote-form{
	position:relative;
}

.quote-form .row{
	position:relative;
}

.quote-form .form-group{
	position:relative;
	margin-bottom:30px;
}

.quote-form .form-group input[type="text"],
.quote-form .form-group input[type="password"],
.quote-form .form-group input[type="tel"],
.quote-form .form-group input[type="email"]{
	position:relative;
	display:block;
	width:100%;
	line-height:32px;
	padding:10px 20px;
	height:52px;
	font-size:16px;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.quote-form .form-group input[type="text"]:focus,
.quote-form .form-group input[type="password"]:focus,
.quote-form .form-group input[type="tel"]:focus,
.quote-form .form-group input[type="email"]:focus,
.quote-form .form-group select:focus,
.quote-form .form-group textarea:focus{
	border-color:#ffb200;
}

.quote-form .form-group textarea{
	position:relative;
	display:block;
	width:100%;
	line-height:32px;
	padding:10px 20px;
	height:110px;
	font-size:16px;
	resize:none;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.quote-form button{
	padding:10px 33px;
	margin-top:15px;
}

.quote-form .text{
	position:relative;
	top:17px;
	display:inline-block;
	color:#c4c4c4;
	font-size:18px;
	line-height:1.7em;
	margin-left:25px;
}

.quote-form.style-two button{
	border-radius:0px;
	margin-top:10px;
}

/*** 

====================================================================
	About Section
====================================================================

***/

.about-section{
	position:relative;
	padding:90px 0px 90px;
}

.about-section .content-column{
	position:relative;
}

.about-section .content-column .inner-column{
	position:relative;
	padding-right:30px;
}

.about-section .content-column .inner-column .sec-title{
	margin-bottom:35px;
}

.about-section .content-column .inner-column .bold-text{
	position:relative;
	color:#020e28;
	font-size:20px;
	font-weight:500;
	line-height:1.6em;
	margin-bottom:20px;
}

.about-section .content-column .inner-column .text{
	position:relative;
	color:#848484;
	font-size:16px;
	line-height:1.7em;
	margin-bottom:38px;
}

.about-section .content-column .inner-column .about-block{
	position:relative;
}

.about-section .content-column .inner-column .about-block li{
	position:relative;
	color:#020e28;
	font-size:18px;
	font-weight:500;
	padding-right:45px;
	margin-right:40px;
	float:left;
	margin-bottom:25px;
	border-right:1px solid #f2f2f2;
	font-family: 'Poppins', sans-serif !important;
}

.about-section .content-column .inner-column .about-block li:last-child{
	padding-right:0px;
	margin-right:0px;
	border-right:0px;
}

.about-section .content-column .inner-column .about-block li .icon{
	position:relative;
	display:block;
	margin-bottom:15px;
}

.about-section .content-column .inner-column .about-block li .icon{
	position:relative;
	opacity:0.20;
}

.about-section .carousel-column{
	position:relative;
}

.about-section .carousel-column .inner-column{
	position:relative;
	padding:45px 25px 50px;
	border:1px solid #f2f2f2;
	background:url(../images/resource/pattern-1.png);
	background-repeat:repeat-x;
	background-position:left bottom;
	-webkit-box-shadow: 0px 0px 15px 0px rgba(219,219,219,1);
	-moz-box-shadow: 0px 0px 15px 0px rgba(219,219,219,1);
	box-shadow: 0px 0px 15px 0px rgba(219,219,219,1);
}

.testimonial-block-three{
	position:relative;
}

.testimonial-block-three .inner-box{
	position:relative;
}

.testimonial-block-three .inner-box .upper-box{
	position:relative;
	padding-left:60px;
	padding-top:8px;
	padding-bottom:25px;
	margin-bottom:25px;
	border-bottom:1px solid #f2f2f2;
}

.testimonial-block-three .inner-box .upper-box .icon{
	position:absolute;
	left:0px;
	top:0px;
	color:#aed0f1;
	font-size:40px;
	line-height:1em;
}

.testimonial-block-three .inner-box .upper-box h2{
	position:relative;
	color:#020e28;
	font-size:18px;
	font-weight:500;
	text-transform:uppercase;
}

.testimonial-block-three .inner-box .text{
	position:relative;
	color:#848484;
	font-size:16px;
	line-height:1.7em;
	margin-bottom:75px;
}

.about-section .carousel-column .owl-nav{
	display:none;
}

.about-section .carousel-column .owl-dots{
	position:relative;
}

.about-section .carousel-column .owl-dots .owl-dot{
	position:relative;
	width:9px;
	height:9px;
	margin:0px 5px;
	display:inline-block;
	border-radius:50%;
	background-color:#c4c4c4;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.about-section .carousel-column .owl-dots .owl-dot:hover,
.about-section .carousel-column .owl-dots .owl-dot.active{
	position:relative;
	width:22px;
	height:8px;
	border-radius:10px;
	background-color:#ff4800;
}

/*** 

====================================================================
	Market Section Three
====================================================================

***/

.market-section-three{
	position:relative;
	padding:80px 0px 90px;
}

.market-block-three{
	position:relative;
	margin-bottom:30px;
}

.market-block-three .inner-box{
	position:relative;
}

.market-block-three .inner-box .upper-box{
	position:relative;
	padding:25px 25px;
	border:1px solid #f2f2f2;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.market-block-three .inner-box .upper-box h3{
	position:relative;
	color:#020e28;
	font-size:20px;
	font-weight:500;
	line-height:1.5em;
	padding-bottom:20px;
	margin-bottom:25px;
}

.market-block-three .inner-box .upper-box h3 a{
	position:relative;
	color:#020e28;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.market-block-three .inner-box .upper-box h3:after{
	position:absolute;
	content:'';
	left:0px;
	bottom:0px;
	height:3px;
	width:90px;
	background-color:#f2f2f2;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.market-block-three .inner-box .upper-box h3 a:hover{
	color:#aed0f1;
}

.market-block-three .inner-box:hover .upper-box h3 a{
	color:#ffffff;
}

.market-block-three .inner-box:hover .upper-box h3:after{
	background-color:rgba(255,255,255,0.20);
}

.market-block-three .inner-box .upper-box .icon-box{
	position:relative;
	font-size:60px;
	line-height:1em;
	color:#f7793f;
	margin-bottom:15px;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.market-block-three .inner-box .upper-box .text{
	position:relative;
	color:#848484;
	font-size:16px;
	line-height:1.7em;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.market-block-three .inner-box:hover .upper-box{
	background-color:#020e28;
	border-color:#020e28;
}

.market-section-three .owl-nav{
	display:none;
}

.market-section-three .owl-dots{
	position:relative;
	text-align:center;
}

.market-section-three .owl-dots .owl-dot{
	position:relative;
	width:9px;
	height:9px;
	margin:0px 5px;
	display:inline-block;
	border-radius:50%;
	background-color:#c4c4c4;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.market-section-three .owl-dots .owl-dot:hover,
.market-section-three .owl-dots .owl-dot.active{
	position:relative;
	width:22px;
	height:8px;
	border-radius:10px;
	background-color:#ff4800;
}

/*** 

====================================================================
	Testimonial Section Three
====================================================================

***/

.testimonial-section-three{
	position:relative;
	padding:85px 0px;
	background-size:cover;
	background-position:center center;
	background-repeat:no-repeat;
}

.testimonial-section-three:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	background-color:rgba(244,244,244,0.95);
}

.testimonial-block-four{
	position:relative;
}

.testimonial-block-four .inner-box{
	position:relative;
}

.testimonial-block-four .inner-box .content-outer{
	position:relative;
}

.testimonial-block-four .inner-box .content-box{
	position:relative;
	padding:28px 50px;
	z-index:10;
	background-color:#ffffff;
	-webkit-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-ms-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-o-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-moz-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
}

.testimonial-block-four .inner-box .content-box .content{
	position:relative;
	padding-left:150px;
}

.testimonial-block-four .inner-box .content-box .content .quote-icon{
	position:absolute;
	left:0px;
	top:2px;
	color:#f2f2f2;
	line-height:1em;
	font-size:98px;
}

.testimonial-block-four .inner-box .content-box .content .text{
	position:relative;
	color:#848484;
	font-size:18px;
	font-weight:400;
}

.testimonial-block-four .inner-box .content-outer .layers{
	position:relative;
	z-index:5;
}

.testimonial-block-four .inner-box .content-outer .layer-one{
	position:absolute;
	left:50px;
	bottom:-8px;
	height:12px;
	right:50px;
	background-color:#ffffff;
	-webkit-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-ms-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-o-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-moz-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
}

.testimonial-block-four .inner-box .content-outer .layer-two{
	position:absolute;
	left:100px;
	bottom:-15px;
	height:12px;
	right:100px;
	z-index:-1;
	background-color:#ffffff;
	-webkit-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-ms-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-o-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	-moz-box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
	box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
}

.testimonial-block-four .inner-box .author-info{
	position:relative;
	text-align:center;
}

.testimonial-block-four .inner-box .author-info .image{
	position:relative;
	width:75px;
	height:75px;
	overflow:hidden;
	border-radius:50%;
	margin:0 auto;
	margin-top:50px;
	margin-bottom:15px;
}

.testimonial-block-four .inner-box .author-info h3{
	position:relative;
	color:#020e28;
	font-size:18px;
	font-weight:600;
	text-transform:capitalize;
}

.testimonial-block-four .inner-box .author-info .location{
	position:relative;
	color:#aed0f1;
	font-size:16px;
	font-weight:400;
}

.testimonial-section-three .owl-dots{
	display:none;
}

.testimonial-section-three .owl-nav{
	position:absolute;
	width:100%;
	max-width:530px;
	left:50%;
	bottom:90px;
	margin-left:-265px;
}

.testimonial-section-three .owl-nav .owl-prev .fa,
.testimonial-section-three .owl-nav .owl-next .fa{
	display:none;
}

.testimonial-section-three .owl-nav .owl-prev{
	position:absolute;
	left:0px;
	top:0px;
	width:45px;
	height:45px;
	margin-right:14px;
	display:inline-block;
	background:url(../images/icons/arrow-left-1.png) no-repeat;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.testimonial-section-three .owl-nav .owl-next{
	position:absolute;
	right:0px;
	top:0px;
	width:45px;
	height:45px;
	display:inline-block;
	background:url(../images/icons/arrow-right-1.png) no-repeat;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.testimonial-section-three .owl-nav .owl-prev:hover,
.testimonial-section-three .owl-nav .owl-next:hover{
	-webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}

/*** 

====================================================================
	Project Section Two
====================================================================

***/

.project-section-two{
	position:relative;
	padding:90px 0px;
}

.project-section-two .title-column{
	position:relative;
	padding:0px;
}

.project-section-two .title-column .inner-column{
	position:relative;
}

.project-section-two .title-column .inner-column .sec-title{
	margin-bottom:25px;
}

.project-section-two .title-column .inner-column .text{
	position:relative;
	color:#848484;
	font-size:16px;
	line-height:1.7em;
	margin-bottom:15px;
}

.project-section-two .title-column .inner-column .projects{
	position:relative;
	color:#020e28;
	font-size:14px;
	font-weight:600;
	text-transform:uppercase;
	font-family: 'Poppins', sans-serif !important;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.project-section-two .title-column .inner-column .projects .icon{
	position:relative;
	margin-left:4px;
	top:1px;
}

.project-section-two .title-column .inner-column .projects:hover{
	color:#aed0f1;
}

.project-section-two .project-block-three{
	padding:0px;
}

.project-block-three{
	position:relative;
}

.project-block-three .inner-box{
	position:relative;
}

.project-block-three .inner-box .image{
	position:relative;
}

.project-block-three .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
}

.project-block-three .inner-box .image .overlay-box{
	position:absolute;
	left:0px;
	bottom:0px;
	width:100%;
	height:30%;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	background:-webkit-linear-gradient(top,rgba(0,0,0,0),rgba(0,0,0,0.6));
	background:-moz-linear-gradient(top,rgba(0,0,0,0),rgba(0,0,0,0.6));
	background:-o-linear-gradient(top,rgba(0,0,0,0),rgba(0,0,0,0.6));
	background:-ms-linear-gradient(top,rgba(0,0,0,0),rgba(0,0,0,0.6));
}

.project-block-three .inner-box:hover .image .overlay-box{
	opacity:0;
}

.project-block-three .inner-box .image .overlay-box .content{
	position:absolute;
	left:20px;
	bottom:22px;
}

.project-block-three .inner-box .image .overlay-box .content h2{
	position:relative;
	font-weight:600;
	font-size:18px;
	color:#ffffff;
}

.project-block-three .inner-box .image .overlay-box .content .title{
	position:relative;
	color:#ffffff;
	font-size:16px;
	font-weight:400;
}

.project-block-three .inner-box .image .overlay-content-box{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	opacity:0;
	padding:25px 22px;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	background-color:rgba(245,91,20,0.90);
}

.project-block-three .inner-box:hover .image .overlay-content-box{
	opacity:1;
}

.project-block-three .inner-box .image .overlay-content-box h4{
	position:relative;
	font-weight:600;
	font-size:18px;
	color:#ffffff;
}

.project-block-three .inner-box .image .overlay-content-box h4 a{
	position:relative;
	color:#ffffff;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.project-block-three .inner-box .image .overlay-content-box h4 a:hover{
	color:#020e28;
}

.project-block-three .inner-box .image .overlay-content-box .designation{
	position:relative;
	color:#ffffff;
	font-size:16px;
	font-weight:400;
}

.project-block-three .inner-box .image .overlay-content-box .go{
	position:absolute;
	bottom:20px;
	font-size:14px;
	font-weight:600;
	color:#ffffff;
	text-transform:uppercase;
	font-family: 'Poppins', sans-serif !important;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.project-block-three .inner-box .image .overlay-content-box .go .icon{
	margin-left:4px;
}

.project-block-three .inner-box .image .overlay-content-box .go:hover{
	color:#020e28;
}

/*** 

====================================================================
	Clients Section Two
====================================================================

***/

.clients-section-two{
	position:relative;
	padding:90px 0px 90px;
}

.clients-section-two h2{
	position:relative;
	color:#020e28;
	font-size:26px;
	font-weight:500;
	text-align:center;
	margin-bottom:35px;
}

.clients-section-two .sponsors-outer{
	position:relative;
}

.clients-section-two .sponsors-outer .owl-dots,
.clients-section-two .sponsors-outer .owl-nav{
	position:relative;
	display:none;
}

.clients-section-two .sponsors-outer .image-box{
	position:relative;
	text-align:center;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.clients-section-two .sponsors-outer .image-box{
	border:1px solid #f2f2f2;
}

.clients-section-two .sponsors-outer .image-box img{
	max-width:100%;
	width:auto;
	display:inline-block;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.clients-section-two .sponsors-outer .image-box img:hover{
	opacity:0.7;
}

/*** 

====================================================================
	Map Section Style
====================================================================

***/

.map-section{
	position:relative;
}

.map-data{
	text-align:center;
	font-size:14px;
	font-weight:400;
	line-height:1.8em;
}

.map-data h6{
	font-size:16px;
	font-weight:700;
	text-align:center;
	margin-bottom:5px;
	color:#121212;
}

.map-canvas{
	height:425px;
}

/*** 

====================================================================
	Call To Action Section Three
====================================================================

***/

.call-to-action-section-three{
	position:relative;
	padding:45px 0px 45px;
}

.call-to-action-section-three h2{
	position:relative;
	color:#ffffff;
	font-size:24px;
	font-weight:600;
	font-family: 'Poppins', sans-serif !important;
}

.call-to-action-section-three .btn-column{
	position:relative;
	text-align:right;
}

.call-to-action-section-three .btn-column .theme-btn{
	padding:10px 30px;
	margin-top:15px;
}

/*** 

====================================================================
	Market Section Four
====================================================================

***/

.market-section-four{
	position:relative;
	padding:85px 0px;
}

.market-section-four .more{
	position:relative;
	font-size:14px;
	font-weight:600;
	color:#020e28;
	text-transform:uppercase;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
	font-family: 'Poppins', sans-serif !important;
}

.market-section-four .more .icon{
	margin-left:5px;
}

.market-section-four .more:hover{
	color:#aed0f1;
}

.market-block-four{
	position:relative;
	margin-bottom:45px;
}

.market-block-four .inner-box{
	position:relative;
}

.market-block-four .inner-box .image{
	position:relative;
}

.market-block-four .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
}

.market-block-four .inner-box .lower-content{
	position:relative;
	padding:28px 26px;
	border:1px solid #f2f2f2;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.market-block-four .inner-box:hover .lower-content{
	background-color:#aed0f1;
	border-color:#aed0f1;
}

.market-block-four .inner-box .lower-content .icon-box{
	position:absolute;
	bottom:35px;
	color:#aed0f1;
	font-size:58px;
	font-weight:500;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.market-block-four .inner-box:hover .lower-content .icon-box{
	opacity:0;
	visibility:hidden;
}

.market-block-four .inner-box .lower-content h3{
	position:relative;
	font-size:20px;
	font-weight:500;
	line-height:1.4em;
	padding-bottom:25px;
	margin-bottom:24px;
}

.market-block-four .inner-box .lower-content h3:after{
	position:absolute;
	content:'';
	left:0px;
	bottom:0px;
	width:90px;
	height:3px;
	background-color:#f2f2f2;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.market-block-four .inner-box:hover .lower-content h3:after{
	background-color:rgba(255,255,255,0.20);
}

.market-block-four .inner-box .lower-content h3 a{
	position:relative;
	color:#020e28;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.market-block-four .inner-box:hover .lower-content h3 a{
	color:#ffffff;
}

.market-block-four .inner-box .lower-content .text{
	position:relative;
	color:#fceee7;
	font-size:16px;
	line-height:1.7em;
	font-weight:400;
	opacity:0;
	visibility:hidden;
	margin-bottom:20px;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.market-block-four .inner-box .lower-content .link-box{
	position:relative;
	font-size:14px;
	font-weight:600;
	color:#ffffff;
	opacity:0;
	visibility:hidden;
	text-transform:uppercase;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
	font-family: 'Poppins', sans-serif !important;
}

.market-block-four .inner-box:hover .lower-content .text,
.market-block-four .inner-box:hover .lower-content .link-box{
	opacity:1;
	visibility:visible;
}

/*** 

====================================================================
	About Section
====================================================================

***/

.about-section-two{
	position:relative;
	padding:85px 0px 55px;
	background-color:#f4f4f4;
}

.about-section-two .content-column{
	position:relative;
	margin-bottom:30px;
}

.about-section-two .content-column .inner-column{
	position:relative;
}

.about-section-two .content-column .sec-title{
	position:relative;
	margin-bottom:35px;
}

.about-section-two .content-column .bold-text{
	position:relative;
	color:#020e28;
	font-size:20px;
	font-weight:500;
	line-height:1.6em;
	margin-bottom:22px;
}

.about-section-two .content-column .text{
	position:relative;
	color:#848484;
	font-size:16px;
	line-height:1.7em;
	margin-bottom:32px;
	font-family: 'Poppins', sans-serif;
}

.about-section-two .content-column .inner-column .theme-btn{
	padding:12px 45px;
}

.about-section-two .image-column{
	position:relative;
}

.about-section-two .image-column .image{
	position:relative;
}

.about-section-two .image-column .image img{
	position:relative;
	width:100%;
	display:block;
}

/*** 

====================================================================
	Choose Section
====================================================================

***/

.choose-section{
	position:relative;
	padding:85px 0px 55px;
}

.choose-block{
	position:relative;
	margin-bottom:30px;
}

.choose-block .inner-box{
	position:relative;
	text-align:center;
}

.choose-block .inner-box .icon-box{
	position:relative;
	color:#c4c4c4;
	font-size:56px;
	line-height:1em;
	margin-bottom:20px;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.choose-block .inner-box:hover .icon-box{
	color:#aed0f1;
}

.choose-block .inner-box h3{
	position:relative;
	color:#020e28;
	font-size:18px;
	font-weight:500;
	padding-bottom:25px;
	text-transform:capitalize;
}

.choose-block .inner-box h3 a{
	position:relative;
	color:#020e28;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.choose-block .inner-box h3:after{
	position:absolute;
	content:'';
	left:50%;
	bottom:0px;
	width:90px;
	height:3px;
	margin-left:-45px;
	background-color:#f2f2f2;
}

.choose-block .inner-box:hover h3 a{
	color:#aed0f1;
}

.choose-block .inner-box .text{
	position:relative;
	margin-top:25px;
	color:#848484;
	font-size:16px;
	font-weight:400;
}

/*** 

====================================================================
	Project Section Three
====================================================================

***/

.project-section-three{
	position:relative;
	padding:80px 0px 50px;
	background-attachment:fixed;
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center center;
}

.project-section-three:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	background-color:rgba(2,14,40,0.96);
}

.project-block-four{
	position:relative;
	margin-bottom:40px;
}

.project-block-four .inner-box{
	position:relative;
}

.project-block-four .inner-box .image{
	position:relative;
	background-color:#2D2828;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.project-block-four .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.project-block-four .inner-box:hover .image img{
	opacity:0.6;
}

.project-block-four .inner-box .lower-content{
	position:relative;
	padding:25px 20px;
	background-color:#ffffff;
}

.project-block-four .inner-box .lower-content h3{
	position:relative;
	color:#020e28;
	font-size:18px;
	font-weight:500;
	margin-bottom:10px;
}

.project-block-four .inner-box .lower-content h3 a{
	position:relative;
	color:#020e28;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.project-block-four .inner-box .lower-content h3 a:hover{
	color:#aed0f1;
}

.project-block-four .inner-box .lower-content .text{
	position:relative;
	color:#848484;
	font-size:16px;
	font-weight:400;
	margin-bottom:15px;
}

.project-block-four .inner-box .lower-content .read-more{
	position:relative;
	color:#020e28;
	font-size:14px;
	font-weight:600;
	text-transform:uppercase;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
	font-family: 'Poppins', sans-serif !important;
}

.project-block-four .inner-box .lower-content .read-more .icon{
	margin-left:5px;
}

.project-block-four .inner-box .lower-content .read-more:hover{
	color:#aed0f1;
}

.project-block-four.style-two .inner-box .lower-content{
	border:1px solid #f2f2f2;
}

.project-section-three .owl-dots{
	display:none;
}

.project-section-three .owl-nav{
	position:absolute;
	right:0px;
	top:-90px;
}

.project-section-three .owl-nav .owl-prev .fa,
.project-section-three .owl-nav .owl-next .fa{
	display:none;
}

.project-section-three .owl-nav .owl-prev{
	position:relative;
	width:35px;
	height:35px;
	margin-right:14px;
	display:inline-block;
	background:url(../images/icons/arrow-left.png) no-repeat;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.project-section-three .owl-nav .owl-next{
	position:relative;
	width:35px;
	height:35px;
	display:inline-block;
	background:url(../images/icons/arrow-right.png) no-repeat;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.project-section-three .owl-nav .owl-prev:hover,
.project-section-three .owl-nav .owl-next:hover{
	-webkit-filter: grayscale(0%);
    filter: grayscale(0%);
}

/*** 

====================================================================
	Testimonial Section
====================================================================

***/

.testimonial-section-four{
	position:relative;
	padding:90px 0px;
}

.testimonial-block-five{
	position:relative;
}

.testimonial-block-five .inner-box{
	position:relative;
}

.testimonial-block-five .inner-box .text{
	position:relative;
	color:#848484;
	font-size:20px;
	font-weight:400;
	line-height:1.7em;
	text-align:center;
	margin-bottom:35px;
}

.testimonial-block-five .inner-box .author-info{
	position:relative;
	text-align:center;
}

.testimonial-block-five .inner-box .author-info .author-inner{
	position:relative;
	display:inline-block;
}

.testimonial-block-five .inner-box .author-info .image{
	position:relative;
	width:100px;
	height:100px;
	overflow:hidden;
	border-radius:50%;
	margin:0 auto;
	margin-bottom:15px;
}

.testimonial-block-five .inner-box .author-info .quote-icon{
	position:absolute;
	right:0px;
	top:0px;
	width:30px;
	height:30px;
	color:#aed0f1;
	line-height:30px;
	font-size:12px;
	text-align:center;
	border-radius:50%;
	background-color:#ffffff;
	border:1px solid #aed0f1;
}

.testimonial-block-five .inner-box .author-info h3{
	position:relative;
	color:#020e28;
	font-size:18px;
	font-weight:500;
	font-family: 'Poppins', sans-serif !important;
}

.testimonial-block-five .inner-box .author-info .location{
	position:relative;
	color:#aed0f1;
	font-size:16px;
}

.testimonial-section-four .owl-nav{
	display:none;
}

.testimonial-section-four .owl-dots{
	position:relative;
	text-align:center;
	margin-top:25px;
}

.testimonial-section-four .owl-dots .owl-dot{
	position:relative;
	width:9px;
	height:9px;
	margin:0px 5px;
	display:inline-block;
	border-radius:50%;
	background-color:#c4c4c4;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.testimonial-section-four .owl-dots .owl-dot:hover,
.testimonial-section-four .owl-dots .owl-dot.active{
	position:relative;
	width:22px;
	height:8px;
	border-radius:10px;
	background-color:#ff4800;
}

/*** 

====================================================================
	News Section Three
====================================================================

***/

.news-section-three{
	position:relative;
	padding:85px 0px 50px;
	background-color:#f4f4f4;
}

.news-section-three .sec-title .theme-btn{
	padding:12px 42px;
}

/*** 

====================================================================
	Page Title
====================================================================

***/

.page-title{
	position:relative;
	padding:25px 0px 120px;
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center top;
}

.page-title:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	background-color:rgba(2,14,40,0.92);
}

.page-title.shop-title:before{
	display:none;
}

.page-title h1{
	position:relative;
	color:#ffffff;
	font-size:48px;
	font-weight:600;
	line-height:1.2em;
	margin-top:75px;
	text-align:center;
	text-transform:capitalize;
}

.page-breadcrumb{
	position:relative;
}

.page-breadcrumb li{
	position:relative;
	display:inline-block;
	margin-right:10px;
	padding-right:20px;
	color:#aed0f1;
	font-size:16px;
	font-weight:400;
}

.page-breadcrumb li:after{
	position:absolute;
	content:'\f1a6';
	right:-4px;
	top:0px;
	color:#aed0f1;
	font-family: "Flaticon";
}

.page-breadcrumb li:last-child::after{
	display:none;
}

.page-breadcrumb li:last-child{
	padding-right:0px;
	margin-right:0px;
}

.page-breadcrumb li a{
	color:#ffffff;
	font-weight:600;
	text-transform:uppercase;
	font-family: 'Poppins', sans-serif !important;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.page-breadcrumb li a:hover{
	color:#aed0f1;
}

/*** 

====================================================================
	Company Section
====================================================================

***/

.company-section{
	position:relative;
	padding:85px 0px 50px;
}

.company-section .content-column{
	position:relative;
	margin-bottom:40px;
}

.company-section .content-column .inner-column{
	position:relative;
}

.company-section .content-column .inner-column .sec-title{
	position:relative;
	margin-bottom:25px;
}

.company-section .content-column .inner-column .bold-text{
	position:relative;
	color:#848484;
	font-size:18px;
	font-weight:500;
	line-height:1.6em;
	margin-bottom:20px;
}

.company-section .content-column .inner-column .text{
	position:relative;
	margin-bottom:25px;
}

.company-section .content-column .inner-column .text p{
	position:relative;
	color:#848484;
	font-size:16px;
	line-height:1.7em;
	margin-bottom:18px;
}

.company-section .content-column .inner-column .text p:last-child{
	margin-bottom:0px;
}

.company-section .image-column{
	position:relative;
}

.company-section .image-column .inner-column{
	position:relative;
}

.company-section .image-column .inner-column .image{
	position:relative;
}

.company-section .image-column .inner-column .image img{
	position:relative;
	width:100%;
	display:block;
}

.company-section .image-column .inner-column .carousel-box{
	position:relative;
	padding:32px 28px 35px;
	background-color:#aed0f1;
}

.company-section .image-column .inner-column .carousel-box .owl-dots,
.company-section .image-column .inner-column .carousel-box .owl-nav{
	display:none;
}

.testimonial-block-six{
	position:relative;
}

.testimonial-block-six .inner{
	position:relative;
}

.testimonial-block-six .inner .quote-icon{
	position:absolute;
	top:-10px;
	font-size:90px;
	line-height:1em;
	color:rgba(255,255,255,0.10);
}

.testimonial-block-six .inner .text{
	position:relative;
	color:#ffffff;
	font-size:20px;
	font-weight:500;
	font-style:italic;
	line-height:1.5em;
	margin-bottom:30px;
}

.testimonial-block-six .inner .author-box{
	position:relative;
	margin-bottom:0px;
}

.testimonial-block-six .inner .author-box .author-inner{
	position:relative;
	padding-left:90px;
	padding-top:10px;
	min-height:70px;
}

.testimonial-block-six .inner .author-box .author-inner .image{
	position:absolute;
	left:0px;
	top:0px;
	width:70px;
	height:70px;
	overflow:hidden;
	border-radius:50%;
}

.testimonial-block-six .inner .author-box .author-inner h3{
	position:relative;
	color:#ffffff;
	font-size:18px;
	font-weight:600;
	text-transform:capitalize;
	font-family: 'Poppins', sans-serif !important;
}

.testimonial-block-six .inner .author-box .author-inner .designation{
	position:relative;
	color:#ffffff;
	font-size:16px;
	font-weight:400;
}

/*** 

====================================================================
	Highlights Section
====================================================================

***/

.highlights-section{
	position:relative;
	padding:85px 0px 55px;
	background-color:#f4f4f4;
}

.highlights-section .image-layer{
	position:absolute;
	right:0px;
	top:0px;
	width:50%;
	height:100%;
	background-position:right top;
	background-repeat:no-repeat;
	background-size:cover;
}

.highlights-section .image-layer:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	border-top: 625px solid #f4f4f4;
	border-right: 200px solid transparent;
}

.highlights-section .content-column{
	position:relative;
}

.highlights-section .content-column .inner-column{
	position:relative;
}

.hightlight-block{
	position:relative;
	margin-bottom:30px;
}

.hightlight-block .hightlight-inner{
	position:relative;
	padding:32px 0px 28px;
	text-align:center;
	background-color:#ffffff;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.hightlight-block .hightlight-inner .icon-box{
	position:relative;
	line-height:1em;
	color:#cbcbcb;
	font-size:50px;
	margin-bottom:12px;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.hightlight-block .hightlight-inner h3{
	position:relative;
	font-size:18px;
	font-weight:600;
	color:#0f1341;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.hightlight-block .hightlight-inner .overlay-link{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
}

.hightlight-block .hightlight-inner:hover{
	background-color:#aed0f1;
}

.hightlight-block .hightlight-inner:hover h3,
.hightlight-block .hightlight-inner:hover .icon-box{
	color:#ffffff;
}

.highlights-section .services-column{
	position:relative;
}

.highlights-section .services-column .inner-column{
	position:relative;
	margin-left:100px;
	margin-right:100px;
    padding: 45px 30px 50px;
    border: 1px solid #f2f2f2;
	background:#ffffff url(../images/resource/pattern-1.png);
	background-position: left bottom;
	background-repeat:repeat-x;
}

.highlights-section .services-column .inner-column .upper-box{
	position:relative;
	padding-left:60px;
	padding-top:8px;
	padding-bottom:25px;
	margin-bottom:25px;
	border-bottom:1px solid #f2f2f2;
}

.highlights-section .services-column .inner-column .upper-box .icon{
	position:absolute;
	left:0px;
	top:0px;
	color:#aed0f1;
	font-size:40px;
	line-height:1em;
}

.highlights-section .services-column .inner-column .upper-box h2{
	position:relative;
	color:#020e28;
	font-size:18px;
	font-weight:500;
	text-transform:uppercase;
}

.highlights-section .services-column .inner-column .text{
	position:relative;
	color:#848484;
	font-size:16px;
	line-height:1.7em;
	margin-bottom:50px;
}

.highlights-section .services-column .inner-column .services{
	position:relative;
	color:#020e28;
	font-size:14px;
	font-weight:600;
	text-transform:uppercase;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	font-family: 'Poppins', sans-serif !important;
}

.highlights-section .services-column .inner-column .services .icon{
	margin-left:4px;
}

.highlights-section .services-column .inner-column .services:hover{
	color:#aed0f1;
}

/*** 

====================================================================
	History Section
====================================================================

***/

.history-section{
	position:relative;
	padding:90px 0px;
}

.history-section .sec-title{
	margin-bottom:70px;
}

.history-section .history-carousel{
	position:relative;
}

.history-section .carousel-outer{
	position:relative;
}

.history-section .carousel-outer .thumbs-outer{
	position:relative;
	overflow:hidden;
}

.history-section .carousel-outer:before{
	position:absolute;
	content:'';
	left:0px;
	top:32px;
	width:100%;
	height:1px;
	background-color:#e6e6e6;
}

.history-section .history-carousel .thumbs-carousel{
	position:static;
	max-width:180px;
	margin:0 auto;
	margin-bottom:40px;
}

.history-section .history-carousel .thumbs-carousel .owl-stage-outer{
	overflow:visible;
}

.history-section .history-carousel .thumbs-carousel .owl-item.active li{
	position:relative;
	color:#020e28;
	-webkit-transform:scale(1.8);
	-moz-transform:scale(1.8);
	-o-transform:scale(1.8);
	-ms-transform:scale(1.8);
	transform:scale(1.8);
}

.history-section .history-carousel .thumbs-carousel .owl-item.active li:before{
	display:none;
}

.history-section .history-carousel .thumbs-carousel li{
	position:relative;
	color:#e6e6e6;
	font-size:36px;
	font-weight:400;
	cursor:pointer;
	line-height:1em;
	text-align:center;
	padding:15px 20px;
	transition:all 0.3s ease;
	display:inline-block;
	background-color:#ffffff;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	font-family: 'Poppins', sans-serif;
}

.history-section .history-carousel .thumbs-carousel .owl-nav{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:0px;
}

.history-section .history-carousel .thumbs-carousel .owl-nav .owl-prev{
	position:absolute;
	left:0px;
	top:20px;
	font-size:22px;
	color:#c4c4c4;
	font-weight:700;
	width:40px;
	display:inline-block;
	background-color:#ffffff;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.history-section .history-carousel .thumbs-carousel .owl-nav .owl-next{
	position:absolute;
	top:20px;
	right:0px;
	left:auto;
	width:40px;
	text-align:right;
	font-size:22px;
	color:#c4c4c4;
	font-weight:700;
	display:inline-block;
	background-color:#ffffff;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.history-section .history-carousel .thumbs-carousel .owl-nav .owl-prev:hover,
.history-section .history-carousel .thumbs-carousel .owl-nav .owl-next:hover{
	color:#aed0f1;
}

.history-section .carousel-outer .content-slide{
	position:relative;
}

.history-section .carousel-outer .content-carousel .owl-stage-outer{
	padding-top:60px;
}

.history-section .carousel-outer .content-slide .content-inner{
	position:relative;
	text-align:center;
	padding:45px 20px 45px;
	background-color:#f4f4f4;
}

.history-section .carousel-outer .content-slide .content-inner:before{
	position:absolute;
	content:'';
	left:50%;
	top:-60px;
	margin-left:-150px;
	border-bottom: 100px solid #f4f4f4;
	border-left: 90px solid transparent;
}

.history-section .carousel-outer .content-slide .content-inner h3{
	position:relative;
	color:#020e28;
	font-size:20px;
	font-weight:500;
	line-height:1.4em;
	margin-bottom:5px;
}

.history-section .carousel-outer .content-slide .content-inner .date{
	position:relative;
	color:#aed0f1;
	font-size:16px;
	margin-bottom:20px;
}

.history-section .carousel-outer .content-slide .content-inner .text{
	position:relative;
	color:#848484;
	font-size:18px;
	line-height:1.7em;
	font-weight:400;
	max-width:980px;
	margin:0 auto;
}

.history-section .carousel-outer .content-carousel .owl-nav{
	position:absolute;
	left:0px;
	top:50%;;
	width:100%;
}

.history-section .carousel-outer .content-carousel .owl-nav .owl-prev{
	position:absolute;
	left:-20px;
	width:42px;
	height:42px;
	color:#aed0f1;
	line-height:42px;
	text-align:center;
	border-radius:50%;
	font-weight:700;
	background-color:#ffffff;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	-webkit-box-shadow: 0px 2px 12px 0px rgba(68,68,68,0.5);
	-moz-box-shadow: 0px 2px 12px 0px rgba(68,68,68,0.5);
	box-shadow: 0px 2px 12px 0px rgba(68,68,68,0.5);
}

.history-section .carousel-outer .content-carousel .owl-nav .owl-next{
	position:absolute;
	right:-20px;
	width:42px;
	height:42px;
	color:#aed0f1;
	line-height:42px;
	text-align:center;
	border-radius:50%;
	font-weight:700;
	background-color:#ffffff;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	-webkit-box-shadow: 0px 2px 12px 0px rgba(68,68,68,0.5);
	-moz-box-shadow: 0px 2px 12px 0px rgba(68,68,68,0.5);
	box-shadow: 0px 2px 12px 0px rgba(68,68,68,0.5);
}

.history-section .carousel-outer .content-carousel .owl-nav .owl-next:hover,
.history-section .carousel-outer .content-carousel .owl-nav .owl-prev:hover{
	color:#ffffff;
	background-color:#aed0f1;
}

/*** 

====================================================================
	Achived Section
====================================================================

***/

.achived-section{
	position:relative;
	padding:85px 0px 45px;
	background-attachment:fixed;
	background-repeat:no-repeat;
	background-position:center bottom;
}

.achived-section:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	background-color:rgba(2,14,40,0.90);
}

.achived-section .title-column{
	position:relative;
	margin-bottom:40px;
}

.achived-section .title-column .sec-title{
	margin-bottom:40px;
}

.achived-section .title-column .inner-column{
	position:relative;
}

.achived-section .title-column .inner-column .title{
	position:relative;
	color:#aed0f1;
	font-size:18px;
	margin-bottom:40px;
}

.achived-section .title-column .achived-list{
	position:relative;
}

.achived-section .title-column .achived-list li{
	position:relative;
	display:inline-block;
	margin-right:20px;
}

.achived-section .title-column .achived-list li.icon{
	font-size:60px;
	color:#aed0f1;
	line-height:1em;
}

.achived-section .title-column .achived-list li.number{
	color:#ffffff;
	font-size:60px;
	font-weight:600;
	line-height:1em;
	font-family: 'Poppins', sans-serif !important;
}

.achived-section .title-column .achived-list li a{
	position:relative;
	top:-15px;
	color:#ffffff;
	font-size:18px;
	font-weight:400;
	padding:7px 12px;
	display:inline-block;
	background-color:#aed0f1;
	border:2px solid #aed0f1;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.achived-section .title-column .achived-list li a:hover{
	background:none;
	color:#aed0f1;
}

.achived-section .title-column .inner-column .text{
	position:relative;
	color:#c4c4c4;
	font-size:16px;
	line-height:1.8em;
	margin-top:22px;
}

.achived-section .graph-column{
	position:relative;
}

.achived-section .graph-column .inner-column{
	position:relative;
	padding-left:15px;
}

/*Progress Level*/

.progress-levels{
	position:relative;
	margin-bottom:30px;
}

.progress-levels .progress-box{
	position:relative;
	overflow:hidden;
	margin-bottom:30px;
	width:20%;
	float:left;
}

.progress-levels .progress-box .inner{
	position:relative;
	text-align:center;
}

.progress-levels .progress-box .inner .count-text{
	position:relative;
	font-size:14px;
	font-weight:600;
	color:#aed0f1;
	display:inline-block;
	margin-bottom:10px;
	font-family: 'Poppins', sans-serif !important;
}

.progress-levels .progress-box .inner .percent{
	position:relative;
	font-size:14px;
	font-weight:600;
	color:#aed0f1;
	display:inline-block;
	margin-bottom:10px;
	font-family: 'Poppins', sans-serif !important;
}

.progress-levels .progress-box .bar{
	 position:relative;
	 height:350px;	
}

.progress-levels .progress-box .bar .bar-innner{
	position:relative;
	width:60px;
	height:350px;
	margin:0 auto;
	border:1px solid rgba(255,255,255,0.15);
}

.progress-levels .progress-box .bar .bar-innner .skill-percent{
    top: -33px;
    position: absolute;
    width: 100%;
    text-align: center;
}

.progress-levels .progress-box .bar .bar-fill{
	position:absolute;
	left:0%;
	bottom:0px;
	width:60px;
	height:0px;
	background-size:cover;
	background:url(../images/resource/graph-layer.jpg) repeat;
	background-position:center bottom;
	-moz-transition:all 2000ms ease 300ms;
	-webkit-transition:all 2000ms ease 300ms;
	-ms-transition:all 2000ms ease 300ms;
	-o-transition:all 2000ms ease 300ms;
	transition:all 2000ms ease 300ms;
}

.progress-levels .progress-box .inner .text{
	position: absolute;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.3em;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif !important;
    left: 10px;
    bottom: 0px;
    width: 10px;
    word-wrap: break-word;
    text-align: center;
}

/*** 

====================================================================
	Team Section
====================================================================

***/

.team-section{
	position:relative;
	padding:80px 0px 50px;
}

.team-block{
	position:relative;
	margin-bottom:40px;
}

.team-block .inner-box{
	position:relative;
}

.team-block .inner-box .image{
	position:relative;
}

.team-block .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
}

.team-block .inner-box .image .overlay-box{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	opacity:0;
	text-align:center;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	background-color:rgba(2,14,40,0.75);
}

.team-block .inner-box .image .overlay-box .social-icon-two{
	top:50%;
	margin-top:-20px;
}

.team-block .inner-box:hover .image .overlay-box{
	opacity:1;
}

.team-block .inner-box .lower-content{
	position:relative;
	padding:25px 25px 22px;
	border:1px solid #f2f2f2;
}

.team-block .inner-box .lower-content h3{
	position:relative;
	color:#020e28;
	font-size:18px;
	font-weight:500;
	margin-bottom:18px;
}

.team-block .inner-box .lower-content h3 a{
	position:relative;
	color:#020e28;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.team-block .inner-box:hover .lower-content h3 a{
	color:#aed0f1;
}

.team-block .inner-box .lower-content .designation{
	position:relative;
	color:#ffffff;
	font-size:16px;
	padding:2px 10px;
	display:inline-block;
	margin-bottom:18px;
	background-color:#aed0f1;
}

.team-block .inner-box .lower-content .text{
	position:relative;
	color:#808080;
	font-size:16px;
	line-height:1.7em;
}

/*** 

====================================================================
	Location Section
====================================================================

***/

.location-section{
	position:relative;
	padding:22px 0px 90px;
	background-color:#020e28;
	background-position:center center;
	background-repeat:no-repeat;
}

.location-section .page-breadcrumb{
	margin-bottom:80px;
}

/*** 

====================================================================
	Contact Section
====================================================================

***/

.contact-section{
	position:relative;
	padding:75px 0px 90px;
}

.contact-section .sec-title .text{
	position:relative;
	color:#848484;
	font-size:18px;
	line-height:1.7em;
	margin-left:50px;
	display:inline-block;
}

.contact-section .inner-container{
	position:relative;
	border:1px solid #f4f4f4;
}

.contact-section .info-column{
	position:relative;
	padding:0px;
}

.contact-section .info-column .inner-column{
	position:relative;
	border-right:1px solid #f4f4f4;
}

.contact-section .info-column .inner-column .upper-box{
	position:relative;
	padding:32px 55px 25px 28px;
	background-color:#f4f4f4;
}

.contact-section .info-column .lower-box{
	position:relative;
	padding:28px 30px;
}

.contact-section .info-column .lower-box .lower-inner{
	position:relative;
	padding-left:100px;
	min-height:80px;
}

.contact-section .info-column .lower-box .lower-inner .image{
	position:absolute;
	left:0px;
	top:0px;
	width:80px;
	height:80px;
}

.contact-section .info-column .lower-box .lower-inner h3{
	position:relative;
	color:#020e28;
	font-size:18px;
	font-weight:500;
	font-family: 'Poppins', sans-serif !important;
}

.contact-section .info-column .lower-box .lower-inner .designation{
	position:relative;
	color:#aed0f1;
	font-size:16px;
	font-weight:400;
	line-height:1.4em;
}

.contact-section .info-column .lower-box .lower-inner .emailed{
	position:relative;
	color:#848484;
	font-size:16px;
	font-weight:400;
	padding-left:26px;
	margin-top:4px;
}

.contact-section .info-column .lower-box .lower-inner .emailed:before{
	position:absolute;
	content: "\f1ca";
	left:0px;
	top:4px;
	color:#aed0f1;
	font-size:16px;
	line-height:1em;
	font-family: "Flaticon";
}

.contact-section .form-column{
	position:relative;
}

.contact-section .form-column .inner-column{
	position:relative;
	padding:20px 25px;
}

/*** 

====================================================================
	Contact Form
====================================================================

 ***/

.contact-form{
	position:relative;
}

.contact-form .form-group{
	position:relative;
	margin-bottom:25px;
}

.contact-form .form-group input[type="text"],
.contact-form .form-group input[type="password"],
.contact-form .form-group input[type="tel"],
.contact-form .form-group input[type="email"],
.contact-form .form-group select{
	position:relative;
	display:block;
	width:100%;
	line-height:28px;
	padding:10px 0px;
	height:50px;
	color:#848484;
	font-size:16px;
	border-bottom:1px solid #f2f2f2;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
	font-family: 'Poppins', sans-serif;
}

.contact-form .form-group input[type="text"]:focus,
.contact-form .form-group input[type="password"]:focus,
.contact-form .form-group input[type="tel"]:focus,
.contact-form .form-group input[type="email"]:focus,
.contact-form .form-group select:focus,
.contact-form .form-group textarea:focus{
	border-color:#aed0f1;
}

.contact-form .form-group textarea{
	position:relative;
	display:block;
	width:100%;
	line-height:24px;
	padding:10px 0px;
	color:#848484;
	height:120px;
	font-size:16px;
	resize:none;
	font-family: 'Poppins', sans-serif;
	border-bottom:1px solid #f2f2f2;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.contact-form .submit-btn{
	margin-top:15px;
	font-size:14px;
	font-weight:600;
	color:#020e28;
	background:none;
	letter-spacing:1px;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
	text-transform:uppercase;
}

.contact-form .submit-btn .icon{
	margin-left:5px;
}

.contact-form .submit-btn:hover{
	color:#aed0f1;
}

.contact-form input.error,
.contact-form select.error,
.contact-form textarea.error{
	border-color:#ff0000 !important;	
}

.contact-form label.error{
	display:block;
	line-height:24px;
	padding:5px 0px 0px;
	margin:0px;
	text-transform:uppercase;
	font-size:12px;
	color:#ff0000;
	font-weight:500;	
}

/*** 

====================================================================
	Error Section
====================================================================

***/

.error-section{
	position:relative;
	width:100%;
	text-align:center;
	padding:90px 0px 90px;
	background-repeat:no-repeat;
	background-size:cover;
}

.error-section:before{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	background-color:rgba(4,19,50,0.90);
}

.error-section .inner-section{
	position:relative;
	text-align:center;
	display:inline-block;
	border-radius:50%;
	padding:100px 30px 100px;
	background-color:#aed0f1;
}

.error-section .inner-section h1{
	position:relative;
	color:#020e28;
	font-size:200px;
	line-height:1em;
	font-weight:700;
	font-family: 'Hind', sans-serif;
}

.error-section .inner-section h2{
	position:relative;
	font-size:18px;
	color:#020e28;
	font-weight:500;
	margin-top:-10px;
	text-transform:uppercase;
	font-family: 'Poppins', sans-serif !important;
}

.error-section .inner-section .text{
	position:relative;
	color:#ffffff;
	font-size:18px;
	margin-top:12px;
	margin-bottom:40px;
}

.error-section .go-back{
	position:relative;
	color:#ffffff;
	font-size:16px;
	font-weight:500;
	text-align:center;
	margin-top:18px;
	display:inline-block;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	font-family: 'Poppins', sans-serif;
}

.error-section .go-back:hover{
	color:#020e28;
}

/*Error Form*/

.error-search-form .form-group{
	position:relative;
	max-width:430px;
	margin:0 auto;
	width:100%;
}

.error-search-form .form-group input[type="text"],
.error-search-form .form-group input[type="search"]{
	position:relative;
	line-height:30px;
	background:none;
	display:block;
	font-size:16px;
	width:100%;
	height:52px;
	border:1px solid transparent;
	background-color:#ffffff;
	padding:10px 50px 10px 20px;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.error-search-form .form-group input:focus{
	border-color:#ff6600;	
}

.error-search-form .form-group button{
	position:absolute;
	right:0px;
	top:0px;
	height:52px;
	width:50px;
	display:block;
	font-size:16px;
	color:#ffffff;
	line-height:100%;
	background:#020e28;
	font-weight:normal;
}

/*** 

====================================================================
	Faq Section
====================================================================

***/

.faq-section{
	position:relative;
	padding:90px 0px 90px;
}

.faq-section .prod-tabs{
	position:relative;	
}

.faq-section .prod-tabs .tab-btns{
	position:relative;
	margin-bottom:40px;
	padding:0px 20px;
	border:1px solid #f2f2f2;
	border-top-color:#aed0f1;
}

.faq-section .prod-tabs .tab-btns .tab-btn{
	position:relative;
	display:block;
	font-size:16px;
	color:#222222;
	font-weight:400;
	cursor:pointer;
	line-height:1.6em;
	padding:10px 0px 10px 0px;
	transition:all 500ms ease;
	text-transform:capitalize;
	border-bottom:1px solid #f2f2f2;
}

.faq-section .prod-tabs .tab-btns .tab-btn:last-child{
	border:none;
}

.faq-section .prod-tabs .tab-btns .tab-btn span{
	position:relative;
	display:block;
	font-size:16px;
	color:#020e28;
	font-weight:400;
	cursor:pointer;
	line-height:1.6em;
	padding:5px 15px 5px 30px;
	transition:all 500ms ease;
	text-transform:capitalize;
	font-family: 'Poppins', sans-serif !important;
}

.faq-section .prod-tabs .tab-btns .tab-btn:before{
	position:absolute;
	content:'';
	left:11px;
	top:23px;
	width:8px;
	height:8px;
	background-color:#e6e6e6;
	border:1px solid #f2f2f2;
	transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
}

.faq-section .prod-tabs .tab-btns .tab-btn:hover:before,
.faq-section .prod-tabs .tab-btns .tab-btn.active-btn:before{
	z-index:1;
	background-color:#aed0f1;
	border-color:rgba(245,91,20,0.20);
}

.faq-section .prod-tabs .tab-btns .tab-btn.active-btn span,
.faq-section .prod-tabs .tab-btns .tab-btn:hover span{
	color:#aed0f1;
}

.faq-section .prod-tabs .tabs-content{
	position:relative;
}

.faq-section .prod-tabs .tabs-content .tab{
	position:relative;
	display:none;
}

.faq-section .prod-tabs .tabs-content .tab.active-tab{
	display:block;	
}

/*** 

====================================================================
	Accordion Style
====================================================================

***/

.accordion-box{
	position:relative;
	padding:10px 30px 10px;
	background-color:#f4f4f4;
}

.accordion-box .block{
	position: relative;
}

.accordion-box .block:last-child .acc-btn{
	border-bottom:0px;
	padding-bottom:10px;
}

.accordion-box .block .acc-btn{
	position:relative;
	font-size:18px;
	cursor:pointer;
	line-height:30px;
	color:#020e28;
	font-weight:500;
	padding:17px 30px 17px 0px;
	border-bottom:1px solid #e1e1e1;
	transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	font-family: 'Poppins', sans-serif !important;
}

.accordion-box .block .icon-outer{
	position:absolute;
	right:28px;
	top:20px;
	color: #929292;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.accordion-box .block .icon-outer .icon{
    position: absolute;
    width: 22px;
    height: 22px;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
	border-radius:50%;
	line-height:22px;
	border:1px solid #898989;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.accordion-box .block .acc-btn.active .icon-outer .icon-plus{
	color:#aed0f1;
	border-color:#aed0f1;
	-ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.accordion-box .block .acc-content{
	position:relative;
	display:none;
}

.accordion-box .block .acc-content:before{
	position:absolute;
	content:'';
	left:-30px;
	top:0px;
	width:1px;
	height:100%;
	display:block;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	background-color:#aed0f1;
}

.accordion-box .block .acc-content .content-text{
	padding-bottom:6px;
}

.accordion-box .block .acc-content.current{
	display:block;	
}

.accordion-box .block .content{
	position:relative;
	font-size:14px;
	padding:20px 0px 15px 0px;
	border-bottom:1px solid #e9e9e9;
}

.accordion-box .block .content .text{
	margin-bottom:0px;
	line-height:1.9em;
	font-size:16px;	
	color:#848484;
}

.accordion-box .block .content p:last-child{
	margin-bottom:0px;	
}

.accordion-box.style-two{
	padding:0px;
	background-color:#ffffff;
}

.accordion-box.style-two .block{
    margin-bottom: 10px;
	padding:17px 20px;
    border: 1px solid #f2f2f2;
    background-color: #ffffff;
}

.accordion-box.style-two .block .acc-btn{
	border:none;
	padding:0px 0px 0px 62px;
}

.accordion-box.style-two .block .icon-outer{
	left:0px;
	top:2px;
	right:auto;
}

.accordion-box.style-two .block .acc-content:before{
	display:none;
}

.accordion-box.style-two .block .content{
	padding:15px 30px 0px 62px;
	border:none;
}

.accordion-box.style-two .block:before{
	position: absolute;
    content: '';
    left: 59px;
    top: 18px;
    bottom: 18px;
    width: 1px;
    background-color: #f2f2f2;
}

.accordion-box.style-two .block .icon-outer .icon{
	border:none;
	font-size:20px;
	font-weight:600;
}

.accordion-box.style-two .block.active-block::before{
	background-color:#f66e2f;
}

.accordion-box.style-two .block .acc-btn.active .icon-outer .icon-plus{
	-ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

/*** 

====================================================================
	Maintenance Section
====================================================================

***/

.maintenance-section{
	position:relative;
	padding:130px 0px 120px;
	background-color:#020e28;
	background-position:right bottom;
	background-repeat:no-repeat;
}

.maintenance-box{
	position:relative;
}

.maintenance-box .inner-box{
	position:relative;
	max-width:570px;
	width:100%;
	padding:40px 30px;
	display:inline-block;
	border:2px solid rgba(255,255,255,0.20);
}

.maintenance-box .inner-box h2{
	position:relative;
	color:#ffffff;
	font-size:60px;
	font-weight:600;
	line-height:1.1em;
	padding-bottom:25px;
	margin-bottom:22px;
	display:inline-block;
	text-transform:uppercase;
	border-bottom:2px solid #aed0f1;
}

.maintenance-box .inner-box h3{
	position:relative;
	color:#ffffff;
	font-size:30px;
	font-weight:500;
	line-height:1.4em;
	text-transform:uppercase;
}

.maintenance-box .inner-box .text{
	position:relative;
	color:#aed0f1;
	font-size:16px;
}

/*** 

====================================================================
	Testimonial Page Section
====================================================================

***/

.testimonial-page-section{
	position:relative;
	padding:90px 0px 50px;
}

.testimonial-page-section .testimonials-outer{
	position:relative;
	margin-bottom:40px;
	border-bottom:1px solid #f2f2f2;
}

.testimonial-page-section .testimonials-outer:last-child{
	border:0px;
	padding-bottom:0px;
}

.testimonial-block-seven{
	position:relative;
	margin-bottom:40px;
	border-right:1px solid #f2f2f2;
}

.testimonial-block-seven:nth-child(2n + 0){
	border:0px;
}

.testimonial-block-seven .inner-box{
	position:relative;
	text-align:center;
	padding:0px 60px;	
}

.testimonial-block-seven .inner-box .author-image{
	position:relative;
	width:80px;
	height:80px;
	margin:0 auto;
	border-radius:50%;
	overflow:hidden;
	margin-bottom:15px;
}

.testimonial-block-seven .inner-box h3{
	position:relative;
	color:#020e28;
	font-weight:500;
	font-size:20px;
	line-height:1.2em;
	margin-bottom:8px;
}

.testimonial-block-seven .inner-box .designation{
	position:relative;
	color:#aed0f1;
	font-size:16px;
	font-weight:400;
	margin-bottom:18px;
}

.testimonial-block-seven .inner-box .text{
	position:relative;
	font-size:18px;
	line-height:1.7em;
	margin-bottom:20px;
	color:#848484;
}

.testimonial-block-seven .inner-box .client-icon{
	position:relative;
	text-align:center;
}

.testimonial-block-seven .inner-box .client-icon img{
	position:relative;
	width:auto;
	margin:0 auto;
}

.quote-page-section{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	background-color:rgba(2,14,40,0.94);
}

/*** 

====================================================================
	Appointment Modal Style
====================================================================

***/

.modal-open {
	overflow: hidden !important;
}

.popup-container{
	max-width:1170px;
	width:100%;
	padding:0px;
	margin-top:100px;
	margin-bottom:80px;
}

#schedule-box .modal-content {
	max-width:1170px;
	box-shadow: none;
	border-radius: 0;
	padding: 1px;
	border:0px;
	margin:auto 0;
	background:none;
}
#schedule-box .modal-content form h3{
	color:#7bca33;
	text-transform: uppercase;
	font-size:20px;
	position: relative;
	font-weight:500;
}

#schedule-box .modal-content form label {
	line-height: 30px;
}
#schedule-box .modal-content .appointment-form h3 {
	font-size:20px;
    margin-bottom: 20px;
	color:#7bca33;
	font-weight:600;
}

#schedule-box .close {
	display: block;
	float:none;
	opacity: 1;
	text-shadow:none;
	width:40px;
	line-height: 40px;
	color:#999999;
	font-size: 16px;
	text-align: center;
	position: absolute;
	top:10px;
	right:10px;
	z-index:10;
}

.modal-backdrop{
	opacity:1 !important;
	background-color:rgba(2,14,40,0.94);
}

.get-quote-form{
	position:relative;
	background-size:cover;
}

.get-quote-form .inner-box{
	position:relative;
	max-width:870px;
	padding:80px 0px 70px 100px;
}

.get-quote-form .inner-box .sec-title{
	margin-bottom:40px;
}

.get-quote-form .inner-box .sec-title .text{
	position:relative;
	color:#848484;
	font-size:18px;
	line-height:1.7em;
	max-width:495px;
	margin-left:40px;
}

#schedule-box .backhome{
	position:relative;
	left:0px;
	bottom:0px;
	width:100%;
	color:#ffffff;
	display:block;
	text-align:center;
	padding:15px 0px;
	font-weight:600;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	text-transform:uppercase;
	background-color:rgba(255,255,255,0.10);
	font-family: 'Poppins', sans-serif !important;
}

#schedule-box .backhome .icon{
	margin-left:5px;
}

#schedule-box .backhome:hover{
	color:#aed0f1;
}

.market-page-section{
	position:relative;
	padding:90px 0px 60px;
}

/*** 

====================================================================
	Services Section Three
====================================================================

***/

.services-section-three{
	position:relative;
	padding:80px 0px 40px;
	background-color:#f4f4f4;
}

.services-block-three{
	position:relative;
	margin-bottom:40px;
}

.services-block-three .inner-box{
	position:relative;
}

.services-block-three .inner-box h3{
	position:relative;
	color:#020e28;
	font-size:18px;
	font-weight:700;
	padding-bottom:15px;
	margin-bottom:18px;
	text-transform:uppercase;
	border-bottom:1px solid #e6e6e6;
}

.services-block-three .inner-box h3 span{
	font-weight:500;
	display:block;
}

.services-block-three .inner-box h3 a{
	position:relative;
	color:#020e28;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.services-block-three .inner-box .text{
	position:relative;
	color:#848484;
	font-size:16px;
	font-weight:400;
	margin-bottom:15px;
}

.services-block-three .inner-box .read-more{
	position:relative;
	color:#020e28;
	font-size:14px;
	font-weight:600;
	text-transform:uppercase;
	font-family: 'Poppins', sans-serif !important;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.services-block-three .inner-box .read-more .icon{
	margin-left:5px;
}

.services-block-three .inner-box:hover h3 a,
.services-block-three .inner-box:hover .read-more{
	color:#aed0f1;
}

/*** 

====================================================================
	Sidebar Page Container
====================================================================

***/

.sidebar-page-container{
	position:relative;
	overflow:hidden;
	padding:90px 0px 40px;
}

.sidebar-page-container .content-side,
.sidebar-page-container .sidebar-side{
	margin-bottom:40px;
}

.sidebar-page-container .sidebar-side .with-border{
	padding-left:30px;
}

.sidebar-page-container .sidebar-side .with-border:before{
	position:absolute;
	content:'';
	left:15px;
	top:-200px;
	width:1px;
	height:5000px;
	background-color:#f0f0f0;
}

.sidebar-widget{
    position: relative;
    margin-bottom: 45px;
}

.market-sidebar{
	position:relative;
	padding:25px 20px 30px;
	margin-left:30px;
	background-color:#f4f4f4;
}

/*Sidebar Title*/

.sidebar-title{
    position: relative;
    margin-bottom:18px;
}

.sidebar-title h2{
    font-size: 20px;
    color: #020e28;
    font-weight: 600;
    line-height: 1.2em;
    text-transform: capitalize;
	font-family: 'Poppins', sans-serif !important;
}

.sidebar-title.style-two{
	margin-bottom:25px;
}

.sidebar-title.style-two h2{
	font-weight:500;
}

/*Blog Category*/

.blog-cat{
	position:relative;
	padding:0px 18px 0px;
	border:1px solid #f2f2f2;
	background-color:#ffffff;
	-webkit-box-shadow: -2px 2px 12px 0px rgba(233,233,233,1);
	-moz-box-shadow: -2px 2px 12px 0px rgba(233,233,233,1);
	box-shadow: -2px 2px 12px 0px rgba(233,233,233,1);
}

.blog-cat li{
	position:relative;
	padding:8px 0px;
	border-bottom:1px solid #eeeeee;
}

.blog-cat li a{
	position:relative;
	font-size:16px;
	font-weight:400;
	display:block;
	color:#848484;
	padding:4px 0px;
	padding-left:25px;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	font-family: 'Poppins', sans-serif;
}

.blog-cat li:last-child{
	margin-bottom:0px;
	border:none;
}

.blog-cat li a:before{
	position:absolute;
	content:'\f178';
	left:0px;
	top:5px;
	font-family: 'FontAwesome';
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.blog-cat li.active a,
.blog-cat li a:hover{
	color:#ffffff;
	padding-left:35px;
	background-color:#aed0f1;
}

.blog-cat li.active a:before,
.blog-cat li a:hover::before{
	left:10px;
	border-color:rgba(255,255,255,0.50);
}

/*Blog Category Two*/

.blog-cat-two{
	position:relative;
	padding:0px 20px 20px;
	border:1px solid #f2f2f2;
	background-color:#ffffff;
}

.blog-cat-two li{
	position:relative;
	padding:8px 0px;
}

.blog-cat-two li:after{
	position:absolute;
	content:'';
	left:0px;
	bottom:0px;
	width:90px;
	height:1px;
	background-color:#f2f2f2;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.blog-cat-two li:hover a::before{
	-ms-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.blog-cat-two li:hover::after{
	width:100%;
	background-color:#aed0f1;
}

.blog-cat-two li a{
	position:relative;
	font-size:16px;
	font-weight:400;
	display:block;
	color:#848484;
	padding:4px 0px;
	padding-left:25px;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	font-family: 'Poppins', sans-serif;
}

.blog-cat-two li a span{
	position:absolute;
	right:0px;
}

.blog-cat-two li:last-child{
	border:none;
}

.blog-cat-two li a:before{
	position:absolute;
	content:'\f175';
	left:0px;
	top:5px;
	color:#c5c5c5;
	font-family: 'FontAwesome';
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.blog-cat-two li.active a,
.blog-cat-two li a:hover{
	color:#aed0f1;
}

.blog-cat-two li a:hover::before{
	color:#aed0f1;
}

/*Broucher Widget*/

.sidebar-brochure .brochure{
	position:relative;
	color:#0f1341;
	font-weight:400;
	font-size:16px;
	padding:5px 20px;
	display:block;
	padding-left:70px;
	background-color:#ffffff;
	border:1px solid #f2f2f2;
	border-bottom:none;
	transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
}

.sidebar-brochure .brochure span{
	display:block;
	font-weight:400;
	color:#808080;
	transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
}

.sidebar-brochure .brochure .icon{
	position:absolute;
	width:54px;
	left:0px;
	top:0px;
	height:65px;
	font-size:18px;
	margin-right:8px;
	text-align:center;
	font-weight:400;
	line-height:65px;
	color:#0f1341;
	border-right:1px solid #f2f2f2;
	transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
}

.sidebar-brochure .brochure:hover{
	background-color:#ff4800;
	color:#ffffff;
}

.sidebar-brochure .brochure:hover span,
.sidebar-brochure .brochure:hover .icon{
	color:#ffffff;
}

/*Testimonial Widget*/

.testimonial-widget{
	position:relative;
}

.testimonial-widget .inner-widget{
	position:relative;
	margin-right:2px;
	padding:25px 18px 55px;
	background-color:#ffffff;
}

.testimonial-widget .inner-widget .upper-box{
	position:relative;
}

.testimonial-widget .inner-widget .upper-box .upper-inner{
	position:relative;
	padding-left:70px;
	min-height:78px;
	margin-bottom:20px;
	border-bottom:1px solid #f2f2f2;
}

.testimonial-widget .inner-widget .upper-box .upper-inner .image{
	position:absolute;
	left:0px;
	top:0px;
	width:56px;
	height:56px;
	overflow:hidden;
	border-radius:50%;
}

.testimonial-widget .inner-widget .upper-box .upper-inner h3{
	position:relative;
	color:#020e28;
	font-size:18px;
	font-weight:500;
	line-height:1.4em;
	text-transform:capitalize;
}

.testimonial-widget .inner-widget .upper-box .upper-inner .location{
	position:relative;
	color:#aed0f1;
	font-size:16px;
}

.testimonial-widget .inner-widget .text{
	position:relative;
	color:#848484;
	font-size:18px;
	line-height:1.7em;
	font-style:italic;
}

.testimonial-widget .owl-nav{
	display:none;
}

.testimonial-widget .owl-dots{
	position:absolute;
	left:20px;
	bottom:20px;
}

.testimonial-widget .owl-dots .owl-dot{
	position:relative;
	width:25px;
	height:5px;
	margin-right:8px;
	display:inline-block;
	background-color:#e6e6e6;
	transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
}

.testimonial-widget .owl-dots .owl-dot:hover,
.testimonial-widget .owl-dots .owl-dot.active{
	background-color:#aed0f1;
}

.request-quote{
	position:relative;
	margin-top:-10px;
}

.request-quote a{
	position:relative;
	font-size:14px;
	color:#ffffff;
	text-align:center;
	padding:12px 0px;
	display:block;
	font-weight:600;
	border:2px solid #aed0f1;
	background-color:#aed0f1;
	text-transform:uppercase;
	font-family: 'Poppins', sans-serif !important;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.request-quote a:hover{
	color:#aed0f1;
	background:none;
}

/*** 

====================================================================
	Market Single
====================================================================

***/

.market-single{
	position:relative;
}

.market-single .inner-box{
	position:relative;
}

.market-single .inner-box .image{
	position:relative;
	margin-bottom:20px;
}

.market-single .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
}

.market-single .inner-box .lower-content{
	position:relative;
}

.market-single .inner-box .lower-content h2{
	position:relative;
	color:#020e28;
	font-weight:600;
	padding-bottom:12px;
	margin-bottom:32px;
	text-transform:capitalize;
}

.market-single .inner-box .lower-content h2:after{
	position:absolute;
	content:'';
	left:0px;
	bottom:0px;
	width:50px;
	height:4px;
	background-color:#aed0f1;
}

.market-single .inner-box .lower-content .text p{
	position:relative;
	color:#848484;
	font-size:16px;
	line-height:1.8em;
	margin-bottom:30px;
}

.market-single .inner-box .lower-content .market-blocks{
	position:relative;
	margin-bottom:55px;
}

.market-single .inner-box .lower-content .market-blocks .blocks-inner{
	position:relative;
	-webkit-box-shadow: 0px 0px 19px 0px rgba(241,241,241,1);
	-moz-box-shadow: 0px 0px 19px 0px rgba(241,241,241,1);
	box-shadow: 0px 0px 19px 0px rgba(241,241,241,1);
}

.market-single .inner-box .lower-content .market-blocks .blocks-inner:after{
	position:absolute;
	content:'';
	left:50%;
	top:0px;
	width:1px;
	height:100%;
	background-color:#f2f2f2;
}

.market-block-five{
	position:relative;
	padding:0px;
}

.market-block-five:nth-child(3),
.market-block-five:nth-child(4){
	margin-bottom:0px;
}

.market-block-five:nth-child(3) .inner-box,
.market-block-five:nth-child(4) .inner-box{
	border-bottom:0px;
}

.market-block-five .block-inner{
	position:relative;
	padding:55px 30px 40px;
	border-bottom:1px solid #f2f2f2;
}

.market-block-five .block-inner .icon-box{
	position:absolute;
	left:30px;
	top:35px;
	color:#f0f0f0;
	font-size:70px;
	line-height:1em;
}

.market-block-five .block-inner h3{
	position:relative;
	color:#020e28;
	font-size:20px;
	font-weight:500;
	margin-bottom:15px;
	z-index:1;
}

.market-block-five .block-inner .block-text{
	position:relative;
	color:#848484;
	font-size:16px;
	font-weight:400;
	margin-bottom:20px;
}

.market-block-five .block-inner .plus-icon{
	position:relative;
	width:30px;
	height:30px;
	color:#c4c4c4;
	text-align:center;
	line-height:26px;
	font-weight:700;
	font-size:14px;
	border-radius:50%;
	border:2px solid #c4c4c4;
}

.market-block-five .block-inner .block-overlay{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	z-index:2;
	opacity:0;
	background-color:#aed0f1;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.market-block-five .block-inner:hover .block-overlay{
	opacity:1;
}

.market-block-five .block-inner .block-overlay .overlay-inner{
	position:relative;
	padding:30px 28px;
}

.market-block-five .block-inner .block-overlay .overlay-inner h4{
	position:relative;
	color:#ffffff;
	font-size:20px;
	font-weight:500;
	margin-bottom:20px;
	font-family: 'Poppins', sans-serif !important;
}

.stats-box{
	position:relative;
	margin-bottom:40px;
}

.stats-box .stats-inner{
	position:relative;
	padding-bottom:30px;
	border-bottom:1px solid #f2f2f2;
}

.stats-box .stats-inner h3{
	position:relative;
	color:#020e28;
	font-size:24px;
	font-weight:600;
	margin-bottom:12px;
}

.stats-box .stats-inner .text{
	position:relative;
	color:#848484;
	font-size:16px;
	margin-bottom:18px;
}

.market-single .inner-box .lower-content .accordian-boxed{
	position:relative;
}

.market-single .inner-box .lower-content .accordian-boxed h3{
	position:relative;
	color:#020e28;
	font-size:24px;
	font-weight:600;
	margin-bottom:25px;
}

.market-single .inner-box .lower-content .market-steps{
	position:relative;
	margin-top:60px;
}

.market-single .inner-box .lower-content .market-steps h3{
	position:relative;
	color:#020e28;
	font-size:24px;
	font-weight:600;
	margin-bottom:20px;
}

.market-single .inner-box .lower-content .market-steps .steps-inner{
	position:relative;
	padding:25px 0px;
	border-top:1px solid #f2f2f2;
	border-bottom:1px solid #f2f2f2;
}

.market-single .inner-box .lower-content .market-steps .steps-inner:before{
	position:absolute;
	content:'';
	left:50%;
	top:32px;
	bottom:32px;
	width:1px;
	right:auto;
	background-color:#f2f2f2;
}

.step-block{
	position:relative;
	padding-right:30px;
}

.step-block:last-child{
	padding-right:0px;
}

.step-block:last-child .inner{
	margin-left:30px;
}

.step-block .inner{
	position:relative;
	padding-left:60px;
}

.step-block .inner .icon-box{
	position:absolute;
	left:0px;
	top:20px;
	color:#c4c4c4;
	font-size:38px;
	line-height:1em;
}

.step-block .inner .step-text{
	position:relative;
	color:#848484;
	font-size:16px;
	line-height:1.8em;
}

.step-block .inner .step-text a{
	font-weight:500;
}

/*** 

====================================================================
	Blog Page Section
====================================================================

***/

.blog-page-section{
	position:relative;
	padding:90px 0px;
}

.blog-page-section .styled-pagination{
	margin-top:10px;
}

.styled-pagination{
	position:relative;
}

.styled-pagination li{
	position:relative;
	display:inline-block;
	margin:0px 6px 0px 0px;
}

.styled-pagination li.prev a,
.styled-pagination li.next a{
	color:#848484;
}

.styled-pagination li a{
	position:relative;
	display:inline-block;
	line-height:50px;
	height:50px;
	font-size:18px;
	min-width:50px;
	color:#020e28;
	font-weight:600;
	text-align:center;
	background:#ffffff;
	border:1px solid #f2f2f2;
	text-transform:capitalize;
	transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	font-family: 'Poppins', sans-serif !important;
}

.styled-pagination li a:hover,
.styled-pagination li a.active{
	color:#ffffff;
	border-color:#aed0f1;
	background-color:#aed0f1;
}

/*Search Box Widget*/

.sidebar .search-box .form-group{
	position:relative;
	margin:0px;	
}

.sidebar .search-box .form-group input[type="text"],
.sidebar .search-box .form-group input[type="search"]{
	position:relative;
	line-height:28px;
	padding:10px 50px 10px 20px;
	border:1px solid #f2f2f2;
	background:none;
	display:block;
	font-size:14px;
	width:100%;
	height:50px;
	color:#808080;
	font-weight:400;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.sidebar .search-box .form-group button{
	position:absolute;
	right:14px;
	top:10px;
	height:28px;
	width:30px;
	font-size:14px;
	color:#222222;
	line-height:100%;
	background:none;
	display:inline-block;
	font-weight:normal;
	text-align:right;
	border-left:1px solid #f2f2f2;
}

.sidebar .search-box.style-two .form-group button{
	background-color: #aed0f1;
    right: 0px;
    top: 0px;
    width: 50px;
    height: 50px;
    color: #ffffff;
    text-align: center;
}

.recent-comments .sidebar-title{
	margin-bottom:40px;
}

/*Comment Block*/

.comment-block{
	position:relative;
	margin-bottom:40px;
}

.comment-block .inner{
	position:relative;
	padding:36px 20px 20px;
	border:1px solid #f2f2f2;
}

.comment-block .inner .date{
	position:absolute;
	left:22px;
	top:-15px;
	font-size:14px;
	color:#848484;
	padding:1px 12px;
	font-weight:400;
	background-color:#f6f6f6;
}

.comment-block .inner .content{
	position:relative;
	padding-left:70px;
}

.comment-block .inner .content .image{
	position:absolute;
	left:0px;
	top:0px;
	width:50px;
	height:50px;
}

.comment-block .inner .content h3{
	position:relative;
	color:#0f1341;
	font-size:16px;
	font-weight:400;
	line-height:1.2em;
	margin-bottom:3px;
	font-family: 'Poppins', sans-serif;
}

.comment-block .inner .content h3 a{
	color:#020e28;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.comment-block .inner .content h3 a:hover{
	color:#aed0f1;
}

.comment-block .inner .content .text{
	position:relative;
	color:#848484;
	font-size:16px;
}

.comment-block .inner .content .reply-btn{
	position:absolute;
	right:0px;
	top:10px;
	color:#0f1341;
	font-size:18px;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.comment-block .inner .content .reply-btn:hover{
	color:#aed0f1;
}

/*Post Widget*/

.sidebar .popular-posts .post{
	position:relative;
	font-size:14px;
	color:#666666;
	padding:0px 0px;
	padding-left:108px;
	min-height:110px;
	margin-bottom:20px;
	border-bottom:1px solid #f0f0f0;
}

.sidebar .popular-posts .post:last-child{
	margin-bottom:0px;
	min-height:90px;
	border:0px;
}

.sidebar .popular-posts .post .post-thumb{
	 position:absolute;
	 left:0px;
	 top:0px;
	 width:90px;
}

.sidebar .popular-posts .post:hover .post-thumb img{
	opacity:0.70;
}

.sidebar .popular-posts .post .post-thumb img{
	display:block;
	width:100%;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.sidebar .popular-posts .post .post-thumb .overlay{
	position:absolute;
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	display:block;
	opacity:0;
	background-color:rgba(2,14,40,0.90);
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.sidebar .popular-posts .post:hover .post-thumb .overlay{
	opacity:1;
}

.sidebar .popular-posts .post .text{
	position:relative;
	top:-4px;
	font-size:16px;
	margin:0px 0px 0px;
	font-weight:500;
	color:#020e28;
	line-height:1.6em;
	text-transform:capitalize;
	font-family: 'Poppins', sans-serif !important;
}

.sidebar .popular-posts .post .text a{
	color:#222222;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.sidebar .popular-posts .post a,
.sidebar .popular-posts .post a:hover{
	color:#ff4800;	
}

.sidebar .popular-posts .post-info{
	position:relative;
	font-size:16px;
	color:#848484;
	padding-left:22px;
	font-weight:400;
}

.sidebar .popular-posts .post-info:before{
	position:absolute;
	content:'\f073';
	left:0px;
	top:0px;
	color:#ff4800;
	font-size:14px;
	font-family: 'FontAwesome';
}

/*Popular Tags*/

.sidebar .popular-tags a{
	position:relative;
	display:inline-block;
	line-height:24px;
	padding:2px 12px 2px;
	margin:0px 6px 10px 0px;
	color:#848484;
	text-align:center;
	font-size:16px;
	text-transform:capitalize;
	background:none;
	font-weight:400;
	border:1px solid #f0f0f0;
	transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
}

.sidebar .popular-tags a:hover{
	color:#ffffff;
	border-color:#aed0f1;
	background-color:#aed0f1;
}

.sidebar .popular-tags.style-two a{
	margin-right:3px;
	margin-bottom:7px;
	font-size:15px;
}

.sidebar .popular-tags.style-two a:hover{
	border-color:#0f1341;
	background-color:#0f1341;
}

/*Blog Single*/

.blog-single{
	position:relative;
}

.blog-single .inner-box{
	position:relative;
	margin-bottom:70px;
}

.blog-single .inner-box .image{
	position:relative;
}

.blog-single .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
}

.blog-single .inner-box .lower-content{
	position:relative;
	padding:25px 25px;
	border:1px solid #f2f2f2;
}

.blog-single .inner-box .lower-content .title{	
	position: relative;
    color: #aed0f1;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 3px;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
}

.blog-single .inner-box .lower-content .post-date{
	position: relative;
    color: #848484;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
}

.blog-single .inner-box .lower-content h3{
	position:relative;
	color:#020e28;
	font-size:24px;
	line-height:1.5em;
	font-weight:500;
	margin-top:5px;
	margin-bottom:20px;
}

.blog-single .inner-box .lower-content .text{
	position:relative;
}

.blog-single .inner-box .lower-content .text p{
	position:relative;
	color:#848484;
	font-size:16px;
	line-height:1.6em;
	margin-bottom:18px;
}

.blog-single .inner-box .lower-content .text blockquote{
	position:relative;
	border:none;
	text-align:center;
	padding:35px 30px;
	margin-bottom:40px;
	margin-top:30px;
	background-color:#f4f4f4;
}

.blog-single .inner-box .lower-content .text blockquote .blockquote-text{
	position:relative;
	z-index:1;
	font-size:18px;
	color:#848484;
	line-height:1.8em;
	font-weight:400;
}

.blog-single .inner-box .lower-content .text blockquote .quote-icon{
	position:absolute;
	left:50%;
	top:20px;
	line-height:1em;
	color:#ffffff;
	font-size:120px;
	text-align:center;
	margin-left:-45px;
}

.blog-single .inner-box .lower-content .text blockquote .quote-info{
	position:relative;
	display:inline-block;
	padding:12px 70px 0px;
	margin-top:20px;
	border-top:1px solid #e8e8e8;
}

.blog-single .inner-box .lower-content .text blockquote .quote-info h4{
	position:relative;
	color:#020e28;
	font-size:18px;
	font-weight:600;
}

.blog-single .inner-box .lower-content .text blockquote .quote-info .quote-desination{
	position:relative;
	color:#aed0f1;
	font-size:16px;
}

/*post share options*/

.blog-single .post-share-options{
	position:relative;
	margin-top:20px;
	padding:0px 0px 0px;
}

.blog-single .post-share-options .post-share-inner{
	border-top:1px solid #f2f2f2;
	padding-top:20px;
}

.blog-single .post-share-options .info-links{
	position:relative;
}

.blog-single .post-share-options .info-links li{
	position:relative;
	color:#848484;
	font-size:16px;
	margin-right:20px;
	display:inline-block;
}

.blog-single .post-share-options .info-links li a{
	position:relative;
	width:35px;
	height:35px;
	color:#aed0f1;
	font-size:14px;
	text-align:center;
	display:inline-block;
	border-radius:50%;
	line-height:34px;
	margin-right:10px;
	background-color:#f2f2f2;
}

.blog-single .post-share-options .tags span{
	font-size:16px;
	color:#020e28;
	font-weight:500;
	margin-right:8px;
	font-family: 'Poppins', sans-serif !important;
}

.blog-single .post-share-options .tags a{
	position:relative;
	color:#aed0f1;
	font-size:16px;
	font-weight:400;
	text-transform:capitalize;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.blog-single .post-share-options .tags{
	position:relative;
	color:#ff4800;
	font-size:16px;
	font-weight:400;
	margin-top:3px;
}

.blog-single .post-share-options .tags a:hover{
	color:#020e28;
}

/*Group Title*/

.sidebar-page-container .group-title{
	position:relative;
	margin-bottom:18px;
}

.sidebar-page-container .group-title h2{
	position:relative;
	font-size:24px;
	color:#020e28;
	font-weight:600;
	text-transform:capitalize;
}

/*Author Box*/

.author-box{
	position:relative;
	margin-bottom:50px;
}

.author-box .author-comment{
	position:relative;
	padding:30px 0px 25px;
	border-top:1px solid #f2f2f2;
	border-bottom:1px solid #f2f2f2;
}

.author-box .author-comment .inner{
	position:relative;
	padding-left:110px;
}

.author-box .author-comment .inner .image{
	position:absolute;
	left:0px;
	top:0px;
	width:85px;
	margin-bottom:20px;
}

.author-box .author-comment .inner h3{
	position:relative;
	color:#020e28;
	font-size:20px;
	font-weight:500;
	margin-bottom:10px;
}

.author-box .author-comment .inner .text{
	position:relative;
	color:#848484;
	font-size:16px;
	font-weight:400;
	line-height:1.7em;
	margin-bottom:10px;
}

/*** 

====================================================================
	Comments Area
====================================================================

 ***/

.sidebar-page-container .comments-area{
	position:relative;
	margin-top:0px;
	margin-bottom:50px;
}

.sidebar-page-container .comments-area .comment-box{
	position:relative;
	margin-bottom:35px;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.sidebar-page-container .comments-area .comment-box:nth-child(1){
	padding-top:0px;	
}

.sidebar-page-container .comments-area .comment-box:hover{
	border-color:#eb5310;	
}

.sidebar-page-container .comments-area .comment-box.reply-comment{
	margin-left:30px;
}

.sidebar-page-container .comments-area .comment{
	position:relative;
	font-size:14px;
	padding:0px 0px 0px 80px;
}

.sidebar-page-container .comments-area .comment .comment-inner{
	position:relative;
	padding:15px 20px;
	border:1px solid #f4f4f4;
}

.sidebar-page-container .comments-area .comment .comment-inner .rating{
	position:absolute;
	right:15px;
	top:15px;
}

.sidebar-page-container .comments-area .comment .comment-inner .rating .fa{
	display:inline-block;
	color:#aed0f1;
	font-size:14px;
}

.sidebar-page-container .comments-area .comment-box .author-thumb{
	position:absolute;
	left:0px;
	top:0px;
	margin-bottom:20px;
}

.sidebar-page-container .comments-area .comment-box .author-thumb img{
	width:60px;
	display:block;
}

.sidebar-page-container .comments-area .comment-info{
	position:relative;
	color:#020e28;
	line-height:24px;
	font-size:16px;	
	font-weight:500;
	font-family: 'Poppins', sans-serif !important;
}

.sidebar-page-container .comments-area .comment-box .text{
	color:#848484;
	font-size:16px;
    margin-top:12px;
	font-weight:400;
    line-height: 1.6em;
	margin-bottom:0px;
}

/*Comment Form*/

.comment-form{
	position:relative;
}

.comment-form .group-title{
	margin-bottom:20px;
}

.comment-form .rating-box{
	position:relative;
	margin-bottom:20px;
}

.comment-form .rating-box .text{
	position:relative;
	font-size:16px;
	color:#848484;
	margin-bottom:10px;
	margin-top:-5px;
}

.comment-form .rating-box .rating{
	position:relative;
	display:inline-block;
	margin-right:15px;
	padding-right:15px;
	border-right:1px solid #ebebeb;
}

.comment-form .rating-box .rating:last-child{
	border:0px;
}

.comment-form .rating-box .rating .fa{
	position:relative;
	margin-right:4px;
	display:inline-block;
}

.comment-form .rating-box .rating a{
	position:relative;
	color:#d6d6d6;
	font-size:12px;
	display:inline-block;
}

.comment-form .rating-box .rating a:hover{
	color:#aed0f1;
}

.comment-form .form-group{
	position:relative;
	margin-bottom:15px;
}

.comment-form .form-group label{
	position:relative;
	color:#848484;
	font-size:16px;
	font-weight:400;
}

.comment-form .form-group:last-child{
	margin-bottom:0px;
}

.comment-form .form-group input[type="text"],
.comment-form .form-group input[type="password"],
.comment-form .form-group input[type="tel"],
.comment-form .form-group input[type="email"],
.comment-form .form-group select{
	position:relative;
	display:block;
	width:100%;
	line-height:28px;
	padding:10px 15px;
	border:1px solid #efefef;
	height:50px;
	color:#848484;
	font-weight:300;
	background:#ffffff;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.comment-form .form-group input[type="text"]:focus,
.comment-form .form-group input[type="password"]:focus,
.comment-form .form-group input[type="tel"]:focus,
.comment-form .form-group input[type="email"]:focus,
.comment-form .form-group select:focus,
.comment-form .form-group textarea:focus{
	border-color:#ff4800;
}

.comment-form .form-group textarea{
	position:relative;
	display:block;
	width:100%;
	line-height:26px;
	padding:10px 15px;
	color:#d6d6d6;
	border:1px solid #efefef;
	height:105px;
	font-weight:300;
	background:#ffffff;
	resize:none;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.comment-form button{
	position:relative;
	margin-top:20px;
	padding:11px 34px;
	border-radius:0px;
	font-weight:600;
	text-transform:uppercase;
}

.comment-form input:focus,
.comment-form select:focus,
.comment-form textarea:focus{
	border-color:#aed0f1;	
}

/*** 

====================================================================
	Project Page Section
====================================================================

 ***/

.project-page-section{
	position:relative;
	padding:90px 0px 85px;
}

.project-page-section .outer-section{
	position:relative;
}

.project-page-section .outer-section .row{
	position:relative;
	margin:0px -30px;
}

.project-page-section .outer-section .project-block-five{
	padding:0px 30px;
}

.project-page-section .outer-section .load-more{
	position:relative;
	color:#aed0f1;
	font-size:14px;
	font-weight:600;
	text-decoration:underline;
	text-transform:uppercase;
	transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
	font-family: 'Poppins', sans-serif !important;
}

.project-page-section .outer-section .load-more:hover{
	color:#444444;
}

.project-block-five{
	position:relative;
	margin-bottom:60px;
}

.project-block-five .inner-box{
	position:relative;
}

.project-block-five .inner-box .image{
	position:relative;
}

.project-block-five .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
}

.project-block-five .inner-box .image .overlay-box{
	position:absolute;
	left:0px;
	bottom:0px;
	width:100%;
	display:block;
	opacity:0;
	transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
}

.project-block-five .inner-box:hover .image .overlay-box{
	opacity:1;
}

.project-block-five .inner-box .image .overlay-box .content{
	position:relative;
	left:0px;
	padding:15px 50px 15px 30px;
	display:inline-block;
	transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
	background-color:rgba(245,91,20,0.95);
}

.project-block-five .inner-box .image .overlay-box .content h3{
	position:relative;
	font-weight:500;
	font-size:18px;
}

.project-block-five .inner-box .image .overlay-box .content h3 a{
	position:relative;
	color:#ffffff;
	transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
}

.project-block-five .inner-box .image .overlay-box .content h3 a:hover{
	color:#333333;
}

.project-block-five .inner-box .image .overlay-box .content .location{
	position:relative;
	color:#ffffff;
	font-size:16px;
	margin-top:5px;
	transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
}

.project-block-five .inner-box .image .overlay-box .content .location .icon{
	margin-left:5px;
}

/*** 

====================================================================
	Project Modern Section
====================================================================

 ***/

.project-modern-section{
	position:relative;
	padding:90px 0px;
}

.project-single-section{
	position:relative;
	padding:90px 0px;
}

.project-single-section .inner-box{
	position:relative;
}

.project-single-section .inner-box .image{
	position:relative;
	margin-bottom:40px;
}

.project-single-section .inner-box .image img{
	position:relative;
	width:100%;
	display:block;
}

.project-single-section .inner-box h2{
	position:relative;
	color:#020e28;
	font-size:30px;
	font-weight:600;
	line-height:1.4em;
	margin-bottom:18px;
}

.project-single-section .inner-box .content-column{
	position:relative;
	margin-bottom:40px;
}

.project-single-section .inner-box .content-column .inner-column{
	position:relative;
}

.project-single-section .inner-box .content-column .text{
	position:relative;
	margin-bottom:40px;
}

.project-single-section .inner-box .content-column .text p{
	position:relative;
	color:#848484;
	font-size:16px;
	line-height:1.7em;
	margin-bottom:18px;
}

.project-single-section .inner-box .content-column .text p:last-child{
	margin-bottom:0px;
}

.project-single-section .inner-box .content-column .inner-column .social-icon-one li{
	margin-left:0px;
	margin-right:20px;
}

.project-single-section .inner-box .content-column .inner-column .social-icon-one li a{
	position:relative;
	color:#848484;
}

.project-single-section .inner-box .info-column{
	position:relative;
	margin-bottom:40px;
}

.project-single-section .inner-box .info-column .inner-column{
	position:relative;
	padding-left:25px;
	border-left:1px solid #f2f2f2;
}

.project-single-section .inner-box .info-column .inner-column ul{
	position:relative;
}

.project-single-section .inner-box .info-column .inner-column ul li{
	position:relative;
	margin-bottom:15px;
	color:#848484;
	font-size:16px;
}

.project-single-section .inner-box .info-column .inner-column ul li strong{
	color:#020e28;
	font-size:16px;
	font-weight:500;
	margin-right:12px;
	font-family: 'Poppins', sans-serif !important;
}

.project-single-section .inner-box .info-column .inner-column ul li .fa{
	color:#aed0f1;
	font-size:14px;
	margin-right:2px;
}

/*Post Options*/

.post-options{
	position:relative;
}

.post-options .inner-box{
	position:relative;
	padding:22px 0px;
	border-top:1px solid #eaeaea;
	border-bottom:1px solid #eaeaea;
}

.post-options .inner-box ul{
	position:relative;
}

.post-options .inner-box ul li.prev{
	position:relative;
	float:left;
}

.post-options .inner-box ul li.next{
	position:relative;
	float:right;
}

.post-options .inner-box ul li.prev a,
.post-options .inner-box ul li.next a{
	position:relative;
	color:#020e28;
	font-size:18px;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.post-options .inner-box ul li.grid{
	position:absolute;
	left:50%;
	color:#333333;
	font-size:26px;
	margin-left:-15px;
}

.post-options .inner-box ul li.grid a{
	color:#333333;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.post-options .inner-box ul li a:hover{
	color:#aed0f1;
}

/*Blog Cat Three*/

.blog-cat-three{
	position:relative;
}

.blog-cat-three li{
	position:relative;
	margin-bottom:15px;
}

.blog-cat-three li a{
	position:relative;
	color:#727272;
	font-size:16px;
	font-weight:400;
	padding-bottom:15px;
	padding-left:25px;
	display:block;
	border-bottom:1px solid #f0f0f0;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.blog-cat-three li a:before{
	position:absolute;
	content:'\f114';
	left:0px;
	top:0px;
	font-size:16px;
	color:#848484;
	font-family: 'FontAwesome';
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.blog-cat-three li a:hover:before,
.blog-cat-three li a:hover{
	color:#aed0f1;
}

/*Sidebar Range slider */

.range-slider-one{
	position:relative;
	min-height:48px;
}

.range-slider-one .title{
	position:relative;
	float:left;
	color:#848484;
	font-size:16px;
	font-weight:400;
	margin-top:4px;
}

.range-slider-one .title:before{
	position:absolute;
	content:'$';
	right:-14px;
	top:0px;
	color:#848484;
	font-size:16px;
	font-weight:300;
}

.range-slider-one .input{
	float:right;
    left: 8px;
	max-width:75px;
	padding-left:8px;
	margin-top:4px;
    position: relative;
}

.range-slider-one .input input{
	background:none;
	color:#848484;
	font-size:16px;
	font-weight:400;
	width:auto;
	text-align:left;
}

.range-slider-one .ui-widget.ui-widget-content{
	height:3px;
	border:none;
	margin-bottom:35px;
	background:#e6e6e6;	
}

.range-slider-one .ui-slider .ui-slider-range{
	top:0px;
	height:3px;
	background:#020e28;	
}

.range-slider-one .ui-state-default,
.range-slider-one .ui-widget-content .ui-state-default{
	top:-3px;
	width:10px;
	height:10px;
	border:1px solid #020e28;
	background:#020e28;
	border-radius:50%;
	cursor:pointer;	
}

.range-slider-one .theme-btn{
	padding:4px 18px;
	color:#ffffff;
	border-radius:0px;
	border-color:#aed0f1;
	background-color:#aed0f1;
}

.range-slider-one .theme-btn:hover{
	color:#ffffff;
}

/*Sidebar Realated Posts */

.sidebar .related-posts .post{
	position:relative;
	padding:0px 0px;
	padding-left:90px;
	min-height:98px;
	margin-bottom:25px;
	border-bottom:1px solid #eeeeee;
}

.sidebar .related-posts .post:last-child{
	margin-bottom:0px;
	border:0px;
	min-height:inherit;
}

.sidebar .related-posts .post .post-thumb{
	 position:absolute;
	 left:0px;
	 top:0px;
	 width:70px;
}

.sidebar .related-posts .post .post-thumb img{
	display:block;
	width:100%;	
}

.sidebar .related-posts .post h4{
	top:-2px;
	font-size:16px;
	font-weight:500;
	color:#020e28;
	text-transform:capitalize;
	font-family: 'Poppins', sans-serif !important;
}

.sidebar .related-posts .post h4 a{
	color:#020e28;
	transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
}

.sidebar .related-posts .post a,
.sidebar .related-posts .post a:hover{
	color:#ff6600;	
}

.sidebar .related-posts .post .price{
	font-size:16px;
	letter-spacing:1px;
	font-weight:400;
	color:#848484;
}

.sidebar .related-posts .post .rating{
	margin-bottom:1px;	
}

.sidebar .related-posts .post .rating .fa{
	position:relative;
	display:inline-block;
	margin-right:2px;
	font-size:12px;
	line-height:26px;
	color:#aed0f1;
}

.sidebar .related-posts .post .rating .fa-star.light{
	
}

/*Item Sorting*/

.shop-section .items-sorting{
	position:relative;
	margin-bottom:10px;	
}

.shop-section .items-sorting .results-column,
.shop-section .items-sorting .select-column{
	margin-bottom:20px;	
}

.shop-section .items-sorting .results-column h4{
	font-size:16px;
	font-weight:400;
	color:#aed0f1;
	line-height:24px;
	padding:13px 0px;
}

.shop-section .items-sorting .form-group{
	margin-bottom:0px;	
}

.shop-section .items-sorting select{
	position:relative;
	display:block;
	width:100%;
	line-height:24px;
	padding:9px 15px 9px;
	height:50px;
	font-size:16px;
	border:1px solid #eeeeee;
	cursor:pointer;
	-moz-appearance:none;
	-webkit-appearance:none;
	-ms-appearance:none;
	-o-appearance:none;
	background:url(../images/icons/icon-select.png) right center no-repeat;
	color:#999999;
	transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;	
}

.shop-section .items-sorting select option{
	text-indent:15px;	
}

.shop-section .styled-pagination{
	margin-top:10px;
}

/*Shop Item*/

.shop-item{
	position:relative;
	margin-bottom:30px;
}

.shop-item .inner-box{
	position:relative;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
	border:1px solid #f4f4f4;
}

.shop-item .inner-box:hover{
	-webkit-box-shadow: 0px 0px 15px 0px rgba(244,244,244,1);
	-moz-box-shadow: 0px 0px 15px 0px rgba(244,244,244,1);
	box-shadow: 0px 0px 15px 0px rgba(244,244,244,1);
}

.shop-item .inner-box:hover .image .overlay-box{
	opacity:1;
}

.shop-item .inner-box .image-box{
	position:relative;
	z-index:11;
	padding:40px 0px;
	text-align:center;
}

.shop-item .inner-box .image-box .image{
	position:relative;
	display:inline-block;
}

.shop-item .inner-box .image-box .overlay-box{
	position:absolute;
	content:'';
	left:0px;
	top:0px;
	width:100%;
	height:100%;
	opacity:0;
	text-align:center;
	background-color:rgba(15,19,65,0.90);
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.shop-item .inner-box .image-box .overlay-box .cart-option{
	position:relative;
	top:50%;
	margin-top:-14px;
}

.shop-item .inner-box .image-box .overlay-box .cart-option li{
	position:relative;
	margin:0px 3px;
	display:inline-block;
}

.shop-item .inner-box .image-box .overlay-box .cart-option li a{
	position:relative;
	font-size:18px;
	color:#ffffff;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.shop-item .inner-box .image-box .overlay-box .cart-option li a:hover{
	color:#aed0f1;
}

.shop-item .inner-box .image-box img{
	max-width:100%;
	display:inline-block;
}

.shop-item .inner-box .lower-content{
	position:relative;
}

.shop-item .inner-box .lower-content .price-box{
	position:relative;
	padding:15px 28px;
	background-color:#f4f4f4;
}

.shop-item .inner-box .lower-content .price-box h3{
	position:relative;
	font-size:16px;
	font-weight:500;
	margin-bottom:2px;
}

.shop-item .inner-box .lower-content .price-box h3 a{
	color:#020e28;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
	-ms-transition:all 0.3s ease;
	-o-transition:all 0.3s ease;
}

.shop-item .inner-box:hover .lower-content h3 a{
	color:#aed0f1;
}

.shop-item .inner-box .lower-content .price-box .price{
	position:relative;
	color:#848484;
	font-size:16px;
	font-weight:400;
}

.shop-item .inner-box .lower-content .rating{
	position:relative;
	padding:15px 30px;
}

.shop-item .inner-box .lower-content .rating .fa{
	position:relative;
	display:inline-block;
	font-size:12px;
	color:#aed0f1;
}





.product-details .basic-details{
	position:relative;
	margin-bottom:30px;
}

.product-details .image-column,
.product-details .info-column{
	margin-bottom:20px;
}

.product-details .image-column .image-box img{
	position:relative;
	display:block;
	width:100%;
	background-color:#f7f7f7;
}

.product-details .basic-details .details-header{
	position:relative;
	margin-bottom:16px;
}

.product-details .basic-details .details-header h4{
	font-size:24px;
	font-weight:500;
	margin:-5px 0px 15px;
	line-height:1.4em;
	color:#020e28;
}

.product-details .basic-details .details-header h4 a{
	color:#333333;	
}

.product-details .basic-details .details-header .rating{
	font-size:14px;
	color:#aed0f1;
	margin-bottom:15px;
}

.product-details .basic-details .details-header .rating .fa{
	display:inline-block;
	margin-right:2px;	
}

.product-details .basic-details .details-header .rating .txt{
	font-size:14px;
	padding-left:10px;
	color:#777777;	
}

.product-details .basic-details .details-header .item-price{
	font-size:22px;
	font-weight:400;
	color:#848484;
	line-height:22px;
	letter-spacing:1px;
	margin-bottom:6px;
}

.product-details .basic-details .prod-info{
	margin-bottom:15px;
	line-height:1.6em;
	font-size:13px;	
}

.product-details .basic-details .prod-info strong{
	font-weight:700;
	color:#111111;	
}

.product-details .basic-details .text{
	margin-bottom:25px;
	color:#848484;
	font-size:16px;
	font-weight:400;
	line-height:1.8em;	
}

.product-details .basic-details .location{
	position:relative;
	color:#020e28;
	font-size:16px;
	font-weight:400;
	margin-bottom:10px;
}

.product-details .basic-details .item-quantity .field-label{
	float:left;
	font-weight:700;
	font-size:14px;
	line-height:32px;
	display:inline-block;
	padding-right:20px;	
}

.product-details .basic-details .quantity-spinner,
.cart-section input.quantity-spinner{
	line-height:24px;
	padding:10px 15px !important;
	height:46px !important;
	box-shadow:none !important;	
}

.product-details .basic-details .bootstrap-touchspin .input-group-btn-vertical > .btn,
.cart-section .bootstrap-touchspin .input-group-btn-vertical > .btn{
	padding:11px 10px;
	background:#f4f5f6;	
}

.product-details .bootstrap-touchspin .input-group-btn-vertical i,
.cart-section .bootstrap-touchspin .input-group-btn-vertical i{
	top:6px;	
}

.product-details .basic-details .item-quantity{
	position:relative;
	float:left;
	width:85px;
	margin-bottom:25px;
	margin-right:20px;	
}

.product-details .basic-details .item-quantity .field-label{
	float:left;
	font-weight:700;
	font-size:14px;
	line-height:32px;
	display:inline-block;
	padding-right:20px;	
}

.product-details .basic-details .quantity-spinner,
.cart-table input.quantity-spinner{
	font-size:18px;
	line-height:24px;
	padding:10px 15px !important;
	height:50px !important;
	box-shadow:none !important;	
}

.product-details .basic-details .bootstrap-touchspin .input-group-btn-vertical > .btn,
.cart-table .bootstrap-touchspin .input-group-btn-vertical > .btn{
	padding:12px 10px;
	background:#f4f5f6;
	border-radius:0px;	
}

.product-details .bootstrap-touchspin .input-group-btn-vertical i,
.cart-table .bootstrap-touchspin .input-group-btn-vertical i{
	top:8px;	
}

.product-details .basic-details .add-to-cart{
	padding:11px 32px;
	border-radius:0px;
	text-transform:uppercase;
}

.product-details .basic-details .expired{
	position:relative;
	color:#aed0f1;
	font-size:16px;
	margin-bottom:40px;
}

/*Pincode Form*/

.pincode-form{
	position:relative;
	margin-bottom:15px;
}

.pincode-form .form-group{
	margin-bottom:10px;
}

.pincode-form .form-group input[type="text"]{
	position:relative;
	line-height:14px;
	padding:4px 10px 4px 15px;
	border:1px solid #f7f7f7;
	background:none;
	font-size:16px;
	width:170px;
	height:40px;
	display:inline-block;
	transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
}

.pincode-form .form-group button{
	position:relative;
	height:40px;
	width:100px;
	font-size:15px;
	color:#020e28;
	font-weight:600;
	line-height:100%;
	background:#f7f7f7;
	margin-left:8px;
	display:inline-block;
	text-transform:uppercase;
	font-family: 'Poppins', sans-serif !important;
}

/*** 

====================================================================
	Product Tabs Style
====================================================================

***/

.shop-single .product-details .prod-tabs{
	position:relative;
	margin-bottom:60px;
}

.shop-single .product-details .prod-tabs .tab-btns{
	position:relative;
	z-index:1;	
	border-bottom:1px solid #eeeeee;
}

.shop-single .product-details .prod-tabs .tab-btns .tab-btn{
	position:relative;
	top:1px;
	display:block;
	float:left;
	margin-right:5px;
	font-size:16px;
	background:#f4f4f4;
	color:#222222;
	border:1px solid #f4f4f4;
	text-transform:capitalize;
	font-weight:500;
	line-height:24px;
	cursor:pointer;
	padding:12px 18px 10px;
	transition:all 500ms ease;
	font-family: 'Poppins', sans-serif !important;
}

.shop-single .product-details .prod-tabs .tab-btns .tab-btn.active-btn:after{
	position:absolute;
	content:'';
	left:0px;
	bottom:-1px;
	width:100%;
	height:1px;
	background-color:#ffffff;
}

.shop-single .product-details .prod-tabs .tab-btns .tab-btn:hover,
.shop-single .product-details .prod-tabs .tab-btns .tab-btn.active-btn{
	color:#aed0f1;
	background:#ffffff;
}

.shop-single .product-details .prod-tabs .tabs-content{
	position:relative;
	padding:30px 30px 40px;
	border:1px solid #f0f0f0;
	border-top:0px;
}

.shop-single .product-details .prod-tabs .tabs-content .tab{
	position:relative;
	display:none;
	border-top:0px;
}

.shop-single .product-details .prod-tabs .tabs-content .tab.active-tab{
	display:block;	
}

.prod-tabs .tabs-content .tab .title{
	position:relative;
	color:#020e28;
	font-size:20px;
	font-weight:500;
	margin-top:10px;
	margin-bottom:25px;
}

/*Comment Form*/

.shop-comment-form{
	position:relative;
}

.shop-comment-form h2{
	position:relative;
	color:#020e28;
	font-size:26px;
	font-weight:600;
	margin-bottom:15px;
}

.shop-comment-form .group-title{
	margin-bottom:20px;
}

.shop-comment-form .rating-box{
	position:relative;
	margin-bottom:30px;
}

.shop-comment-form .rating-box .text{
	position:relative;
	font-size:16px;
	color:#848484;
	margin-bottom:10px;
}

.shop-comment-form .rating-box .rating{
	position:relative;
	display:inline-block;
	margin-right:10px;
	padding-right:10px;
	border-right:1px solid #ebebeb;
}

.shop-comment-form .rating-box .rating:last-child{
	border-right:0px;
}

.shop-comment-form .rating-box .rating .fa{
	position:relative;
	margin-right:2px;
	display:inline-block;
}

.shop-comment-form .rating-box .rating a{
	position:relative;
	color:#d6d6d6;
	font-size:12px;
	display:inline-block;
}

.shop-comment-form .rating-box .rating a:hover{
	color:#fc7837;
}

.shop-comment-form .form-group{
	position:relative;
	margin-bottom:15px;
}

.shop-comment-form .form-group label{
	position:relative;
	color:#848484;
	font-size:16px;
	font-weight:400;
}

.shop-comment-form .form-group:last-child{
	margin-bottom:0px;
}

.shop-comment-form .form-group input[type="text"],
.shop-comment-form .form-group input[type="password"],
.shop-comment-form .form-group input[type="tel"],
.shop-comment-form .form-group input[type="email"],
.shop-comment-form .form-group select{
	position:relative;
	display:block;
	width:100%;
	line-height:28px;
	padding:10px 15px;
	border:1px solid #efefef;
	height:50px;
	color:#848484;
	font-weight:300;
	background:#ffffff;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.shop-comment-form .form-group input[type="text"]:focus,
.shop-comment-form .form-group input[type="password"]:focus,
.shop-comment-form .form-group input[type="tel"]:focus,
.shop-comment-form .form-group input[type="email"]:focus,
.shop-comment-form .form-group select:focus,
.shop-comment-form .form-group textarea:focus{
	border-color:#aed0f1;
}

.shop-comment-form .form-group textarea{
	position:relative;
	display:block;
	width:100%;
	line-height:26px;
	padding:10px 15px;
	color:#848484;
	border:1px solid #efefef;
	height:105px;
	font-weight:300;
	background:#ffffff;
	resize:none;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
	transition:all 300ms ease;
}

.shop-comment-form button{
	position:relative;
	margin-top:20px;
	border-radius:0px;
	padding:11px 33px;
}

.shop-comment-form input:focus,
.shop-comment-form select:focus,
.shop-comment-form textarea:focus{
	border-color:#f06529;	
}

/*Related Products*/

.related-products{
	position:relative;
}

.related-products h2{
	position:relative;
	color:#020e28;
	font-size:24px;
	font-weight:600;
	margin-bottom:25px;
	font-family: 'Poppins', sans-serif !important;
}

/*** 

====================================================================
	Cart Section style
====================================================================

***/

.cart-section{
	position:relative;
	padding:80px 0px;
}

.cart-section .cart-outer{
	position:relative;	
}

.cart-section .table-outer{
	position:relative;
	width:100%;
	overflow-x:	auto;
}

.cart-section .cart-table{
	width:100%;
	min-width:900px;	
}

.cart-table .cart-header{
	position:relative;
	width:100%;
	text-transform:uppercase;
	font-size:16px;
	border-radius:0px;
	background:#020e28;
	color:#1f1f1f;
}

.cart-table thead tr th{
	line-height:24px;
	padding:16px 15px;
	min-width:120px;
	font-weight:500;
	color:#ffffff;
	font-size:16px;
	text-transform:capitalize;
	font-family: 'Poppins', sans-serif !important;
}

.cart-table thead tr th.prod-column{
	text-align:left;
	padding-left:30px;	
}

.cart-table tbody tr{
	border-bottom:1px solid #e6e6e6;
}

.cart-table tbody tr td{
	line-height:24px;
	padding:30px 10px 20px;
	min-width:100px;
}

.cart-table tbody tr .qty{
	width:90px;
	padding-right:40px;
}

.cart-table tbody tr .qty .quantity-spinner{
	background:#f5f5f5;	
}

.column.default-column .cart-table tbody tr .qty{
	width:110px;
	padding-right:20px;	
}

.cart-table tbody tr .prod-column .column-box h3{
	font-size:16px;
	color:#020e28;
	font-weight:600;
	margin-bottom:5px;	
}

.cart-table tbody tr .sub-total{
	font-weight:400;
	color:#aed0f1;
	font-size:20px;
}

.cart-table tbody tr .remove-btn{
	position:relative;
	font-size:18px;
	color:#ececec;
	width:34px;
	height:34px;
	text-align:center;
	line-height:30px;
	display:inline-block;
	border:2px solid #ececec;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	transition:all 500ms ease;
}

.cart-table tbody tr .remove-btn .fa{
	position:relative;
	top:2px;
	font-size:10px;
	line-height:14px;
	width:14px;
	height:14px;
	margin-right:8px;
	background:#f0f0f0;
	color:#ffffff;
	text-align:center;
	overflow:hidden;
	text-indent:100px;
}

.cart-table tbody tr .remove{
	text-align:center;
}

.cart-table tbody tr .remove-btn:hover{
	color:#aed0f1;
	border-color:#aed0f1;
}

.cart-table tbody tr .remove-btn:hover .fa{
	text-indent:0px;
	background:#fa9928;
}

.cart-table tbody tr td{
	vertical-align:middle;	
}

.cart-table tbody tr td.price{
	font-size:20px;
	font-weight:400;
	color:#848484;
}

.cart-table tbody tr td .quantity-spinner{
	padding:5px 0px 5px 20px;
	line-height:24px;
	height:34px;
	display:block;
	width:100%;
	position:relative;
}

.cart-table tbody .available-info{
	position:relative;
	padding-left:50px;
    color: #848484;
    font-size: 16px;
}

.cart-table tbody .available-info .icon{
	position:absolute;
	left:0px;
	top:5px;
	width:40px;
	height:40px;
	line-height:40px;
	text-align:center;
	font-size:18px;
	color:#ffffff;
	background:#aed0f1;
	border-radius:50%;
}

.cart-section .coupon-outer{
	position:relative;
	margin:30px 30px 70px ;
}

.cart-section .coupon-outer .cart-btn{
	color:#222222;
	font-size:15px;
	padding:11px 33px;
	font-weight:600;
	text-transform:uppercase;
	background-color:#f6f6f6;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-o-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	transition:all 500ms ease;
	font-family: 'Poppins', sans-serif !important;
}

.cart-section .coupon-outer .cart-btn:hover{
	color:#ffffff;
	background-color:#aed0f1;
}

.cart-section .apply-coupon{
	position:relative;
}

.cart-section .apply-coupon .form-group{
	position:relative;
	float:left;
	margin-right:15px;	
}

.cart-section .apply-coupon .form-group .coupon-btn{
	padding:8px 30px;
	line-height:30px;
	border-radius:0px;
}

.cart-section .apply-coupon .form-group input[type="text"]{
	display:block;
	line-height:26px;
	padding:12px 20px;
	border:2px solid #f7f7f7;
	height:50px;
	width:230px;
	background:none;	
}

.cart-section .estimate-form{
	position:relative;
}

.cart-section .sec-title{
	margin-bottom:25px;
}

.cart-section .sec-title h2{
	font-size:24px;
	color:#020e28;
	padding-bottom:0px;
	text-transform:capitalize;
}

.cart-section .estimate-form .row{
	margin-left:-7px;
	margin-right:-7px;	
}

.cart-section .estimate-form .row .form-group{
	padding:0px 7px !important;
	margin-bottom:30px;
}

.cart-section .totals-table{
	position:relative;
	border:2px solid #f0f0f0;
	border-radius:2px;
	margin-bottom:30px;
}

.cart-section .totals-table .col{
	position:relative;
	display:block;
	float:left;
	padding:15px 15px;
	line-height:24px;
	width:50%;
	font-weight:400;
	font-size:16px;
	color:#848484;
}

.cart-section .totals-table .col-title{
	font-size:16px;
	color:#020e28;
	font-weight:600;
	border-right:2px solid #f0f0f0;	
	font-family: 'Poppins', sans-serif !important;
}

.cart-section .totals-table li{
	position:relative;
	border-bottom:1px solid #f0f0f0;	
}

.cart-section .totals-table li:last-child{
	border-bottom:none;	
}

.cart-section .cart-note{
	position:relative;
	margin-bottom:50px;
	padding:16px 20px;
	text-align:center;
	border:1px dashed #b2dd4c;
	background:#f4f4f4;
	line-height:1.7em;
}

.product-details .basic-details .item-quantity input[type="text"],
.cart-table tbody tr .qty input[type="text"]{
	position:relative;
	line-height:46px;
	height:46px;
	background:#ffffff;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn{
	height:24px;
	background:#e9e9e9;	
}

.bootstrap-touchspin .input-group-btn-vertical i{
	left:6px;
	top:6px;	
}

/*Shipping Form*/

.shipping-form .form-group{
	position:relative;
	margin-bottom:30px;
	padding:0px 15px;
}

.shipping-form .form-group .ui-selectmenu-button.ui-button{
	background-color:#ffffff;
	height:45px;
	line-height:16px;
	z-index:10;
	border:2px solid #f2f2f2;
	background-position:right top;
	background-repeat:no-repeat;
	background-image:url(../images/icons/arrow-right.jpg);
}

.shipping-form .form-group .ui-button .ui-icon{
	opacity:0;
}

.shipping-form .form-group input,
.shipping-form .form-group select{
	position:relative;
	display:block;
	width:100%;
	line-height:33px;
	padding:10px 20px;
	height:45px;
	color:#848484;
	font-size:14px;
	background:#ffffff;
	border:2px solid #f4f4f4;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	transition:all 500ms ease;	
}

.shipping-form .form-group textarea{
	position:relative;
	display:block;
	width:100%;
	line-height:30px;
	padding:10px 20px;
	height:120px;
	resize:none;
	font-size:16px;
	font-weight:300;
	background:#f7f7f7;
	border:1px solid #f4f4f4;
	-webkit-transition:all 500ms ease;
	-ms-transition:all 500ms ease;
	-moz-transition:all 500ms ease;
	transition:all 500ms ease;	
}

.shipping-form .form-group input:focus,
.shipping-form .form-group textarea:focus{
	border-color:#aed0f1;
}

.shipping-form .form-group .total-btn{
    color: #222222;
    font-size: 15px;
    padding: 11px 33px;
    font-weight: 600;
	background-color:#f6f6f6;
	text-transform:uppercase;
	font-family: 'Poppins', sans-serif !important;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    transition: all 500ms ease;
}

.shipping-form .form-group .total-btn:hover{
	background-color:#aed0f1;
	color:#ffffff;
}

.cart-section .cart-outer .checkout-btn{
	padding:11px 28px;
	border-radius:0px;
}

/*** 

====================================================================
	CheckOut Sectioon
====================================================================

***/

.checkout-page{
	position:relative;
	padding:80px 0px 0px;
}

.checkout-page .default-links{
	position:relative;
	margin-bottom:50px;	
}

.checkout-page .default-links li{
	margin-bottom:15px;
	line-height:24px;
	padding:18px 30px;
	background:#f4f4f4;
	color:#020e28;
	font-size:16px;
	font-weight:500;
	border-left:2px solid #aed0f1;
	font-family: 'Poppins', sans-serif !important;
}

.checkout-page .default-links li a{
	color:#aed0f1;
}

.checkout-form{
	position:relative;
}

.checkout-form .checkout-title{
	position:relative;
	margin-bottom:20px;
}

.checkout-form .checkout-title h2{
	position:relative;
	color:#020e28;
	font-size:24px;
	font-weight:600;
}

.checkout-form .form-group{
	position:relative;
	margin-bottom:25px;	
}

.checkout-form .form-group .field-label{
	display:block;
	line-height:24px;
	margin-bottom:6px;
	text-transform:capitalize;
	color:#020e28;	
	font-size:16px;
	font-weight:400;
	font-family: 'Poppins', sans-serif !important;
}

.checkout-form .form-group .field-label sup{
	top:-1px;
	font-size:16px;
}

.checkout-form input[type="text"],
.checkout-form input[type="email"],
.checkout-form input[type="password"],
.checkout-form input[type="tel"],
.checkout-form input[type="number"],
.checkout-form input[type="url"],
.checkout-form select,
.checkout-form textarea{
	position:relative;
	display:block;
	width:100%;
	background:#ffffff;
	line-height:22px;
	padding:12px 15px;
	height:48px;
	border:2px solid #f4f4f4;
	transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
}

.checkout-form textarea{
	resize:none;
	height:120px !important;	
}

.checkout-form input:focus,
.checkout-form select:focus,
.checkout-form textarea:focus{
	border-color:#aed0f1;	
}

.checkout-page .check-box{
	line-height:24px;
	font-size:14px;
	font-weight:normal;
	padding-top:5px;	
}

.checkout-page .check-box label{
	position:relative;
	top:-1px;
	font-weight:500;
	padding:0px;
	font-size:16px;
	cursor:pointer;
	color:#46c3e8;
}

.checkout-page .lower-content{
	position:relative;
	margin-top:60px;
	padding:80px 0px;
	background-color:#f4f4f4;
}

.checkout-page .lower-content .order-column{
	margin-bottom:40px;
}

.checkout-page .lower-content .column h2{
	position:relative;
	color:#020e28;
	font-size:24px;
	font-weight:600;
}

.checkout-page .lower-content .checkout-title{
	margin-bottom:25px;
}

/*cart outer*/

.cart-outer{
	position:relative;	
}

.cart-outer .table-outer{
	position:relative;
	width:100%;
	overflow-x:	auto;
}

.checkout-page .lower-content .order-column .cart-outer{
	overflow-x:auto;
}

.checkout-page .lower-content .order-column .cart-table{
	width:100%;
}

.cart-table .cart-header{
	position:relative;
	width:100%;
	text-transform:capitalize;
	font-size:18px;
	background:#020e28;
	color:#222222;
}

.cart-table thead tr th{
	line-height:24px;
	padding:13px 30px;
	font-weight:500;
}

.cart-table thead tr th .fa{
	font-size:18px;	
}

.cart-table thead tr th.prod-column{
	text-align:left;
}

.cart-table tbody tr td{
	line-height:24px;
	padding:30px 0px 30px 30px;
}

.cart-table tbody tr .qty input{
	width:45px;
	height:50px;
	color:#222222;
	font-size:18px;
	line-height:50px;
	background:#f7f7f7;
	text-align:center;
}

.cart-table tbody tr .prod-column .column-box{
	position:relative;
	min-height:70px;
	padding-left:100px;
	padding-top:20px;
	text-align:left;
	min-width:230px;
}

.cart-table tbody tr .prod-column .column-box .prod-thumb{
	position:absolute;
	width:80px;
	left:0px;
	top:0px;	
	border:1px solid #f0f0f0;
}

.cart-table tbody tr .prod-column .column-box .prod-thumb img{
	 display:block;
	 width:100%;
}

.cart-table tbody tr .prod-column .column-box h4{
	font-size:16px;
	color:#222222;
	font-weight:500;
	margin-bottom:5px;
	text-transform:capitalize;
}

.cart-table tbody tr .price{
	font-weight:700;
	font-size:14px;
	color:#777777;	
}

/*Total Table*/

.checkout-page .lower-content .totals-table{
	position:relative;
	margin-bottom:40px;
	border:1px solid #e6e6e6;
}

.checkout-page .lower-content .totals-table li .col{
	padding:12px 20px;
}

.checkout-page .lower-content .totals-table li{
	line-height:24px;
	border-bottom:1px solid #e6e6e6;	
}

.checkout-page .lower-content .totals-table li .col.title{
	font-weight:500;
	color:#222222;
	font-size:16px;
	border-right:1px solid #e6e6e6;
	font-family: 'Poppins', sans-serif !important;
}

.checkout-page .lower-content .totals-table li:last-child{
	border-bottom:none;
	margin-bottom:0px;
}

.checkout-page .lower-content .totals-table .col{
	position:relative;
	display:block;
	float:left;
	line-height:24px;
	width:50%;
	color: #848484;
	font-weight: 400;
	font-size:16px;
}

/*Payment Option*/

.checkout-page .payment-options{
	position:relative;
	padding:30px 20px 40px;
	background-color:#ffffff;
	border:1px solid #e6e6e6;
}

.checkout-page .payment-options li{
	position:relative;
	margin-bottom:15px;	
}

.checkout-page .payment-options li .radio-option{
	position:relative;		
}

.checkout-page .payment-options li .radio-option label{
	position:relative;
	display:inline-block;
	padding-left:30px;
	font-weight:500 !important;
	text-transform:capitalize;
	letter-spacing:1px;
	color:#333333;
	cursor:pointer;
}

.checkout-page .payment-options li .radio-option label strong{
	font-weight:500;	
	color:#020e28;
	font-size:16px;
	font-family: 'Poppins', sans-serif !important;
}

.checkout-page .payment-options li .radio-option label strong a{
	font-size:16px;
	color:#020e28;
	margin-left:30px;
	text-decoration:underline;
}

.checkout-page .payment-options li .radio-option input[type="radio"]{
	position:absolute;
	left:0px;
	top:3px;	
}

.checkout-page .payment-options li .radio-option label .small-text{
	display:block;
	padding-top:5px;
	letter-spacing:0px;
	text-transform:none;
	font-size:16px;
	color:#848484;
	font-weight:400;
	line-height:1.7em;
}

.checkout-page .payment-options li .radio-option label img{
	position:relative;
	top:-3px;
	display:inline-block;
	max-width:100%;
	padding-left:30px;	
}

.checkout-page .payment-options .theme-btn{
	padding:11px 32px;
	margin-top:10px;
	margin-left:32px;
	border-radius:0px;
}

/*** 

====================================================================
	Login / Register Section
====================================================================

***/

.register-section{
	position:relative;
	padding:80px 0px 40px;
}

.register-section .form-column{
	margin-bottom:40px;	
}

.register-section .form-column .sec-title{
	position:relative;
	margin-bottom:30px;	
}

.register-section .form-column .sec-title h2{
	text-transform:capitalize;
	padding-bottom:0px;
}

.styled-form{
	position:relative;	
}

.styled-form .submit-text{
	font-size:15px;
	color:#9e9e9e;
	font-weight:400;
	margin-top:10px;
}

.styled-form .form-group{
	position:relative;
	margin-bottom:30px;
	font-size:16px;
	color:#848484;
}

.checkout-section .styled-form .form-group{
	position:relative;
	margin-bottom:20px;	
}

.styled-form .form-group .check-box{
	line-height:24px;
	font-size:15px;
	font-weight:normal;
	padding-top:5px;
	color:#272727;
}

.styled-form .form-group .check-box label{
	position:relative;
	top:-1px;
	font-weight:normal;
	padding:0px;
	font-size:15px;
	cursor:pointer;
}

.styled-form .pull-left label,
.styled-form .pull-right label{
	cursor:pointer;
}

.styled-form .form-group .adon-icon{
	position:absolute;
	top:50%;
	margin-top:-10px;
	right:10px;
	width:20px;
	height:20px;
	line-height:20px;
	font-size:15px;
	color:#999999;
	z-index:2;
}

.styled-form .form-group .field-label{
	display:block;
	color:#272727;
	text-transform:capitalize;
	font-size:16px;
	margin-bottom:7px;
	font-weight:400;
}

.styled-form input[type="text"],
.styled-form input[type="email"],
.styled-form input[type="password"],
.styled-form input[type="tel"],
.styled-form input[type="number"],
.styled-form input[type="url"],
.styled-form select,
.styled-form textarea{
	position:relative;
	display:block;
	width:100%;
	background:#ffffff;
	line-height:30px;
	padding:8px 20px;
	height:50px;
	border:1px solid #f4f4f4;
	transition:all 300ms ease;
	-webkit-transition:all 300ms ease;
	-ms-transition:all 300ms ease;
	-o-transition:all 300ms ease;
	-moz-transition:all 300ms ease;
}

.checkout-section .styled-form input[type="text"],
.checkout-section .styled-form input[type="email"],
.checkout-section .styled-form input[type="password"],
.checkout-section .styled-form select{
	line-height:26px;
	height:44px;	
}

.styled-form select{
	-webkit-appearance:none;
	-ms-appearance:none;
	-moz-appearance:none;
	-o-appearance:none;
	background:#ffffff url(../images/icons/icon-select.png) right center no-repeat;
	cursor:pointer;
}

.styled-form select option{
	text-indent:20px;	
}

.styled-form textarea{
	resize:none;
	height:110px;	
}

.styled-form input:focus,
.styled-form select:focus,
.styled-form textarea:focus{
	border-color:#aed0f1;	
}

.styled-form .form-group .theme-btn{
	min-width:150px;
	text-align:center;
	padding:11px 34px;	
	border-radius:0px;
}

.styled-form .remember-me{
	position:relative;
	color:#222222;
	font-size:16px;
	font-weight:500;
}

.social-links-two a{
	position:relative;
	display:inline-block;
	font-size:14px;
	width:42px;
	height:42px;
	line-height:42px;
	text-align:center;
	color:#ffffff;
	background:#fc721e;
	margin:0px 0px 0px 15px;
	transition:all 0.5s ease;
	-moz-transition:all 0.5s ease;
	-webkit-transition:all 0.5s ease;
	-ms-transition:all 0.5s ease;
	-o-transition:all 0.5s ease;
}

.social-links-two a:hover{
	opacity:0.80;
}


.social-links-two .facebook{
	background:#3b5998;	
}

.social-links-two .twitter{
	background:#33ccfe;	
}

.social-links-two .google-plus{
	background:#dd4b39;	
}



/* CUSTOM */
.vc_col-has-fill>.vc_column-inner, .vc_row-has-fill+.vc_row-full-width+.vc_row>.vc_column_container>.vc_column-inner, .vc_row-has-fill+.vc_row>.vc_column_container>.vc_column-inner, .vc_row-has-fill+.vc_vc_row>.vc_row>.vc_vc_column>.vc_column_container>.vc_column-inner, .vc_row-has-fill+.vc_vc_row_inner>.vc_row>.vc_vc_column_inner>.vc_column_container>.vc_column-inner, .vc_row-has-fill>.vc_column_container>.vc_column-inner, .vc_row-has-fill>.vc_row>.vc_vc_column>.vc_column_container>.vc_column-inner, .vc_row-has-fill>.vc_vc_column_inner>.vc_column_container>.vc_column-inner {
	padding-top: 0 !important;
}

.sticky-header .nav-outer.clearfix nav {
    margin-top: 15px;
}
.sticky-header a:hover {
	text-decoration: none;
}

.sticky-header a.quote-btn:hover {
	color: white !important;
}

.main-slider {
	background-size: cover;
	background-position: center center;
	padding: 100px 0;
}
.main-slider h1 {
	font-weight: bold;
	font-size: 40px;
	color: #009ce1;
}
.main-slider h2 {
	font-weight: bold;
	font-size: 25px;
	color: #3c4144;
	text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 30px;
    line-height: 30px;
}
.main-slider p {
	color: #3c4144;
	font-size: 25px;
	line-height: 30px;
}

.services-block-two .icon-box {
    height: 70px;
}
.services-block-two h3 {
    min-height: 60px;
}
.services-block-two .text {
    line-height: 20px !important;
    min-height: 60px;
    padding: 0 15px;
}

.detail-column {
    padding: 80px 30px !important;
    text-align: center;
    font-weight: 600;
}
.detail-column h3{
    color: #332e86;
    font-size: 40px;
}
.detail-column img {
	margin-bottom: 10px;
}
.detail-column .text {
	text-transform: uppercase;
	line-height: 20px;
    font-weight: 500;
}

.detail-column:nth-child(2) .text,
.detail-column:last-child .text {
    padding: 0 55px !important;
}

.detail-column:not(:first-child):before {
    content: "";
    position: absolute;
    left: 0;
    top: 40px;
    height: calc(100% - 80px);
    width: 1px;
    background: rgba(0,0,0,0.3);
}

.quote-box .sec-title h2 {
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: 30px;
}
.quote-box .sec-title .subtitle {
	color: white;
	font-size: 20px;
}
.quote-box .small-text {
	color: #848484;
	font-family: "Poppins", sans-serif;
}

.quote-form select {
	-webkit-appearance: none;
	-moz-appearance: none;
	cursor: pointer;
	background: white;
}
.quote-form input,
.quote-form select {
    border-radius: 0;
    padding: 15px 20px;
    width: 100%;
    margin-bottom: 30px;
    font-family: "Poppins", sans-serif;
}
.quote-form input[type="submit"] {
	color: #fff;
	border-radius: 100px;
	background: #332e86;
	padding: 12px 22px;
	transition: background 0.3s;
}
.quote-form input[type="submit"]:hover {
	background: #009ce1;
}

span.wpcf7-form-control-wrap.intervention:after {
    content: "▼";
    position: absolute;
    top: -2px;
    right: 10px;
    pointer-events: none;
}

.contactbloc {
    padding: 50px 30px;
    background: #f4f4f4;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
}
.contactbloc img {
	margin-top: 15px;
	margin-right: 15px;
}

.sec-title {
	text-transform: uppercase;
}

.contactjoin input,
.contactjoin textarea {
    padding: 15px 0;
    background: transparent;
    border-bottom: 1px solid rgba(255,255,255,0.3);
    margin-bottom: 30px;
    width: 100%;
    color: white;
    font-family: "Poppins", sans-serif;
}
.contactjoin input::placeholder,
.contactjoin textarea::placeholder {
    color: white;
}
.contactjoin input[type="submit"] {
	border: none;
    padding: 12px 22px;
    width: auto;
    background: #009ce1;
    border-radius: 100px;
    font-family: "Poppins", sans-serif;
    text-transform: uppercase;
    transition: background 0.3s;
}
.contactjoin input[type="submit"]:hover {
	background: #332e86;
}
.contactjoin textarea {
	height: 65px;
}

input[type="submit"] {
	cursor: pointer;
	padding-right: 50px !important;
	font-weight: 600;
}

.submit {
	position: relative
}
.submit:after {
    content: url(/wp-content/uploads/2018/09/submit-arrow.png);
    position: absolute;
    right: 50px;
    top: 12px;
}

.tl-address {
    display: flex;
    align-items: center;
    margin-top: 30px;
    color: white;
    font-family: "Poppins", sans-serif;
}

.tl-address img {
	margin-right: 30px;
}

div.wpcf7-mail-sent-ok,
div.wpcf7-validation-errors {
	border: none;
    color: white;
    font-family: "Poppins", sans-serif;
    margin: 15px 0;
}
div.wpcf7-mail-sent-ok {
	background: #019098; 
}
div.wpcf7-validation-errors {
    background: #332e86;
}
span.wpcf7-not-valid-tip {
	color: #332e86;
    font-family: "Poppins", sans-serif;
}

.temoignage-wrapper .vigne-bg {
	position: absolute;
	z-index: 0;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
    background: url(/wp-content/uploads/2019/01/Ambiance_Vignoble.jpg);
    background-size: cover;
    background-position: center;
    transition: opacity 0.3s;
}

footer {
	background-color: #222528;
	padding-top: 1rem;
	color: #9e9e9e;
	font-size: 14px;
}
footer a,
footer a:hover {
	color: inherit;
	text-decoration: underline;
}
footer .container {
	position: relative;
}

footer a.a42 {
    position: absolute;
    right: 15px;
    top: 0;
}

.page-erreur {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.page-erreur h2 {
    font-size: 200px;
}
.page-erreur a {
    background: #009ce1;
    color: white;
    padding: 12px 22px;
    border-radius: 100px;
    font-family: "Poppins", sans-serif;
    text-decoration: none;
    transition: background 0.3s;
}
.page-erreur a:hover {
	background: #332e86;
	color: white;
	text-decoration: none;
}

/* SAFARI HACK */
body.safari *[class*="col"]{
	margin-left: -1px;
}

/* IE HACK */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    span.wpcf7-form-control-wrap.intervention:after {
        display: none;
    }
}

/* Custom responsive */
@media (max-width: 768px) {
	.detail-column:not(:first-child):before {
		display: none;
	}
}

@media (min-width: 769px) {
	.navbar-collapse.collapse {
	    display: block!important;
	    height: auto!important;
	    padding-bottom: 0;
	    overflow: visible!important;
	}
	.navbar-header {
	    display: none;
	}
}

@media (max-width: 425px) {
	.testimonial-section-two .testimonial-column .inner-column {
    	margin-right: 0 !important;
	}
	.testimonial-section-two .testimonial-column {
		padding: 0 !important;
	}
	.page-erreur h2 {
	    font-size: 50px;
	}
	.quote-box .sec-title h2 {
		font-size: 25px !important;
	}
	.contactbloc img {
		margin-top: 0;
		margin-bottom: 15px;
	}
	body {
		font-family: "Poppins", sans-serif !important;
	}
}