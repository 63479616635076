/* The Metron - Industry and Factory HTML Template */

@media only screen and (max-width: 1600px){

	.page-wrapper{
		overflow:hidden;	
	}
	
}


@media only screen and (max-width: 1140px){
	
	.main-header{
		position:relative;
		margin:0px !important;
	}
	
	.main-menu .navigation > li{
		margin-left:20px;	
	}
	
	.sec-title h2{
		font-size:30px;
	}
	
	.main-header .header-top .top-left ul li:last-child{
		top:0px;
	}
	
	.main-header .header-top .top-left .links li{
		padding-right:14px;
		margin-right:14px;
	}
	
	.content-image,
	.sidebar-page-container .sidebar-side .with-border:before{
		display:none;
	}
	
	.sidebar-page-container .sidebar-side .with-border{
		padding-left:0px;
	}
	
	.main-header .nav-outer .outer-box .social-icon-one{
		display:none;
	}
	
	.main-header .header-lower .search-box .form-group input[type="text"],
	.main-header .header-lower .search-box .form-group input[type="search"]{
		width:260px;
	}
	
	.header-style-three .nav-outer .outer-box .quote-btn{
		display:none;
	}
	
	.header-style-four{
		background-color:#041332;
	}
	
	.header-style-four .navigation > li{
		padding-bottom:20px;
	}
	
	.news-block .inner-box h2,
	.news-block-two .inner-box .lower-content h3{
		font-size:18px;
	}
	
	.about-section .content-column .inner-column,
	.career-section .video-column .inner-column{
		padding-right:0px;
	}
	
	.about-section .content-column .inner-column .bold-text br,
	.career-section .content-column .inner-column .sec-title br{
		display:none;
	}
	
	.info-block .inner-box h3{
		margin-left:90px;
	}
	
	.testimonial-block-three .inner-box .text{
		margin-bottom:40px;
	}
	
	.about-section .content-column .inner-column .about-block li{
		padding-right:20px;
		margin-right:0px;
		border:none;
		margin-bottom:30px;
	}
	
	.call-to-action-section-three .btn-column .theme-btn{
		padding:10px 20px;
	}
	
	.project-block-four .inner-box .lower-content .text{
		font-size:15px;
	}
	
	.highlights-section .services-column .inner-column{
		margin:0px;
	}
	
	.history-section .auto-container{
		padding:0px 30px;
	}
	
	.maintenance-section{
		background-image:none !important;
	}
	
	.market-sidebar{
		margin-left:0px;
	}
	
	.project-page-section .outer-section .row{
		margin:0px -20px;
	}
	
	.project-page-section .outer-section .project-block-five{
		padding:0px 15px;
		margin-bottom:30px;
	}
	
	.contact-section .info-column .lower-box{
		padding:15px 15px;
	}
	
	.contact-section .info-column .lower-box .lower-inner .emailed{
		font-size:14px;
	}
	
	#schedule-box .modal-content .get-quote-form{
		margin:0px 15px;
		width:auto !important;
	}
	
	#schedule-box .modal-content .get-quote-form .inner-box{
		max-width:none;
		padding:50px;
		width:auto !important;
	}
	
	.main-menu .navigation > li > .mega-menu{
		padding:30px 30px;
	}
	
	.main-menu .navigation > li .mega-menu-bar h3{
		font-size:16px;	
	}
	
	.main-menu .navigation > li .mega-menu-bar .column > ul > li > a{
		font-size:15px;	
	}
	
	.services-block .inner-box .overlay-box{
		padding-bottom:20px;	
	}
	
	.market-section .title-column .inner-column{
		border-bottom:none;	
	}
	
	.market-section .row{
		margin:0px -15px;	
	}
	
	.market-section .row .m-block{
		padding:0px 15px;
	}
	
	.market-block .inner-box{
		padding:30px 20px;	
	}
	
	.market-section .market-block .inner-box:before{
		left:0px;
		right:0px;	
	}
	
	.market-block .inner-box .view_all{
		left:20px;	
	}
	
	.market-section .row .m-block:nth-child(2) .inner-box:after,
	.market-section .row .m-block:nth-child(3) .inner-box:after,
	.market-section .row .m-block:nth-child(5) .inner-box:after,
	.market-section .row .m-block:nth-child(6) .inner-box:after{
		display:none;
	}
	
}

@media only screen and (min-width: 768px){
	.main-menu .navigation > li > ul,
	.main-menu .navigation > li > ul > li > ul,
	.main-menu .navigation > li > .mega-menu{
		display:block !important;
		visibility:hidden;
		opacity:0;
	}
}

@media only screen and (max-width: 1023px){
	
	.main-header .main-box .logo-box{
		width:100%;
		padding-bottom:10px;
	}
	
	.main-header .nav-outer{
		width:100%;
	}
	
	.main-menu .navigation > li{
		margin-left:0px;
		margin-right:18px;
		padding:0px;
	}
	
	.main-header .btn-outer{
		display:none;
		padding:12px 0px;
	}
	
	.fixed-header .main-menu .navigation > li > a{
		padding:20px 0px;	
	}
	
	.tparrows{
		display:none;
	}
	
	.main-slider h2{
		font-size:45px;
	}
	
	.main-slider h1{
		font-size:38px;
	}
	
	.main-header .header-upper .upper-right{
		width:100%;
		padding-top:0px;
		text-align:center;
		padding-bottom:20px;
	}
	
	.main-header .info-box{
		float:none;
		margin-left:15px;
		display:inline-block;
	}
	
	.main-header .sticky-header .main-menu .navigation > li{
		margin-left:0px;
		margin-right:20px;
	}
	
	.main-header .sticky-header .right-col{
		width:100%;
	}
	
	.info-block:after,
	.testimonial-section .owl-dots,
	.fixed-header .sticky-header,
	.testimonial-section .owl-stage-outer:before{
		display:none;	
	}
	
	.main-header .header-top .top-left,
	.main-menu .navigation > li:before,
	.main-menu .navigation > li:after{
		display:none;
	}
	
	.main-header .header-top .top-right{
		width:100%;
		text-align:center;
	}
	
	.main-header .header-top .top-right ul li{
		float:none;
		display:inline-block;
	}
	
	.main-header .nav-outer .outer-box{
		margin-top:10px;
	}
	
	.main-header .header-lower .info-box{
		margin-right:12px;
	}
	
	.main-header .header-lower .lower-right,
	.main-header .header-lower .search-box,
	.main-header .header-lower .search-box .form-group input[type="text"],
	.main-header .header-lower .search-box .form-group input[type="search"]{
		width:100%;
	}
	
	.main-header .header-lower .lower-right{
		margin-top:0px;
	}
	
	.header-style-three .header-lower-two .nav-outer,
	.header-style-three .header-upper-two .header-upper-left,
	.header-style-three .header-lower-two .outer-container{
		padding-left:0px;
	}
	
	.header-style-three .header-lower-two .logo-box{
		position: relative;
		top: 0px;
		text-align: center;
		max-width: 200px;
		margin: 0 auto;
		margin: 15px auto;
	}
	
	.call-to-action-section .link-column,
	.main-header .nav-outer .search-box-outer{
		margin-top:13px;
	}
	
	.header-style-four .header-top .top-right ul li{
		float:left;
	}
	
	.header-style-four .header-top .top-right{
		width:auto;
	}
	
	.header-style-four .header-top .top-left{
		display:block;
	}
	
	.services-section .inner-container{
		margin-top:80px;
	}
	
	.projects-section .sec-title .text{
		margin-top:20px;
	}
	
	.projects-section .sec-title .view_all{
		float:left;
		margin-top:15px;
		display:inline-block;
	}
	
	.testimonial-block .inner-box{
		padding-left:240px;
	}
	
	.call-to-action-section,
	.call-to-action-section .link-column{
		text-align:center;
	}
	
	.main-footer .footer-bottom .title-column .text{
		min-height:55px;
		line-height:3em;
		margin-bottom:15px;
	}
	
	.info-block .inner-box h3{
		margin-left:160px;
	}
	
	.market-section-two .title-box h2,
	.call-to-action-section-three h2{
		margin-bottom:15px;
	}
	
	.testimonial-section-two .testimonial-column .inner-column{
		margin-right:0px;
	}
	
	.call-to-action-section-three .btn-column,
	.call-to-action-section-three{
		text-align:center;
	}
	
	.about-section-two .content-column{
		margin-bottom:40px;
	}
	
	.testimonial-block-seven .inner-box{
		padding:0px 15px;
	}
	
	.contact-section .sec-title .text{
		margin-left:0px;
		margin-top:20px;
	}
	
	.get-quote-form .inner-box .sec-title .pull-left,
	.get-quote-form .inner-box .sec-title .pull-right{
			width:100%;	
	}
	
	.get-quote-form .inner-box .sec-title .text{
		padding:0px 0px;
		margin:20px 0px 0px;	
	}
	
	.main-header .sticky-header{
		display:none;	
	}
	
	.main-menu .navigation > li > .mega-menu{
		padding:20px 20px;	
	}
	
	.services-block .inner-box .overlay-box{
		padding-bottom:30px;	
	}
	
	.about-section .content-column .inner-column .about-block li{
		padding-right:0px;
		width:100%;
		margin-bottom:30px;
	}
	
}

@media only screen and (max-width: 768px){
	
	.nav-outer,
	.main-menu,
	.main-menu .navigation,
	.main-menu .navigation > li,
	.main-menu .navigation > li.has-mega-menu{
		position:relative !important;	
	}
	
	.main-menu .navigation > li .mega-menu-bar .column{
		padding:0px;
		margin:0px;	
	}
	
	.main-menu .navigation > li > .mega-menu{
		position:relative;
		width:100%;
		padding:0px !important;
		background:none !important;
		margin:0px !important;
		left:0px !important;
		top:0px !important;	
	}
	
	.main-menu .navigation > li > .mega-menu .row{
		margin:0px;
		width:100%;	
	}
	
	.header-upper .logo-outer {
		position:relative;
		left:0px;
		top:0px;
		right:0px;
		bottom:0px;
		width:100%;
		margin:0px;
	}
	
	.main-header .header-top .top-left,
	.main-header .header-top .top-right,
	.main-header .header-top .top-right .links{
		width:100%;
		text-align:center;
	}
	
	.main-header .header-top .top-right .links{
		padding-bottom:15px;
	}
	
	.header-top .social-icon{
		float:none;
		margin-left:0px;
		text-align:center;
		display:inline-block;
	}
	
	.main-header .header-top .top-right .links li{
		float:none;
		display:inline-block;
	}
	
	.sec-title h2{
		font-size:24px;	
	}
	
	.main-menu .collapse {
		float:none;
		width:100%;
		padding:0px 0px 0px;
		border:none;
		margin:10px 0px 15px;
		-ms-border-radius:0px;
		-moz-border-radius:0px;
		-webkit-border-radius:0px;
		-o-border-radius:0px;
		border-radius:0px;
		-webkit-box-shadow:none;
		-ms-box-shadow:none;
		-o-box-shadow:none;
		-moz-box-shadow:none;
		box-shadow:none;
   }
   
	.main-menu .collapse.in,
	.main-menu .collapsing{
		 max-height:320px;
		overflow:auto;
		padding:0px 0px 0px;
		border:none;
		margin:10px 0px 15px;
		-ms-border-radius:0px;
		-moz-border-radius:0px;
		-webkit-border-radius:0px;
		-o-border-radius:0px;
		border-radius:0px;
		-webkit-box-shadow:none;
		-ms-box-shadow:none;
		-o-box-shadow:none;
		-moz-box-shadow:none;
		box-shadow:none;	
	}
	
	.main-menu .navbar-header{
		position:relative;
		float:none;
		display:block;
		text-align:right;
		width:100%;
		padding:12px 0px;
		right:0px;
		z-index:12;
	}
	
	.main-menu .navbar-header .navbar-toggle{
		display: inline-block;
	    z-index: 12;
	    border: none;
	    float: none;
	    margin: -2px 0 0;
	    border-radius: 0;
	    background: transparent;
	}
	
	.main-menu .navbar-header .navbar-toggle .icon-bar{
		background: #000000;
	    width: 25px;
	    height: 2px;
	    margin: 5px 0;
	    display: block;	
	}
	
	.main-menu .navbar-collapse > .navigation{
		float:none !important;
		margin:0px !important;
		width:100% !important;
		background:#2c2c2c;
		border:1px solid #ffffff;
		border-top:none;
	}

	.main-menu .nav > li {
		margin: 0 !important;
		padding: 0 !important;
		width: 100%;
	}
	
	.main-menu .navbar-collapse > .navigation > li{
		margin:0px !important;
		float:none !important;
		padding:0px !important;
		width:100%;
	}
	
	.main-menu .navigation > li > a,
	.main-menu .navigation > li > ul:before{
		border:none;	
	}
	
	.main-menu .navbar-collapse > .navigation > li > a{
		padding:10px 10px !important;
		border:none !important;
		font-weight:500;
	}
	
	.main-menu .navigation li.dropdown > a:after,
	.main-menu .navigation > li.dropdown > a:before,
	.main-menu .navigation > li > ul > li > a::before,
	.main-menu .navigation > li > ul > li > ul > li > a::before,
	.main-menu .navigation > li > .mega-menu:before,
	.main-menu .navigation > li .mega-menu-bar h3{
		color:#ffffff !important;
		right:15px;
		font-size:16px;
		display:none !important;
	}
	
	.main-menu .navbar-collapse > .navigation > li > ul,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul,
	.main-menu .navigation > li > .mega-menu,
	.main-menu .navigation > li .mega-menu-bar > ul{
		position:relative;
		border:none;
		float:none;
		visibility:visible;
		opacity:1;
		display:none;
		margin:0px;
		left:auto !important;
		right:auto !important;
		top:auto !important;
		padding:0px;
		outline:none;
		width:100%;
		background:#2c2c2c;
		-webkit-border-radius:0px;
		-ms-border-radius:0px;
		-o-border-radius:0px;
		-moz-border-radius:0px;
		border-radius:0px;
		transition:none !important;
		-webkit-transition:none !important;
		-ms-transition:none !important;
		-o-transition:none !important;
		-moz-transition:none !important;
		-webkit-box-shadow:none;
		-ms-box-shadow:none;
		-o-box-shadow:none;
		-moz-box-shadow:none;
		box-shadow:none;
	}
		
	.main-menu .navbar-collapse > .navigation > li > ul,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul{
		
	}
	
	.main-menu .navbar-collapse > .navigation > li,
	.main-menu .navbar-collapse > .navigation > li > ul > li,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul > li,
	.main-menu .navigation > li .mega-menu-bar .column > ul > li{
		border-top:1px solid rgba(255,255,255,1) !important;
		border-bottom:none;
		opacity:1 !important;
		top:0px !important;
		left:0px !important;
		visibility:visible !important;
	}
	
	.main-menu .navbar-collapse > .navigation > li:first-child{
		border:none;	
	}
	
	.main-menu .navbar-collapse > .navigation > li > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul > li > a,
	.main-menu .navigation > li .mega-menu-bar .column > ul > li > a{
		padding:12px 20px 10px !important;
		line-height:22px;
		color:#ffffff !important;
		background:#2c2c2c;
		text-align:left;
		font-size:14px;
		font-weight:400;
	}
	
	.main-menu .navbar-collapse > .navigation > li > a:hover,
	.main-menu .navbar-collapse > .navigation > li > a:active,
	.main-menu .navbar-collapse > .navigation > li > a:focus,
	.main-menu .navigation > li .mega-menu-bar .column > ul > li > a{
		background:#ff4800;
	}
	
	.main-menu .navbar-collapse > .navigation > li:hover > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li:hover > a,
	.main-menu .navbar-collapse > .navigation > li > ul > li > ul > li:hover > a,
	.main-menu .navbar-collapse > .navigation > li.current > a,
	.main-menu .navbar-collapse > .navigation > li.current-menu-item > a,
	.main-menu .navigation > li .mega-menu-bar .column > ul > li > a{
		background:#2c2c2c;
		color:#ffffff !important;
	}
	
	.main-menu .navbar-collapse > .navigation li.dropdown .dropdown-btn{
		display:block;
	}
	
	.main-menu .navbar-collapse > .navigation li.dropdown:after,
	.main-menu .navigation > li > ul:before{
		display:none !important;	
	}
	
	.main-slider .big-title{
		font-size:36px;
	}
	
	.main-header .nav-outer{
		padding-right:0px;
		width:100%;
	}
	
	.main-header .nav-outer .search-box-outer{
		margin-left:0px;
	}
	
	.main-header .nav-outer .outer-box .talk{
		margin-top:0;
		top:15px;	
	}
	
	.main-header .nav-outer .search-box-outer .dropdown-menu{
		left:0px;
		top:41px;
	}
	
	.main-header .btn-outer{
		position:relative;
		display:block;
		float:left;
		z-index:15;
		margin-left:0px;
		margin-bottom:-58px;
	}
	
	.main-header .header-top .top-left .links{
		width:100%;
	}
	
	.main-header .header-top .top-left .links li{
		float:none;
		display:inline-block;
	}
	
	.main-header .header-top .top-left .links li:before,
	.main-header .header-top .top-left .links li:after{
		display:none;	
	}
	
	.main-slider .text,
	.main-slider .text.alternate{
		font-size:16px;
	}
	
	.main-header .info-box .icon-box{
		position:relative;
		top:0px;
		text-align:center;
		margin-bottom:10px;
	}
	
	.fixed-header .sticky-header,
	.sec-title .text br{
		display:none;
	}
	
	.main-header .main-box .logo-box{
		text-align:center;
		padding: 0 !important;
		margin: 0 !important;
	}
	
	.main-header .main-box .outer-container{
		padding-bottom:5px;
	}
	
	.main-header .search-box-btn{
		text-align:left;
		border:0px;	
	}
	
	.main-slider h2.alternate{
		font-size:36px;
	}
	
	.main-menu{
		width:100%;
		padding-right:0px !important;
	}
	
	.main-header .language{
		margin-top:0px;
		top:-8px;
	}
	
	.main-header .header-top .top-left .links li{
		padding-right:0px;
	}
	
	.main-header .info-box{
		display:block;
		padding:0px;
		margin-bottom:20px;
		border:0px;
	}
	
	.main-header .info-box{
		text-align:center;
	}
	
	.main-header .nav-outer .outer-box{
		display: none;
		position:absolute !important;
		left:0px;
		top:0px;
		z-index:12;
		margin-left:0px !important;
		margin-top:0px !important;
		padding-right: 40px;
	}
	
	.main-header .header-top .top-right ul li.language{
		top:0px;
	}
	
	.header-style-three .header-upper-two .header-upper-left,
	.main-header .header-lower .lower-left,
	.header-style-four .header-top .top-left,
	.info-block .inner-box .icon-box:after,
	.header-style-four .header-top .top-right ul li.language,
	.market-single .inner-box .lower-content .market-steps .steps-inner:before,
	.market-single .inner-box .lower-content .market-blocks .blocks-inner:after{
		display:none;
	}
	
	.header-style-three .header-upper-two .header-upper-right .social-icon-one{
		margin:15px 0px;
	}
	
	.header-style-four .header-top .top-right > ul > .social-icon-one,
	.header-style-three .header-upper-two .header-upper-right{
		width:100%;
		text-align:center;
	}
	
	.header-style-four .header-top .top-right ul li{
		float:none;
		margin:0px 5px;
	}
	
	.header-style-two .nav-outer .outer-box .quote-btn{
		padding:8px 22px 8px;
	}
	
	.main-header .nav-outer .outer-box .quote-btn::after{
		bottom:8px;
	}
	
	.main-slider h2{
		font-size:36px;
	}
	
	.info-block .inner-box h3{
		margin-left:0px;
		text-align:center;
	}
	
	.main-footer .footer-bottom .title-column .text{
		min-height:auto;
		padding-left:0px;
		text-align:center;
		line-height:normal;
	}
	
	.main-footer .footer-bottom .title-column .text .icon{
		position:relative;
		text-align:center;
		margin:0 auto;
		display:block;
		margin-bottom:20px;
		line-height:1.4em;
	}
	
	.market-single .inner-box .lower-content h2,
	.call-to-action-section-two h2,
	.maintenance-box .inner-box h3,
	.project-single-section .inner-box h2{
		font-size:24px;
	}
	
	.quote-box .inner-box{
		padding:30px 30px 15px;
	}
	
	.step-block .inner,
	.testimonial-block-four .inner-box .content-box .content{
		padding-left:0px;
		text-align:center;
	}
	
	.testimonial-block-four .inner-box .content-box .content .quote-icon{
		position:relative;
		margin:0 auto;
		top:0px;
	}
	
	.project-section-two .title-column{
		margin-bottom:30px;
	}
	
	.testimonial-block-five .inner-box .text,
	.history-section .carousel-outer .content-slide .content-inner .text{
		font-size:16px;
	}
	
	.page-title h1{
		font-size:38px;
	}
	
	.history-section .history-carousel .thumbs-carousel .owl-item.active li{
		-webkit-transform:scale(1.4);
		-moz-transform:scale(1.4);
		-o-transform:scale(1.4);
		-ms-transform:scale(1.4);
		transform:scale(1.4);
	}
	
	.testimonial-block-seven .inner-box{
		padding:0px 0px;
	}
	
	.error-section .inner-section h1{
		font-size:150px;
	}
	
	.maintenance-box .inner-box h2{
		font-size:40px;
	}
	
	.market-block-five .block-inner .block-overlay .overlay-inner{
		padding:15px 28px;
	}
	
	.step-block:last-child .inner{
		margin-left:0px;
		margin-top:20px;
	}
	
	.step-block .inner .icon-box{
		position:relative;
		top:0px;
		left:0px;
		margin-bottom:15px;
	}
	
	.blog-single .inner-box .lower-content h3,
	.blog-classic .news-block-two .inner-box .lower-content h3{
		font-size:20px;
	}
	
	.blog-single .inner-box .two-column .image-column .image{
		margin-bottom:30px;
	}
	
	.author-box .author-comment .inner,
	.sidebar-page-container .comments-area .comment{
		padding-left:0px;
	}
	
	.sidebar-page-container .comments-area .comment-box .author-thumb,
	.author-box .author-comment .inner .image{
		position:relative;
		margin-bottom:20px;
	}
	
	.sidebar-page-container .comments-area .comment-box.reply-comment{
		margin-left:0px;
	}
	
	.cart-section .coupon-outer .pull-right{
		width:100%;
	}
	
	.cart-section .coupon-outer{
		margin-left:0px;
		margin-right:0px;
	}
	
	.contact-section .form-column .inner-column{
		padding:15px 0px;
	}
	
	.quote-form .form-group input[type="text"],
	.quote-form .form-group input[type="password"],
	.quote-form .form-group input[type="tel"],
	.quote-form .form-group input[type="email"]{
		border:1px solid #e0e0e0;	
	}
	
	.project-page-section .outer-section .row{
		margin:0px -15px;
	}
	
	.main-slider .text br{
		display:none;
	}
	
}

@media only screen and (max-width: 599px){
	
	.main-slider h2{
		font-size:30px;
		padding-bottom:0px;
	}
	
	.main-slider .text,
	.main-slider .text.style-three{
		font-size:14px;
		line-height:1.4em;
	}
	
	.main-slider h1,
	.market-section-two .title-box h2{
		font-size:24px;
	}
	
	.main-slider h2.alternate{
		font-size:26px;
	}
	
	.main-header .header-top .top-left{
		padding-bottom:12px;
	}
	
	.main-slider h2 span{
		font-size:60px;
		line-height:1.4em;
	}
	
	.testimonial-block .inner-box{
		padding-left:0px;
	}
	
	.testimonial-block .inner-box .author-img{
		position:relative;
	}
	
	.testimonial-block .inner-box .author-img img{
		width:auto;
		margin-bottom:20px;
	}
	
	.testimonial-block.style-two .inner-box{
		padding-right:0px;
	}
	
	.testimonial-block.style-two .inner-box .author-img{
		right:0px;
		left:auto;
	}
	
	.testimonial-section-two .testimonial-column .inner-column{
		padding:30px 25px 20px;
	}
	
	.testimonial-section-two .content-column .inner-column .achived-list li,
	.achived-section .title-column .achived-list li.icon{
		margin-right:10px;
	}
	
	.quote-form .text{
		margin-left:0px;
		top:0px;
		margin-top:15px;
	}
	
	.quote-form .text br,
	.market-section-three .owl-dots{
		display:none;
	}
	
	.market-section-two .title-box h2{
		margin-right:0px;
	}
	
	.testimonial-section-three .owl-nav{
		margin-left:-225px;
	}
	
	.call-to-action-section-three h2,
	.clients-section-two h2{
		font-size:20px;
	}
	
	.testimonial-block-four .inner-box .content-box{
		padding:20px 15px;
	}
	
	.testimonial-block-four .inner-box .content-box .content .text{
		font-size:16px;
	}
	
	.project-section-three .owl-nav{
		position:relative;
		top:0px;
		bottom:0px;
	}
	
	.news-section-three .sec-title .pull-right{
		width:100%;
		margin-top:30px;
	}
	
	.progress-levels .progress-box .inner .text{
		left:2px;
	}
	
	.error-section .inner-section{
		border-radius:0px;
		padding:50px 15px 50px;
	}
	
	.error-section .inner-section h2{
		font-size:14px;
	}
	
	.styled-form .submit-text,
	.blog-single .post-share-options .tags{
		width:100%;
		margin-top:10px;
	}
	
	.shop-single .product-details .prod-tabs .tabs-content{
		padding:15px 15px 20px;
	}
	
	.sidebar-page-container .comments-area .comment .comment-inner .rating{
		position:relative;
		top:auto;
		right:auto;
	}
	
	#schedule-box .modal-content .get-quote-form .inner-box{
		padding:30px 20px;
	}
	
	.popup-container{
		margin:0px;
		margin-top:30px;	
	}
	
}


@media only screen and (max-width: 479px) {

	.services-block-two .inner-box {
		padding: 30px 15px !important;
	}
	
	.main-slider .big-title{
		font-size:24px;
	}
	
	.main-slider h2{
		line-height:1em;
	}
	
	.main-slider .text,
	.main-slider .text.alternate{
		font-size:14px;
	}
	
	.main-header .main-box .logo-box{
		padding-bottom:0px;
	}
	
	.main-header .main-box .logo-box{
		width:100%;
		margin-bottom:20px;
	}
	
	.main-slider h1{
		padding-bottom:0px;
	}
	
	.main-slider h1:after,
	.market-block-two .inner-box .content-overlay .content-inner .content .text{
		display:none;
	}
	
	.testimonial-section-three .owl-nav{
		margin-left:-145px;
	}
	
	.call-to-action-section-two h2,
	.sec-title h2{
		font-size:20px;
	}
	
	.main-slider .medium-title{
		font-size:24px;
	}
	
	.main-slider h2{
		font-size:18px;
	}
	
	.project-block .inner-box .image .overlay-box .content .text,
	.main-slider .text br{
		display:none;
	}
	
	.project-block .inner-box .image .overlay-box .content{
		bottom:0px;
	}
	
	.news-section .owl-nav{
		position:relative;
		bottom:0px;
		top:0px;
	}
	
	.testimonial-section-two .owl-dots{
		margin-top:10px;
	}
	
	.achived-section .title-column .achived-list li a,
	.testimonial-section-two .content-column .inner-column .achived-list li a{
		top:0px;
		margin-top:15px;
	}
	
	.progress-levels .progress-box{
		width:33.333%;
	}
	
	.maintenance-box .inner-box h2{
		font-size:30px;
	}
	
	.cart-section .apply-coupon .form-group,
	.cart-section .apply-coupon .form-group .coupon-btn,
	.cart-section .coupon-outer .cart-btn,
	.shipping-form .form-group .total-btn,
	.cart-section .cart-outer .checkout-btn,
	.cart-section .apply-coupon .form-group input[type="text"]{
		width:100%;
		display:block;
	}
	
	.list-style-three li,
	.contact-section .info-column .lower-box .lower-inner{
		padding-left:0px;
	}
	
	.list-style-three li .icon,
	.contact-section .info-column .lower-box .lower-inner .image{
		position:relative;
		margin-bottom:20px;
		display:block;
	}
	
}
