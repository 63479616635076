@import "common/variables";

// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap/scss/bootstrap.scss";
// endbower

@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";

.page-title:before {
	background-color: transparent;
}

.sec-title.center {
	text-align: center;
	.separator {
		margin: 0 auto;
	}
}

a.qc-cmp-persistent-link {
    display: none !important;
}

.main-header .main-box .outer-container:before {
    display: none;
}

.logo-box .logo a {
    margin: 10px 0 0 0;
}
.logo-box .logo a img {
    max-width: 155px !important;
}

blockquote {
    position: relative;
    border: none;
    text-align: center;
    padding: 35px 30px;
    margin-bottom: 40px;
    margin-top: 30px;
    background-color: #f4f4f4;
}
blockquote .blockquote-text {
    position: relative;
    z-index: 1;
    font-size: 18px;
    color: #848484;
    line-height: 1.8em;
    font-weight: 400;
}
blockquote .quote-info {
    position: relative;
    display: inline-block;
    padding: 12px 70px 0px;
    margin-top: 20px;
    border-top: 1px solid #e8e8e8;
}
blockquote .quote-info h4 {
    position: relative;
    color: #020e28;
    font-size: 18px;
    font-weight: 600;
}

.about-section-two {
	padding: 50px 30px;
}

@media (max-width: 768px) {
    .main-header .main-box .logo-box {
        position: absolute;
        top: 0;
        left: 0;
        width: auto;
    }
    .main-menu .navbar-header {
        margin-top: 12px;
    }
}